import { createContext, useCallback, useState } from 'react';
import authAPI, { TractionUser } from '../api/auth';
import mentorsAPI, { Mentor, MentorId } from '../api/mentors';
import { Specialization } from '../api/specializations';

export interface MentorContextProps {
  mentor?: Mentor;
  tenantUser: TractionUser | null;
  mentorSpecializations?: Specialization['id'][];
  updateMentor: (mentor: Mentor) => any;
  updateSpecializations: (specializations: Specialization['id'][]) => any;
  loadMentor: (mentorId: MentorId) => Promise<any>;
  loadMentorSpecializations: (mentorId: MentorId) => Promise<any>;
  isMentorLoading: boolean;
  isSpecializationsLoading: boolean;
  setTenantUser: (user: TractionUser | null) => any;
}

export const MentorContext = createContext<MentorContextProps>({
  tenantUser: null,
  updateMentor: () => {},
  updateSpecializations: () => {},
  loadMentor: async () => {},
  loadMentorSpecializations: async () => {},
  isMentorLoading: true,
  isSpecializationsLoading: true,
  setTenantUser: () => {},
});

interface MentorProviderProps {
  children: React.ReactNode;
}

export const MentorProvider = ({ children }: MentorProviderProps) => {
  const [mentor, setMentor] = useState<Mentor>();
  const [tenantUser, setTenantUser] = useState<TractionUser | null>(null);
  const [mentorSpecializations, setMentorSpecializations] =
    useState<Specialization['id'][]>();
  const [isMentorLoading, setIsMentorLoading] = useState(true);
  const [isSpecializationsLoading, setIsSpecializationsLoading] =
    useState(true);

  const updateMentor = useCallback((mentor: Mentor) => {
    setMentor(mentor);
  }, []);

  const updateSpecializations = useCallback(
    (specializations: Specialization['id'][]) => {
      setMentorSpecializations(specializations);
    },
    [],
  );

  const loadMentor = useCallback(async (mentorId: MentorId) => {
    try {
      setIsMentorLoading(true);
      const loadedMentor = await mentorsAPI.getMentor(mentorId);
      setMentor(loadedMentor);
      const loadedTenantUser = await authAPI.getUserByEmail(loadedMentor.email);
      setTenantUser(loadedTenantUser);
      setIsMentorLoading(false);
    } catch (e: any) {
      setIsMentorLoading(false);
      console.error('error loadMentor', e);
    }
  }, []);

  const loadMentorSpecializations = useCallback(async (mentorId: MentorId) => {
    try {
      setIsSpecializationsLoading(true);
      const specializations = await mentorsAPI.getMentorSpecializations(
        mentorId,
      );
      setMentorSpecializations(specializations.map((spec) => spec.id));
      setIsSpecializationsLoading(false);
    } catch (e: any) {
      setIsSpecializationsLoading(false);
      console.error('error loadMentorSpecializations', e);
    }
  }, []);

  const contextValue: MentorContextProps = {
    mentor,
    tenantUser,
    isMentorLoading,
    loadMentor,
    updateMentor,
    mentorSpecializations,
    isSpecializationsLoading,
    loadMentorSpecializations,
    updateSpecializations,
    setTenantUser,
  };

  return (
    <MentorContext.Provider value={contextValue}>
      {children}
    </MentorContext.Provider>
  );
};
