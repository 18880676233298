import { makeStyles } from '@material-ui/core';
import { formatDate } from '../../../utils/date';
import { COLORS } from '../../../theme/variables';
import { Text } from '../index';
import { useMemo } from 'react';
import AttachmentCard, { Attachment } from '../attachment-card';
import { createMarkup } from '../../../utils/functions';
import { LogType } from './types/LogType';

interface LogProps<T extends LogType<any>> {
  log: T;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  body: {
    wordBreak: 'break-all',
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up(800)]: {
      width: '50%',
    },
  },
  bodyText: {
    wordBreak: 'break-word',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

const parseAttachments = (attachmentRefs: string): Attachment[] => {
  try {
    return JSON.parse(attachmentRefs);
  } catch (e: any) {
    return [];
  }
};

export function Log<T extends LogType<any>>({ log, index }: LogProps<T>) {
  const classes = useStyles();
  const noteAttachments = useMemo(() => {
    if (log.attachmentRefs) {
      return parseAttachments(log.attachmentRefs);
    }
    return [];
  }, [log]);

  return (
    <div className={classes.container}>
      <div className={classes.title} data-testid={`log-${index}`}>
        <Text color={COLORS.COLOR_GRAY_DARKENED} variant='normal2'>
          {formatDate(log.creationDate)}
        </Text>
      </div>
      <div>
        <Text className={classes.body} variant='normal'>
          <div
            className={classes.bodyText}
            dangerouslySetInnerHTML={createMarkup(log.contents)}
          />
        </Text>
      </div>
      <div className={classes.attachmentsList}>
        {noteAttachments.map((attachment, attachmentIndex) => (
          <div key={attachmentIndex} className={classes.attachment}>
            <AttachmentCard attachment={attachment} />
          </div>
        ))}
      </div>
    </div>
  );
}
