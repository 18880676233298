import { AxiosError } from 'axios';
import { MentorPersonalNote } from '../contexts/personal-notes-context';
import { Email } from '../utils/String/Email';
import { Phone } from '../utils/String/Phone';
import { Sentence } from '../utils/String/Sentence';
import { Zip } from '../utils/String/Zip';
import { PastStringDate } from '../utils/date/PastStringDate';
import { StringDate } from '../utils/date/StringDate';
import { CohortId } from './Cohort/types/Cohort';
import { ExportJob } from './exports';
import { FounderId } from './founders';
import { axiosRequest } from './instance';
import { Specialization, SpecializationId } from './specializations';
import { TenantId } from './tenants/Tenant';
import { JWTToken } from './user/JWTToken';
import { VentureMentor } from './ventures';
import { VentureId } from './ventures/types/Venture';

// region MentorId
declare const _mentorId: unique symbol;

export type MentorId = string & { [_mentorId]: 'MentorId' };
// endregion

export enum MentorStatus {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
  APPLICANT = 'Applicant',
  REJECTED = 'Rejected',
}

export interface MentorNote {
  contents: string;
  creationDate: string;
  id: string;
  mentorId: string;
  attachmentRefs: string | null;
}

export interface MentorLog {
  id: string;
  mentorId: string;
  creationDate: string;
  contents: string;
}

export interface MentorNewNote {
  contents: string;
  mentorId: string;
  attachmentRefs?: string | null;
}

export interface MentorDetails {
  address: Sentence<1024> | null;
  city: Sentence<250> | null;
  country: Sentence<250> | null;
  dateOfBirth: PastStringDate | null;
  dateOfEnrollment: PastStringDate;
  email: Email;
  firstName: Sentence<250>;
  lastName: Sentence<250>;
  linkedInProfile: Sentence<250> | null;
  logo?: string | null;
  mentorAdditionalInfo?: MentorAdditionalInfo | MentorAdditionalInfoCreate;
  mentorNotes?: Array<MentorNote | MentorNewNote>;
  phone: Phone | null;
  state: Sentence<250> | null;
  status: MentorStatus;
  zip: Zip | null;
}

export interface Mentor extends MentorDetails {
  assigned: number;
  assignmentStatus?: null | 'INVITED' | 'ACCEPTED' | 'DECLINED' | 'TENTATIVE';
  deactivationDate: PastStringDate | null;
  email: Email;
  emailValid: boolean;
  firstName: Sentence<250>;
  // !TODO need to confirm length
  fullName: Sentence<250>;
  id: MentorId;
  lastName: Sentence<250>;
  linkedInProfile: Sentence<250> | null;
  logo: string | null;
  mentorAdditionalInfo: MentorAdditionalInfo;
  mentorNotes?: MentorNote[];
  tenantId: TenantId;
  valid: boolean;
  voted: boolean;
}

export interface MentorSpecialization {
  id: string;
  mentorId: MentorId;
  specializationId: SpecializationId;
  valid: boolean;
}

export interface MentorAdditionalInfoCreate {
  gender: Sentence<20> | null;
  race: Sentence<50> | null;
  veteran: boolean | null;
  whyBecomeMentor: Sentence<4096> | null;
  startedBusiness: boolean | null;
  salesChallenges: Sentence<1024> | null;
  marketingChallenges: Sentence<1024> | null;
  fundraisingChallenges: Sentence<1024> | null;
  businessChallenges: Sentence<1024> | null;
  comments: Sentence<4096> | null;
}

export interface MentorAdditionalInfo {
  id: string;
  mentorId: MentorId;
  tenantId: TenantId;
  gender: Sentence<20>;
  race: Sentence<50>;
  veteran: boolean;
  whyBecomeMentor: Sentence<4096>;
  startedBusiness: boolean;
  salesChallenges: Sentence<1024>;
  marketingChallenges: Sentence<1024>;
  fundraisingChallenges: Sentence<1024>;
  businessChallenges: Sentence<1024>;
  comments: Sentence<4096>;
  creationDate: StringDate;
}

export interface SelectMentor {
  id: string;
  mentorName: string;
}

export interface MentorRequestDetails {
  tenantId: TenantId | null;
  ventureId: VentureId | null;
  founderId: FounderId;
  type: 'ADD' | 'REMOVE' | 'CHANGE';
  description: string;
  id?: string;
  mentorId?: MentorId | null;
  valid?: boolean;
}

export interface RateMentorDetails {
  rating: string;
  comment: string;
  valid?: boolean;
}

export interface AdvancedFilters {
  tenantId: TenantId;
  status?: string | null;
  specializationId?: SpecializationId | null;
  assignedNum?: number | null;
  assignedMoreThanNum?: number | null;
  ventureId?: string | null;
  cohortId?: string | null;
  offset?: number;
  limit?: number;
}

export interface VentureProgress {
  ventureId: VentureId;
  ventureName: string;
  businessGoals: number;
  founderGoals: number;
}

export interface CohortAttendance {
  cohortName: string;
  cohortId: CohortId;
  totalSessions: number;
  sessionsAttended: number;
  cohortCreationDate: StringDate;
}

export interface MentorStatistics {
  newApplications: number;
  activelyRecruiting: number;
  votedFor: number;
  ventureProgress: VentureProgress[];
  cohortAttendance: CohortAttendance[];
  numberOfSessionsAttended: number;
  leadMentorReportsSubmitted: number;
  mentorAssessmentsSubmitted: number;
  mentorLevel: number;
  mentorScore: number;
  mentorXp: number;
}

function normalizeMentor(mentor: Mentor): Mentor {
  return mentor;
}

function normalizeMentorDetails({
  address,
  firstName,
  lastName,
  dateOfBirth,
  dateOfEnrollment,
  email,
  phone,
  linkedInProfile,
  country,
  state,
  city,
  logo,
  status,
  mentorAdditionalInfo,
  zip,
}: Mentor | MentorDetails) {
  return {
    address,
    firstName,
    lastName,
    dateOfBirth,
    dateOfEnrollment,
    email,
    phone,
    linkedInProfile,
    country,
    state,
    city,
    logo:
      typeof logo === 'string'
        ? logo.replace(`${process.env.REACT_APP_S3_URL}/`, '')
        : logo,
    status,
    mentorAdditionalInfo,
    zip,
  };
}

const mentorsAPI = {
  async search(searchString: string) {
    const res = await axiosRequest.get<Mentor[]>({
      url: `/mentors/search/${searchString}`,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async searchNonusers(searchString: string) {
    const res = await axiosRequest.get<Mentor[]>({
      url: `/mentors/search-nonusers/${searchString}`,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async getMentors(page = 0) {
    const res = await axiosRequest.get<Mentor[]>({
      url: `/mentors/pages/${page}`,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async getMentorsByLastname(lastName: string) {
    const res = await axiosRequest.get<Mentor[]>({
      url: `/mentors/lastname/${lastName}`,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async getMentorsByStatus(status: string, page = 0) {
    const res = await axiosRequest.get<Mentor[]>({
      url: `/mentors/statuses/${status}/pages/${page}`,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async getMentorsByAssignment(assignment: string, page = 0) {
    const url =
      assignment === 'notassigned'
        ? `/mentors/notassigned/pages/${page}`
        : `/mentors/assignments/${assignment}/pages/${page}`;
    const res = await axiosRequest.get<Mentor[]>({
      url,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async getMentorsByVentureId(ventureId: string) {
    try {
      const res = await axiosRequest.get<VentureMentor[]>({
        url: `/mentors/ventures/${ventureId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  getMentorsBySpecializations(
    specializationId: SpecializationId,
    pageNum: number,
  ): Promise<Mentor[]> {
    return axiosRequest
      .get<Mentor[]>({
        url: `/mentors/specializations/${specializationId}/pages/${pageNum}`,
        credentials: true,
      })
      .then((r) => r.data)
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          return [];
        }

        throw e;
      });
  },
  async getMentorsAdvancedFilters(body: AdvancedFilters, page: number) {
    const res = await axiosRequest.post<Mentor[]>({
      url: `/mentors/advanced-filters/pages/${page}`,
      credentials: true,
      data: body,
    });
    return res.data.map(normalizeMentor);
  },
  async getMentorsDetailsByVentureId(ventureId: string) {
    try {
      const res = await axiosRequest.get<Mentor[]>({
        url: `/mentors/ventures/${ventureId}/details`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getMentorSpecializations(mentorId: Mentor['id']) {
    try {
      const res = await axiosRequest.get<Specialization[]>({
        url: `/mentors/${mentorId}/specializations`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async setMentorSpecialization(
    mentorId: Mentor['id'],
    specializationId: string,
  ) {
    const res = await axiosRequest.post<MentorSpecialization>({
      url: `/mentors/${mentorId}/specializations/${specializationId}`,
      credentials: true,
    });
    return res.data;
  },
  async deleteMentorSpecialization(
    mentorId: Mentor['id'],
    specializationId: string,
  ) {
    const res = await axiosRequest.delete<MentorSpecialization>({
      url: `/mentors/${mentorId}/specializations/${specializationId}`,
      credentials: true,
    });
    return res.data;
  },
  async updateAvatar(mentorId: Mentor['id'], logoFile: File) {
    let formData = new FormData();
    formData.append('name', logoFile.name);
    formData.append('file', logoFile);

    const res = await axiosRequest.post<Mentor>({
      url: `/mentors/${mentorId}/logo`,
      data: formData,
      credentials: true,
    });
    return normalizeMentor(res.data);
  },
  async updateAvatarPublic(
    tenantId: TenantId,
    mentorId: Mentor['id'],
    logoFile: File,
  ) {
    let formData = new FormData();
    formData.append('name', logoFile.name);
    formData.append('file', logoFile);

    const res = await axiosRequest.post<Mentor>({
      url: `/public/tenants/${tenantId}/files/mentors/${mentorId}/logo`,
      data: formData,
      credentials: true,
    });
    return normalizeMentor(res.data);
  },
  async create(mentorDetails: MentorDetails) {
    const res = await axiosRequest.post<Mentor>({
      url: '/mentors',
      data: {
        ...mentorDetails,
      },
      credentials: true,
    });
    return normalizeMentor(res.data);
  },
  async update(mentorId: string, mentorDetails: MentorDetails) {
    const res = await axiosRequest.put<Mentor>({
      url: '/mentors',
      data: { ...normalizeMentorDetails(mentorDetails), id: mentorId },
      credentials: true,
    });
    return normalizeMentor(res.data);
  },
  async getMentor(mentorId: Mentor['id']) {
    const res = await axiosRequest.get<Mentor>({
      url: `/mentors/${mentorId}`,
      credentials: true,
    });
    return normalizeMentor(res.data);
  },
  async checkExportMentors(jobId: ExportJob['jobId']) {
    try {
      const res = await axiosRequest.get<ExportJob>({
        url: `/export/mentors/${jobId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
      throw e;
    }
  },
  async exportMentors() {
    const res = await axiosRequest.post<ExportJob>({
      url: '/export/mentors',
      credentials: true,
    });
    return res.data;
  },
  async import(file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    try {
      const res = await axiosRequest.post<string>({
        url: '/import/mentors',
        data: formData,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async attachFileToNote(mentorId: string, file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/mentors/${mentorId}/mentornotes`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async checkEmail(email: string) {
    try {
      const res = await axiosRequest.get<Mentor[]>({
        url: `/mentors/email/${email}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },

  async checkGlobalEmail(email: string) {
    try {
      const res = await axiosRequest.get<MentorId>({
        url: `/mentors/global/emails/${email}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return null;
      }
      throw e;
    }
  },
  async getNotes(mentorId: string) {
    try {
      const res = await axiosRequest.get<MentorNote[]>({
        url: `/mentors/${mentorId}/notes`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createNote(note: MentorNewNote) {
    try {
      const res = await axiosRequest.post<MentorNote>({
        url: '/mentors/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeNote(mentorId: string, noteId: string) {
    try {
      const res = await axiosRequest.delete<MentorNote>({
        url: `/mentors/${mentorId}/notes/${noteId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateNote(note: MentorNote) {
    try {
      const res = await axiosRequest.put<MentorNote>({
        url: '/mentors/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getLogs(mentorId: string) {
    try {
      const res = await axiosRequest.get<MentorLog[]>({
        url: `/mentors/${mentorId}/log`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async removeLog(mentorId: string, logId: string) {
    try {
      const res = await axiosRequest.delete<MentorLog>({
        url: `/mentors/${mentorId}/log/${logId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async globalCheckEmail(email: string) {
    try {
      const res = await axiosRequest.get<string>({
        url: `/mentors/global/emails/${email}`,
        credentials: true,
      });
      return !!res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
      throw e;
    }
  },
  async sendMentorUpdateInvites(mentorIds: string[]) {
    try {
      const res = await axiosRequest.post<string>({
        url: '/mentors/invites',
        data: mentorIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getMentorToken(key: string) {
    try {
      const res = await axiosRequest.post<{ jwttoken: JWTToken }>({
        url: '/otl',
        data: key,
      });
      return res.data.jwttoken;
    } catch (e: any) {
      throw e;
    }
  },
  async getMentorsRecommendedForVenture(ventureId: VentureId) {
    try {
      const res = await axiosRequest.get<Mentor[]>({
        url: `/mentors/assignments/ventures/${ventureId}`,
        credentials: true,
      });

      return res.data;
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async voteVenture(
    mentorId: MentorId,
    tenantId: TenantId,
    ventureId: VentureId,
  ): Promise<void> {
    return axiosRequest
      .post<void>({
        url: '/mentors/votes',
        data: {
          mentorId,
          tenantId,
          ventureId,
        },
        credentials: true,
      })
      .then(() => {});
  },
  async unvoteVenture(mentorId: MentorId, ventureId: VentureId): Promise<void> {
    return axiosRequest
      .delete<void>({
        url: `/mentors/${mentorId}/votes/ventures/${ventureId}`,
        credentials: true,
      })
      .then(() => {});
  },
  async getMentorsByFounderId(founderId: FounderId) {
    const res = await axiosRequest.get<Mentor[]>({
      url: `/mentors/founders/${founderId}`,
      credentials: true,
    });
    return res.data.map(normalizeMentor);
  },
  async createMentorManagementRequest(requestDetails: MentorRequestDetails) {
    const res = await axiosRequest.post<MentorRequestDetails>({
      url: '/mentors/management-requests',
      data: requestDetails,
      credentials: true,
    });
    return res.data;
  },
  async rateMentor(
    mentorId: MentorId,
    ventureId: VentureId,
    data: RateMentorDetails,
  ) {
    const res = await axiosRequest.post<RateMentorDetails>({
      url: `/mentors/${mentorId}/ventures/${ventureId}/ratings`,
      data,
      credentials: true,
    });
    return res.data;
  },
  async getPersonalNotes() {
    try {
      const res = await axiosRequest.get<MentorPersonalNote[]>({
        url: '/mentors/personal-notes',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      } else {
        throw e;
      }
    }
  },
  async getPersonalNotesByPages(page: number) {
    try {
      const res = await axiosRequest.get<MentorPersonalNote[]>({
        url: `/mentors/personal-notes/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      } else {
        throw e;
      }
    }
  },
  async searchPersonalNotes(keywords: string) {
    try {
      const res = await axiosRequest.get<MentorPersonalNote[]>({
        url: `/mentors/personal-notes/search/${keywords}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      } else {
        throw e;
      }
    }
  },
  async createPersonalNotes(note: Omit<MentorPersonalNote, 'id' | 'date'>) {
    try {
      const res = await axiosRequest.post<MentorPersonalNote>({
        url: '/mentors/personal-notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updatePersonalNotes(note: MentorPersonalNote) {
    try {
      const res = await axiosRequest.put<MentorPersonalNote>({
        url: '/mentors/personal-notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removePersonalNote(note: MentorPersonalNote) {
    try {
      const res = await axiosRequest.delete<MentorPersonalNote>({
        url: `/mentors/personal-notes`,
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getMentorStatistics(mentorId: Mentor['id']) {
    const res = await axiosRequest.get<MentorStatistics>({
      url: `/mentors/${mentorId}/statistics`,
      credentials: true,
    });
    return res.data;
  },
};

export default mentorsAPI;
