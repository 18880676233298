import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { GoalStatus } from '../../api/types/Goal';
import { COLORS } from '../../theme/variables';
import { formatDate } from '../../utils/date';
import { ConfirmButton, Text } from '../common';
import CheckinForm, { Values } from '../forms/checkin';

interface CheckinProps<T> {
  checkin: T & {
    creationDate: string;
    contents: string;
    progress: number;
    status: GoalStatus;
  };
  updating?: boolean;
  deleting?: boolean;
  onEdit: (updateCheckin: T, callback: () => any) => any;
  onRemove: (checkin: T, callback: () => any) => any;
  readOnly?: boolean;
  index?: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    '& + &': {
      marginTop: 45,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  contentBox: {
    display: 'grid',
    gridTemplateColumns: '140px 1fr',
    gap: 16,
    width: '100%',
    alignItems: 'flex-start',
  },
  progressBox: {
    position: 'relative',
    paddingTop: 16,
    border: `solid 1px ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    borderRadius: 4,
  },
  progressBoxLabel: {
    position: 'absolute',
    top: 0,
    left: '50%',
    padding: '0 8px',
    transform: 'translate(-50%, -50%)',
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    fontSize: '14px',
    backgroundColor: '#fff',
  },
  progressNumber: {
    width: '100%',
    padding: '4px 0',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: COLORS.COLOR_BLUE_BASE,
  },
  statusOption: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0',
  },
  statusColor: {
    width: 10,
    height: 10,
    borderRadius: 5,

    '&.ARCHIVED': {
      backgroundColor: COLORS.COLOR_GRAY_DARKENED,
    },
    '&.COMPLETED': {
      backgroundColor: COLORS.COLOR_GREEN_BASE,
    },
    '&.NOT_STARTED': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
    },
    '&.AT_RISK': {
      backgroundColor: COLORS.COLOR_ADDITIONAL_ORANGE,
    },
    '&.IN_TROUBLE': {
      backgroundColor: COLORS.COLOR_RED_BASE,
    },
    '&.ON_TRACK': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_10,
    },
  },
  subtitle: {
    marginBottom: 16,
  },
  body: {
    wordBreak: 'break-all',
  },
  bodyText: {
    wordBreak: 'break-word',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

function goalStatusTitle(status: GoalStatus) {
  switch (status) {
    case 'ARCHIVED':
      return 'Archived';
    case 'COMPLETED':
      return 'Completed';
    case 'NOT_STARTED':
      return 'Not Started';
    case 'AT_RISK':
      return 'At Risk';
    case 'IN_TROUBLE':
      return 'In Trouble';
    case 'ON_TRACK':
      return 'On Track';
  }
}

function Checkin<T>({
  checkin,
  updating,
  deleting,
  onEdit,
  onRemove,
  readOnly = false,
  index,
}: CheckinProps<T>) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleCheckinEdit = () => {
    setIsEditing(true);
  };

  const handleCheckinRemove = (callback: () => any) => {
    onRemove(checkin, callback);
  };

  const handleCheckinEditCancel = () => {
    setIsEditing(false);
  };

  const handleSubmitCheckinForm = ({ contents }: Values) => {
    onEdit({ ...checkin, contents }, handleCheckinEditCancel);
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <CheckinForm
          title='Check-in editing'
          checkin={{ contents: checkin.contents }}
          loading={updating}
          onSubmit={handleSubmitCheckinForm}
          onCancel={handleCheckinEditCancel}
        />
      ) : (
        <>
          <div className={classes.contentBox}>
            <div className={classes.progressBox}>
              <div className={classes.progressBoxLabel}>Progress</div>
              <GaugeChart
                animate={false}
                percent={checkin.progress / 100}
                needleColor={COLORS.COLOR_GRAY_DARKENED_15}
                needleBaseColor={COLORS.COLOR_GRAY_DARKENED_15}
                hideText
                colors={[
                  COLORS.COLOR_BLUE_BASE,
                  COLORS.COLOR_BLUE_LIGHTENED_25,
                ]}
                arcsLength={[checkin.progress, 100 - checkin.progress]}
              />
              <div className={classes.progressNumber}>{checkin.progress}</div>
              <div className={classes.statusOption}>
                <div
                  className={classNames(classes.statusColor, checkin.status)}
                />
                <div>{goalStatusTitle(checkin.status)}</div>
              </div>
            </div>
            <div>
              <div className={classes.title} data-testid={`checkin-${index}`}>
                <Text color={COLORS.COLOR_GRAY_DARKENED} variant='normal2'>
                  {checkin.creationDate ? formatDate(checkin.creationDate) : ''}
                </Text>
                {!readOnly && (
                  <div>
                    <IconButton
                      onClick={handleCheckinEdit}
                      data-testid={`form-checkin-edit-${index}`}>
                      <EditIcon />
                    </IconButton>
                    <ConfirmButton
                      loading={deleting}
                      title='Remove the check-in?'
                      body='Sure you want to remove the check-in? Changes can’t be undone'
                      successProps={{
                        btnLabel: 'Remove',
                        onSuccess: handleCheckinRemove,
                      }}
                      buttonRenderer={({ onClick }) => (
                        <IconButton
                          onClick={onClick}
                          data-testid={`form-checkin-delete-${index}`}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    />
                  </div>
                )}
              </div>
              <div>
                <Text className={classes.body} variant='normal'>
                  <div
                    className={classes.bodyText}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(checkin.contents),
                    }}
                  />
                </Text>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Checkin;
