import React, { useEffect } from 'react';
import {
  BrowserRouter as DOMRouter,
  Redirect,
  Route as DOMRoute,
  RouteProps as DOMRouteProps,
  Switch,
} from 'react-router-dom';
import { KnownModule } from '../api/user/Module';
import { Role } from '../api/user/Role';
import { PageLoader, RouteACL } from '../components/common';
import { RouterProvider } from '../contexts/router-context';
import { useAuth } from '../hooks';
import { AddAdvisor } from '../pages/Advisors/Add';
import { Availabilities } from '../pages/Advisors/Availabilities';
import { EditAdvisor } from '../pages/Advisors/Edit';
import { AdvisorsList as DashboardAdvisorsList } from '../pages/Advisors/List';
import { AdvisorLogs } from '../pages/Advisors/Logs';
import { ViewAdvisor } from '../pages/Advisors/View';
import { AddAppointment } from '../pages/Appointments/Add';
import { EditAppointment } from '../pages/Appointments/Edit';
import { AppointmentsList } from '../pages/Appointments/List';
import { AppointmentLogs } from '../pages/Appointments/Logs';
import { CommunityMemberSignup } from '../pages/CommunityMemberSignup';
import { AddCommunityMember } from '../pages/CommunityMembers/Add';
import { EditCommunityMember } from '../pages/CommunityMembers/Edit';
import { Enroll } from '../pages/CommunityMembers/Enroll';
import { CommunityMembersList } from '../pages/CommunityMembers/List';
import { Logs } from '../pages/CommunityMembers/Logs';
import { CommunityMemberNotesPage } from '../pages/CommunityMembers/Notes';
import { OfficeHours } from '../pages/OfficeHours';
import { OfficeHoursReports } from '../pages/OfficeHoursReports';
import { OfficeHoursSurvey } from '../pages/OfficeHoursSurvey';
import CancelSessionPage from '../pages/cancel-session';
import ChangePasswordPage from '../pages/change-password';
import DashboardPage from '../pages/dashboard';
import EducationList from '../pages/education/education';
import FileLinksPage from '../pages/file-links';
import { FounderDashboardPage } from '../pages/founder-dashboard';
import FounderExternalPage from '../pages/founder-external';
import FounderUpdatePage from '../pages/founder-update';
import FounderPage from '../pages/founder/founder';
import NewFounderPage from '../pages/founder/new-founder';
import FoundersPage from '../pages/founders';
import GatheringPage from '../pages/gathering/gathering';
import NewGatheringPage from '../pages/gathering/new-gathering';
import GatheringsPage from '../pages/gatherings';
import GoalPage from '../pages/goal/goal';
import NewGoalPage from '../pages/goal/new-goal';
import GoalsPage from '../pages/goals';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import { MentorDashboardPage } from '../pages/mentor-dashboard';
import MentorExternalPage from '../pages/mentor-external';
import MentorUpdatePage from '../pages/mentor-update';
import MentorPage from '../pages/mentor/mentor';
import NewMentorPage from '../pages/mentor/new-mentor';
import MentorsPage from '../pages/mentors';
import MentorsApplicationsWizardPage from '../pages/mentors-applications-wizard';
import PaymentCancelPage from '../pages/payment/cancel';
import PaymentSuccessPage from '../pages/payment/success';
import PersonalNotesPage from '../pages/personal-notes';
import { AdvisorsList as PublicAdvisorsList } from '../pages/public/officehours/List';
import { Profile as AdvisorProfile } from '../pages/public/officehours/Profile';
import { Landing } from '../pages/public/officehours/landing';
import RegistrationPage from '../pages/registration';
import ReportAgendaRequestPage from '../pages/report/report-agenda-request';
import ReportFounderSessionNotesPage from '../pages/report/report-founder-session-notes';
import ReportLeadMentorPage from '../pages/report/report-lead-mentor';
import ReportMentorAssessmentPage from '../pages/report/report-mentor-assessment';
import ReportVentureQuarterPage from '../pages/report/report-venture-quarter';
import ReportsList from '../pages/reports/reports';
import ReportDetailsGeneral from '../pages/reports/reports-details-general-page';
import ReportDetailsMentor from '../pages/reports/reports-details-mentor-page';
import ReportDetailsVenture from '../pages/reports/reports-details-venture-page';
import ReportQuarterly from '../pages/reports/reports-quarterly-page';
import ResetPasswordPage from '../pages/reset-password';
import { CancelPage } from '../pages/scheduling/CancelPage';
import SchedulingInitialPage from '../pages/scheduling/initial';
import SchedulingVotePage from '../pages/scheduling/vote';
import NewSessionPage from '../pages/session/new-session';
import SessionPage from '../pages/session/session';
import { Sessions } from '../pages/sessions';
import TenantPage from '../pages/tenant/tenant';
import Test from '../pages/test/test';
import UnsubscribePage from '../pages/unsubscribe';
import VentureApplicationsWizardPage from '../pages/venture-applications-wizard';
import VentureExternalPage from '../pages/venture-external';
import VentureUpdatePage from '../pages/venture-update';
import NewVenturePage from '../pages/venture/new-venture';
import VenturePage from '../pages/venture/venture';
import VenturesPage from '../pages/ventures';
import { Pages } from './constants';
import { ProtectedRouteProps } from './type';
import { useRedirectRoute } from './useRedirectRoute';

interface RouteProps extends DOMRouteProps {
  isGuest?: boolean;
  isProtected?: boolean;
}

const Route = ({
  isGuest = false,
  isProtected = false,
  ...routeProps
}: RouteProps) => {
  const redirect = useRedirectRoute(isGuest, isProtected);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return <DOMRoute {...routeProps} />;
};

export const ProtectedRoute = (
  Component: React.FunctionComponent<ProtectedRouteProps>,
): React.FC<RouteProps> => {
  function DecoratedRoute(routeProps: RouteProps) {
    const { user, isUserLoading, loadUser } = useAuth();

    useEffect(() => {
      if (!user) {
        loadUser();
      }
    }, [user, loadUser]);

    if (isUserLoading || !user) {
      return <PageLoader />;
    }

    return <Component {...routeProps} user={user} />;
  }

  return DecoratedRoute;
};

const Router = () => {
  return (
    <DOMRouter>
      <RouterProvider>
        <Switch>
          <Route isGuest exact path={Pages.LOGIN} component={LoginPage} />
          <Route
            isGuest
            exact
            path={Pages.REGISTRATION}
            component={RegistrationPage}
          />
          <Route
            isGuest
            exact
            path={Pages.RESET_PASSWORD}
            component={ResetPasswordPage}
          />
          <Route
            isGuest
            exact
            path={Pages.CHANGE_PASSWORD}
            component={ChangePasswordPage}
          />
          <Route
            exact
            path={Pages.PAYMENT_SUCCESS}
            component={PaymentSuccessPage}
          />
          <Route
            exact
            path={Pages.PAYMENT_CANCEL}
            component={PaymentCancelPage}
          />
          <Route
            exact
            path={Pages.OH_CANCEL_SESSION}
            component={CancelSessionPage}
          />
          <Route exact path={Pages.FILE_LINKS} component={FileLinksPage} />
          <Route exact path={Pages.UNSUBSCRIBE} component={UnsubscribePage} />
          <Route
            exact
            path={Pages.MENTOR_UPDATE}
            component={MentorUpdatePage}
          />
          <RouteACL
            isProtected
            module={KnownModule.Core}
            path={Pages.MENTOR_EXTERNAL}
            component={MentorExternalPage}
          />
          <Route
            exact
            path={Pages.VENTURE_UPDATE}
            component={VentureUpdatePage}
          />
          <RouteACL
            isProtected
            module={KnownModule.Core}
            path={Pages.VENTURE_EXTERNAL}
            component={VentureExternalPage}
          />
          <Route
            exact
            path={Pages.FOUNDER_UPDATE}
            component={FounderUpdatePage}
          />
          <RouteACL
            isProtected
            module={KnownModule.Core}
            path={Pages.FOUNDER_EXTERNAL}
            component={FounderExternalPage}
          />
          <Route
            exact
            path={Pages.MENTORS_APPLICATIONS_WIZARD}
            component={MentorsApplicationsWizardPage}
          />
          <Route
            exact
            path={Pages.VENTURE_APPLICATIONS_WIZARD}
            component={VentureApplicationsWizardPage}
          />
          <Route
            exact
            path={Pages.REPORT_LEAD_MENTOR}
            component={ReportLeadMentorPage}
          />
          <Route
            exact
            path={Pages.REPORT_MENTOR_ASSESSMENT}
            component={ReportMentorAssessmentPage}
          />
          <Route
            exact
            path={Pages.REPORT_VENTURE_QUARTER}
            component={ReportVentureQuarterPage}
          />
          <Route
            exact
            path={Pages.SCHEDULUNG_INITIAL}
            component={SchedulingInitialPage}
          />
          <Route
            exact
            path={Pages.SCHEDULUNG_VOTE}
            component={SchedulingVotePage}
          />
          <Route
            path={Pages.SCHEDULING_CANCELLATION}
            component={CancelPage}
            exact
          />
          <Route
            exact
            path={Pages.REPORT_FOUNDER_SESSION_NOTES}
            component={ReportFounderSessionNotesPage}
          />
          <Route
            exact
            path={Pages.REPORT_AGENDA_REQUEST}
            component={ReportAgendaRequestPage}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.REPORTS_LIST}
            rule='modules.reports.view'
            component={ProtectedRoute(ReportsList)}
            module={KnownModule.Analytics}
            redirectUrl={Pages.DASHBOARD}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.REPORTS_DETAILS_VENTURE}
            component={ProtectedRoute(ReportDetailsVenture)}
            module={KnownModule.Analytics}
            rule='modules.reports.view'
            redirectUrl={Pages.DASHBOARD}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.REPORTS_DETAILS_MENTOR}
            component={ProtectedRoute(ReportDetailsMentor)}
            module={KnownModule.Analytics}
            rule='modules.reports.view'
            redirectUrl={Pages.DASHBOARD}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.REPORTS_GENERAL}
            component={ProtectedRoute(ReportDetailsGeneral)}
            module={KnownModule.Analytics}
            rule='modules.reports.view'
            redirectUrl={Pages.DASHBOARD}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.REPORTS_QUARTERLY}
            component={ProtectedRoute(ReportQuarterly)}
            module={KnownModule.Analytics}
            rule='modules.reports.view'
            redirectUrl={Pages.DASHBOARD}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.EDUCATION_LIST}
            component={ProtectedRoute(EducationList)}
            module={KnownModule.Education}
            redirectUrl={Pages.DASHBOARD}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.DASHBOARD}
            module={KnownModule.Core}
            component={ProtectedRoute(DashboardPage)}
            rule='dashboard.view'
          />
          <RouteACL
            exact
            isProtected
            module={KnownModule.Core}
            path={Pages.MENTOR_DASHBOARD}
            component={ProtectedRoute(MentorDashboardPage)}
            role={Role.Mentor}
          />
          <RouteACL
            exact
            isProtected
            module={KnownModule.Core}
            path={Pages.MENTORS}
            component={ProtectedRoute(MentorsPage)}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.NEW_MENTOR}
            component={ProtectedRoute(NewMentorPage)}
            rule='mentor.create'
            redirectUrl={Pages.ACTIVE_MENTORS}
          />
          <RouteACL
            isProtected
            path={Pages.MY_MENTOR_DETAILS}
            component={ProtectedRoute(MentorPage)}
            rule='mentor.details.view'
            redirectUrl={Pages.MENTORS}
          />
          <RouteACL
            isProtected
            path={Pages.MENTOR_DETAILS}
            component={ProtectedRoute(MentorPage)}
            rule='mentor.details.view'
            redirectUrl={Pages.ACTIVE_MENTORS}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.VENTURES}
            rule={'venture.view'}
            module={KnownModule.Core}
            component={ProtectedRoute(VenturesPage)}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.NEW_VENTURE}
            component={ProtectedRoute(NewVenturePage)}
            rule='venture.create'
            redirectUrl={Pages.VENTURES}
          />
          <RouteACL
            isProtected
            path={Pages.VENTURE_DETAILS}
            component={ProtectedRoute(VenturePage)}
            rule='venture.details.view'
            redirectUrl={Pages.VENTURES}
          />
          <RouteACL
            exact
            isProtected
            module={KnownModule.Core}
            path={Pages.FOUNDER_DASHBOARD}
            component={ProtectedRoute(FounderDashboardPage)}
            role={Role.Founder}
          />
          <RouteACL
            exact
            isProtected
            module={KnownModule.Core}
            path={Pages.FOUNDERS}
            component={ProtectedRoute(FoundersPage)}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.NEW_FOUNDER}
            component={ProtectedRoute(NewFounderPage)}
            rule='founder.create'
            redirectUrl={Pages.FOUNDERS}
          />
          <RouteACL
            isProtected
            path={Pages.FOUNDER_DETAILS}
            component={ProtectedRoute(FounderPage)}
            rule='founder.details.view'
            redirectUrl={Pages.FOUNDERS}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.NEW_SESSIONS}
            component={ProtectedRoute(NewSessionPage)}
            rule='session.create'
            redirectUrl={Pages.SESSIONS}
          />
          <RouteACL
            isProtected
            path={Pages.SESSIONS_DETAILS}
            component={ProtectedRoute(SessionPage)}
            rule='session.details.view'
            redirectUrl={Pages.SESSIONS}
          />
          <RouteACL
            isProtected
            module={KnownModule.Core}
            path={Pages.SESSIONS}
            component={ProtectedRoute(Sessions)}
          />
          <RouteACL
            exact
            isProtected
            path={Pages.NEW_GATHERINGS}
            component={ProtectedRoute(NewGatheringPage)}
            rule='gathering.create'
            redirectUrl={Pages.GATHERINGS}
          />
          <RouteACL
            isProtected
            path={Pages.GATHERINGS_DETAILS}
            component={ProtectedRoute(GatheringPage)}
            rule='gathering.details.view'
            redirectUrl={Pages.SESSIONS}
          />
          <RouteACL
            isProtected
            module={KnownModule.Core}
            path={Pages.GATHERINGS}
            component={ProtectedRoute(GatheringsPage)}
          />
          <Route
            isProtected
            path={Pages.TENANT}
            component={ProtectedRoute(TenantPage)}
          />
          <RouteACL
            isProtected
            path={Pages.OH_DASHBOARD}
            component={ProtectedRoute(OfficeHours)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_ADVISORS}
            component={ProtectedRoute(DashboardAdvisorsList)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_APPOINTMENTS_NEW}
            component={ProtectedRoute(AddAppointment)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_APPOINTMENTS}
            component={ProtectedRoute(AppointmentsList)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_APPOINTMENTS_EDIT}
            component={ProtectedRoute(EditAppointment)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_APPOINTMENTS_LOGS}
            component={ProtectedRoute(AppointmentLogs)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_ADVISORS_NEW}
            component={ProtectedRoute(AddAdvisor)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
          />
          <RouteACL
            isProtected
            path={Pages.OH_ADVISORS_EDIT}
            component={ProtectedRoute(EditAdvisor)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_ADVISORS_VIEW}
            component={ProtectedRoute(ViewAdvisor)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_ADVISORS_AVAILABILITIES}
            component={ProtectedRoute(Availabilities)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_ADVISORS_LOGS}
            component={ProtectedRoute(AdvisorLogs)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            rule='advisor.logs.view'
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_COMMUNITY_MEMBERS}
            component={ProtectedRoute(CommunityMembersList)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_COMMUNITY_MEMBERS_CREATE}
            component={ProtectedRoute(AddCommunityMember)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_COMMUNITY_MEMBERS_EDIT}
            component={ProtectedRoute(EditCommunityMember)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_COMMUNITY_MEMBERS_LOGS}
            component={ProtectedRoute(Logs)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_COMMUNITY_MEMBERS_NOTES}
            component={ProtectedRoute(CommunityMemberNotesPage)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.OH_REPORTS}
            component={ProtectedRoute(OfficeHoursReports)}
            module={KnownModule.OfficeHours}
            role={Role.Manager}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.GOALS}
            component={ProtectedRoute(GoalsPage)}
            module={KnownModule.Goals}
            rule='goal.view'
            exact
          />
          <RouteACL
            isProtected
            path={Pages.PERSONAL_NOTES}
            component={ProtectedRoute(PersonalNotesPage)}
            rule='personalNotes.view'
            exact
          />
          <RouteACL
            isProtected
            path={Pages.NEW_GOAL}
            component={ProtectedRoute(NewGoalPage)}
            module={KnownModule.Goals}
            rule='goal.create'
            redirectUrl={Pages.GOALS}
            exact
          />
          <RouteACL
            isProtected
            path={Pages.GOAL_OVERVIEW}
            component={ProtectedRoute(GoalPage)}
            module={KnownModule.Goals}
            rule='goal.overview.view'
            redirectUrl={Pages.GOALS}
          />
          <Route
            path={Pages.OH_REPORT_SUBMISSION}
            component={OfficeHoursSurvey}
            exact
          />
          <Route
            path={Pages.OH_REPORT_SUBMISSION}
            component={OfficeHoursSurvey}
            exact
          />
          <Route
            path={Pages.PUBLIC_ADVISORS}
            component={PublicAdvisorsList}
            exact
          />
          <Route path={Pages.PUBLIC_OH_LANDING} component={Landing} exact />
          <Route
            path={Pages.PUBLIC_ADVISOR_PROFILE}
            component={AdvisorProfile}
            exact
          />
          <Route
            path={Pages.OH_COMMUNITY_MEMBER_SIGN_UP}
            component={CommunityMemberSignup}
            exact
          />
          <Route
            path={Pages.OH_COMMUNITY_MEMBER_ENROLL}
            component={Enroll}
            exact
          />
          <Route
            isProtected
            path={Pages.TEST}
            component={ProtectedRoute(Test)}
          />
          <DOMRoute exact path={Pages.HOME} component={HomePage} />
        </Switch>
      </RouterProvider>
    </DOMRouter>
  );
};

export default Router;
