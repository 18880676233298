import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import filesAPI from '../../api/files';
import { ExportIcon } from '../../assets/icons';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { Text } from '../common';

export interface UserLogoProps {
  bgText?: string;
  name: string;
  logo?: string;
  onUpload: (photo: File) => any;
  onRemove: () => any;
  readOnly?: boolean;
  showLabelIfEmpty?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 120,
    height: 120,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 40,
    overflow: 'hidden',
    background: COLORS.COLOR_GRAY_LIGHTENED_30,
    color: 'white',
    fontSize: 46,
    lineHeight: '68px',
    fontWeight: 700,
    fontFamily: MONTSERRAT_FONT_FAMILY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('xs')]: {
      width: 160,
      height: 160,
      fontSize: 56,
    },

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },

    '&:hover $background': {
      display: 'flex',
    },
  },
  input: {
    display: 'none',
  },
  name: {
    display: 'flex',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exportIcon: {
    lineHeight: 0,
    cursor: 'pointer',
  },
  actionsBtn: {
    padding: 0,
    minWidth: 'auto',
    color: '#dadada',
    fontSize: 24,

    '&:hover': {
      color: 'white',
    },
  },
  removeBtn: {
    marginLeft: 25,
  },
  background: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 40,
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: COLORS.COLOR_ADDITIONAL_DARK_TRANS_60,
  },
  forceLabel: {
    display: 'flex',
  },
  actions: {
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  newImageContent: {
    width: '100%',
    height: '100%',
    fontSize: 13,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '28px',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

export function UserLogo({
  bgText,
  name,
  logo,
  onUpload,
  onRemove,
  readOnly = false,
  showLabelIfEmpty = false,
}: UserLogoProps) {
  const classes = useStyles();
  const id = `icon-button-file-${name}`;

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      onUpload(selectedFile);
    }
  };

  const handleFileRemove = (event: React.SyntheticEvent) => {
    onRemove();
  };

  return (
    <div className={classes.container} data-testid='user-logo-input'>
      <input
        onChange={handleFileSelect}
        accept='image/*'
        className={classes.input}
        id={id}
        type='file'
      />
      {logo ? (
        <>
          {logo.includes('blob:') ? (
            <div
              style={{ backgroundImage: `url(${logo})` }}
              className={classes.image}
            />
          ) : (
            <Logo src={logo} />
          )}
          {!readOnly && (
            <div className={cn(classes.background, classes.actions)}>
              <div>
                <Tooltip title='Upload a new photo'>
                  <Button className={classes.actionsBtn} variant='text'>
                    <label className={classes.exportIcon} htmlFor={id}>
                      <ExportIcon />
                    </label>
                  </Button>
                </Tooltip>
                <Tooltip title='Remove photo'>
                  <Button
                    onClick={handleFileRemove}
                    className={cn(classes.actionsBtn, classes.removeBtn)}
                    variant='text'>
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <span className={classes.name}>{bgText || name || '?'}</span>
          {!readOnly && (
            <div
              className={cn(classes.background, {
                [classes.forceLabel]: showLabelIfEmpty && !logo,
              })}>
              <label htmlFor={id} className={classes.newImageContent}>
                <ExportIcon />
                <Text variant='normal2' inherit bold>
                  Upload photo
                </Text>
              </label>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function Logo({ src }: { src: string }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [url, setUrl] = useState<null | string>(null);

  useEffect(() => {
    const getImage = async (src: string) => {
      try {
        setError(false);
        setLoading(true);
        const newUrl = await filesAPI.getFileLogo(src);
        setUrl(newUrl);
        setLoading(false);
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    };

    getImage(src);
  }, [src]);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (error) {
    return <>error</>;
  }

  return (
    <div style={{ backgroundImage: `url(${url})` }} className={classes.image} />
  );
}

export default UserLogo;
