import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement } from 'react';
import { Success } from '../../../assets/icons';
import { TestId } from '../../Testing/TestId';
import { Button } from '../../common';
import { DialogTitle } from '../../common/dialog';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 12,
    },
  },
  title: {
    textAlign: 'center',

    '& button[aria-label=close] .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 14,
  },
  content: {
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    padding: 32,
  },
}));

export interface Props {
  open: boolean;
  onPay: () => void;
  onCancel: () => void;
}

export function BookedWithPayment({
  open,
  onPay,
  onCancel,
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Dialog open={open} className={classes.root}>
      <DialogTitle className={classes.title}>
        <Success />
        <Typography variant={'h3'}>Appointment requested</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.subtitle} variant={'body1'}>
          Please note, that advisor you requested a session with will charge
          some amount of dollars for the consultation.
          <br />
          You will be forwarded to Stripe now to pay for the session before we
          can confirm it.
        </Typography>

        <TestId testId={'proceed-button'}>
          <Button onClick={onPay}>Proceed to Payment</Button>
        </TestId>
        <TestId testId={'cancel-appointment-button'}>
          <Button variant='outlined' onClick={onCancel}>
            Cancel Appointment
          </Button>
        </TestId>
      </DialogContent>
    </Dialog>
  );
}
