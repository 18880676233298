import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import cn from 'classnames';
import { add, getDate, getDay, getWeekOfMonth } from 'date-fns';
import { mPipe, pass } from 'fp-utilities';
import { ChangeEvent, ReactElement } from 'react';
import { WeekDay } from '../../api/types/WeekDay';
import {
  CustomPeriod,
  Monthly,
} from '../../pages/Advisors/Availabilities/types/CustomPeriod';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';
import { isNumber } from '../../utils/Num';
import { getDayOfWeekString } from '../../utils/functions';
import { unreachableError } from '../../utils/unreachableError';
import { TestId } from '../Testing/TestId';
import { SimpleSelect } from './SimpleSelect';
import { Button, DateInput, Dialog, FormButtons, Text } from './index';

const useStyles = makeStyles({
  inputNumber: {
    padding: '7px 10px 10px !important',
    width: '39px !important',
  },
  selectTypeRepetitions: {
    '& > div': {
      padding: '9px !important',
    },
  },
  selectPeriodMonths: {
    marginTop: 10,
    '& > div': {
      padding: '9px 30px 9px 9px !important',
    },
  },
  containerRepeatEvery: {
    display: 'flex',
    columnGap: 25,
    alignItems: 'center',
  },
  containerRepeatOn: {
    marginTop: 15,
  },
  itemWeek: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
    borderRadius: 13,
    cursor: 'pointer',
    '&> span': {
      color: COLORS.COLOR_GRAY_DARKENED_15,
      fontSize: 12,
    },

    '&:hover': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_40,
    },
  },
  listWeek: {
    display: 'flex',
    columnGap: 10,
    marginTop: 10,
  },
  activeItemWeek: {
    backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_10,
    '&> span': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_25,
    },
  },
  radioboxWrapper: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
  },
  dateInput: {
    marginLeft: 15,

    '& > div > input': {
      maxWidth: 120,
      padding: '12.5px 13px',
    },
  },
  radioGroup: {
    rowGap: 10,
    '& span': {
      fontSize: 14,
      fontFamily: INTER_FONT_FAMILY,
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
});

export interface Props {
  open: boolean;
  date: Date;
  onCancel: () => void;
  onSave: () => void;
  value: CustomPeriod;
  onChange: (v: CustomPeriod) => void;
}

export function CustomPeriodDialog({
  open,
  onCancel,
  onSave,
  value,
  onChange,
  date,
}: Props): ReactElement {
  const classes = useStyles();

  const handleRepeat = mPipe(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => e.target.value,
    Number,
    pass(isNumber),
    (v) => {
      switch (value.type) {
        case 'Monthly':
          break;
        case 'Daily': {
          onChange({
            type: 'Daily',
            endType: value.endType,
            every: v,
          });
          break;
        }
        case 'Weekly': {
          onChange({
            type: 'Weekly',
            endType: value.endType,
            every: v,
            weekDay: getDay(date) as WeekDay,
          });
          break;
        }
        default:
          unreachableError(value);
      }
    },
  );

  return (
    <Dialog
      open={open}
      setOpen={onCancel}
      title='Custom recurrence'
      width={400}
      contentRenderer={() => (
        <div>
          <div className={classes.containerRepeatEvery}>
            <div>
              <Text variant='normal'>Repeat every</Text>
            </div>
            <TextField
              value={getRepeat(value)}
              disabled={value.type === 'Monthly'}
              type='number'
              variant='outlined'
              onChange={(e) => handleRepeat(e)}
              InputProps={{
                inputProps: {
                  'data-testid': 'repeat-count',
                  className: classes.inputNumber,
                  min: 0,
                  max: 100,
                },
              }}
            />
            <TestId testId='repeat-type'>
              <SimpleSelect<CustomPeriod['type']>
                value={value.type}
                options={periodOptions()}
                onChange={(v) => {
                  switch (v) {
                    case 'Daily': {
                      onChange({
                        type: 'Daily',
                        every: getRepeat(value),
                        endType: value.endType,
                      });
                      break;
                    }
                    case 'Weekly': {
                      onChange({
                        type: 'Weekly',
                        every: getRepeat(value),
                        endType: value.endType,
                        weekDay: getDay(date) as WeekDay,
                      });
                      break;
                    }
                    case 'Monthly': {
                      onChange({
                        type: 'Monthly',
                        endType: value.endType,
                        on: 'fixedDate',
                      });
                      break;
                    }
                  }
                }}
                className={classes.selectTypeRepetitions}
              />
            </TestId>
          </div>
          {value.type === 'Monthly' ? (
            <div>
              <TestId testId='monthly-type'>
                <SimpleSelect<Monthly['on']>
                  value={value.on}
                  options={monthlyOptions(date)}
                  onChange={(on) => onChange({ ...value, on })}
                  className={classes.selectPeriodMonths}
                />
              </TestId>
            </div>
          ) : null}

          {value.type === 'Weekly' ? (
            <div className={classes.containerRepeatOn}>
              <div>
                <Text variant='normal'>Repeat on</Text>
              </div>
              <div className={classes.listWeek}>
                {weeklyOptions().map((option) => {
                  return (
                    <div
                      className={cn(classes.itemWeek, {
                        [classes.activeItemWeek]:
                          value.weekDay === option.value,
                      })}
                      onClick={() =>
                        onChange({ ...value, weekDay: option.value })
                      }>
                      <Text variant='normal'>{option.label}</Text>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className={classes.containerRepeatOn}>
            <div>
              <Text variant='normal'>Ends</Text>
            </div>

            <RadioGroup
              className={classes.radioGroup}
              aria-label='quiz'
              value={value.endType.ends}
              onChange={(e) => {
                onChange({
                  ...value,
                  endType: {
                    ...value.endType,
                    ends: e.target.value as CustomPeriod['endType']['ends'],
                  },
                });
              }}>
              <FormControlLabel
                value={'hundredOccurrences'}
                control={<Radio color='primary' />}
                label='After 100 occurrences'
              />
              <div className={classes.radioboxWrapper}>
                <FormControlLabel
                  value='onDate'
                  control={<Radio color='primary' />}
                  label='On'
                  data-testid='check-on-date'
                />
                <DateInput
                  disabled={value.endType.ends !== 'onDate'}
                  className={classes.dateInput}
                  editable
                  value={value.endType.date}
                  testid='end-on-date'
                  onChange={(v) => {
                    v &&
                      onChange({
                        ...value,
                        endType: { ...value.endType, date: v },
                      });
                  }}
                  minDate={new Date()}
                  maxDate={add(new Date(), { years: 3 })}
                />
              </div>
              <div className={classes.radioboxWrapper}>
                <FormControlLabel
                  value='occurrences'
                  control={<Radio color='primary' />}
                  label='After'
                  data-testid='check-end-after-occurrences'
                />
                <TextField
                  value={value.endType.occurrences}
                  type='number'
                  variant='outlined'
                  disabled={value.endType.ends !== 'occurrences'}
                  onChange={(e) => {
                    const number = Number(e.target.value);
                    isNumber(number) &&
                      onChange({
                        ...value,
                        endType: { ...value.endType, occurrences: number },
                      });
                  }}
                  InputProps={{
                    inputProps: {
                      className: classes.inputNumber,
                      min: 0,
                      max: 100,
                      'data-testid': 'occurrences-count',
                    },
                  }}
                />
                <Text variant='normal'>occurrences</Text>
              </div>
            </RadioGroup>
          </div>

          <FormButtons className={classes.formButtons}>
            <Button data-testid='button-submit-form' onClick={onSave}>
              Save
            </Button>
            <Button variant='outlined' onClick={onCancel}>
              Cancel
            </Button>
          </FormButtons>
        </div>
      )}
    />
  );
}

function getRepeat(v: CustomPeriod): number {
  switch (v.type) {
    case 'Daily':
    case 'Weekly':
      return v.every;
    case 'Monthly':
      return 1;
  }
}

function periodOptions(): Array<{
  value: CustomPeriod['type'];
  label: string;
}> {
  return [
    {
      value: 'Daily',
      label: 'Daily',
    },
    {
      value: 'Weekly',
      label: 'Weekly',
    },
    {
      value: 'Monthly',
      label: 'Monthly',
    },
  ];
}

function monthlyOptions(date: Date): Array<{
  value: Monthly['on'];
  label: string;
}> {
  return [
    {
      label: `Monthly on day ${getDate(date)}`,
      value: 'fixedDate',
    },
    {
      label: `Monthly on ${weekOrder(
        getWeekOfMonth(date) as 1 | 2 | 3 | 4,
      )} ${getDayOfWeekString(getDay(date))}`,
      value: 'nthDay',
    },
  ];
}

function weeklyOptions(): Array<{
  value: WeekDay;
  label: string;
}> {
  return [
    { value: 7, label: 'Sun' },
    { value: 1, label: 'Mon' },
    { value: 2, label: 'Tue' },
    { value: 3, label: 'Wen' },
    { value: 4, label: 'Thu' },
    { value: 5, label: 'Fri' },
    { value: 6, label: 'Sat' },
  ];
}

function weekOrder(v: 1 | 2 | 3 | 4): string {
  switch (v) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
  }
}
