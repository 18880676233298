import {
  Button as MaterialButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Bowser from 'bowser';
import cn from 'classnames';
import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MentorStatus } from '../../api/mentors';
import { VentureId } from '../../api/ventures/types/Venture';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { checkSizeTablet, isTablet } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Toggler } from '../common';
import { CreateSchedulingWizardButton } from '../common/create-scheduling-wizard-button';

interface HeaderProps {
  title: string;
  status?: MentorStatus;
  statuses?: Array<{
    label: string;
    value: MentorStatus;
  }>;
  backLink: React.ReactNode;
  additional?: React.ReactNode;
  onStatusChange?: (status: MentorStatus) => any;
  actionRenderer?: () => any;
  prevPath?: string;
  ventureId?: VentureId;
  isShowToggler?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 28,
  },
  containerTablet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: 28,
    rowGap: 10,
    padding: '0 15px',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
  },
  activeTablet: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeButtonTablet: {
    display: 'flex',
  },
  title: {
    marginLeft: 5,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    margin: '5px 0 0 20px',
  },
  newSessionButton: {
    marginRight: 15,
    whiteSpace: 'nowrap',
    minWidth: 108,
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

const defaultStatuses: Array<{
  label: string;
  value: MentorStatus;
  className: string;
}> = [
  {
    label: 'Active',
    value: MentorStatus.ACTIVE,
    className: CLASS_TRACKING.INTERNAL_ACTION,
  },
  {
    label: 'Archived',
    value: MentorStatus.ARCHIVED,
    className: CLASS_TRACKING.INTERNAL_ACTION,
  },
];

function Header({
  title,
  status,
  statuses = defaultStatuses,
  onStatusChange,
  backLink,
  actionRenderer = () => {},
  additional,
  ventureId,
  isShowToggler = true,
}: HeaderProps) {
  const classes = useStyles();
  const location = useLocation();
  const { hasAccessToAction } = useContext(UserContext);
  const browser = Bowser.getParser(window.navigator.userAgent);

  const isTabletVersion =
    browser?.getPlatform().type === 'tablet' || isTablet();

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  if (isTabletVersion) {
    return (
      <div className={cn(classes.containerTablet, classes.noPrint)}>
        <div className={classes.activeTablet}>
          {backLink}
          <div className={classes.activeButtonTablet}>
            {hasAccessToAction('session.create') && ventureId && (
              <MaterialButton
                component={Link}
                to={{
                  pathname: getRoutePath(Pages.NEW_SESSIONS),
                  state: {
                    ventureId: ventureId,
                    prevPath: currentPath,
                  },
                }}
                startIcon={!checkSizeTablet(500) && <AddIcon />}
                variant='contained'
                color='primary'
                className={classes.newSessionButton}
                data-testid='button-create-session'>
                {checkSizeTablet(500) ? <AddIcon /> : 'Session'}
              </MaterialButton>
            )}
            {isShowToggler &&
              !!status &&
              typeof onStatusChange === 'function' && (
                <Toggler
                  value={status}
                  options={statuses}
                  onChange={onStatusChange}
                />
              )}

            {actionRenderer()}
          </div>
        </div>

        <div className={classes.title}>
          <Typography variant='h2'>{title}</Typography>
          <div className={classes.subtitle}>{additional}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(classes.container, classes.noPrint)}>
      {backLink}
      <div className={classes.title}>
        <Typography variant='h2'>{title}</Typography>
        <div className={classes.subtitle}>{additional}</div>
      </div>
      {hasAccessToAction('session.create') && ventureId && (
        <MaterialButton
          component={Link}
          to={{
            pathname: getRoutePath(Pages.NEW_SESSIONS),
            state: {
              ventureId: ventureId,
              prevPath: currentPath,
            },
          }}
          startIcon={<AddIcon />}
          variant='contained'
          color='primary'
          className={classes.newSessionButton}
          data-testid='button-create-session'>
          Session
        </MaterialButton>
      )}
      {hasAccessToAction('session.create') && ventureId && (
        <CreateSchedulingWizardButton ventureId={ventureId} />
      )}

      {isShowToggler && !!status && typeof onStatusChange === 'function' && (
        <Toggler value={status} options={statuses} onChange={onStatusChange} />
      )}

      {actionRenderer()}
    </div>
  );
}

export { Header };
