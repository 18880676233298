import { Text } from './index';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    columnGap: '25px',
    alignItems: 'center',
  },
  arrow: {
    minWidth: 0,

    '&:not(:disabled)': {
      color: COLORS.COLOR_GRAY_BASE,
    },
  },
}));

export interface Props {
  page: number;
  onPrev: () => void,
  onNext: () => void,
  disablePrev?: boolean;
  disableNext?: boolean;
}

export function Pagination({ page, onPrev, onNext, disableNext, disablePrev }: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text variant='normal2'>Page {page}</Text>
      <div>
        <Button
          className={classes.arrow}
          disabled={disablePrev}
          onClick={onPrev}>
          <ChevronLeftIcon />
        </Button>
        <Button
          className={classes.arrow}
          disabled={disableNext}
          onClick={onNext}>
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
}