import { mPipe, pass } from 'fp-utilities';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { BehaviorSubject, of } from 'rxjs';
import { format } from 'date-fns/fp';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { useQuery } from '../../../hooks/useQuery';
import { isStringDate, toDate } from '../../../utils/date/StringDate';
import * as Api from './Api';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';
import { statusFromString } from './utils';

export function useAppointmentsList(): [
  State.State,
  Dispatch<Actions.PublicActions>,
] {
  const query = useQuery();
  const status = mPipe(statusFromString)(query.get('status')) ?? 'nonaccepted';
  const start = mPipe(pass(isStringDate), toDate)(query.get('start'));
  const end = mPipe(pass(isStringDate), toDate)(query.get('end'));
  const [state, dispatch] = useReducer(
    reducer,
    State.loading({
      status,
      dateRange:
        start || end
          ? ([start ?? end, end ?? start] as [Date, Date])
          : undefined,
      page: 1,
    }),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subject = useMemo(() => new BehaviorSubject<State.State>(state), []);

  useEffect(() => {
    subject.next(state);
  }, [state, subject]);

  useEffect(() => {
    const loading$ = subject
      .pipe(
        filter((s): s is State.Loading => s.type === 'Loading'),
        switchMap((s) =>
          Api.get(s.payload.dateRange, s.payload.status, s.payload.page),
        ),
        map(Actions.loadSuccess),
        catchError(() => of(Actions.loadError('Unable to load items'))),
      )
      .subscribe(dispatch);

    const remove$ = subject
      .pipe(
        filter((s): s is State.Removing => s.type === 'Removing'),
        switchMap((s) =>
          Api.archive(
            s.payload.id,
            s.payload.dateRange,
            s.payload.status,
            s.payload.page,
          ),
        ),
        map(Actions.removeSuccess),
        catchError(() => of(Actions.removeError('Unable to remove item'))),
      )
      .subscribe(dispatch);

    const bulk$ = subject
      .pipe(
        filter((s): s is State.BulkRemove => s.type === 'BulkRemove'),
        switchMap((s) =>
          Api.bulkArchive(
            s.payload.items.filter((i) => i.selected).map((i) => i.id),
            s.payload.dateRange,
            s.payload.status,
            s.payload.page,
          ),
        ),
        map(Actions.bulkSuccess),
        catchError(() => of(Actions.bulkError('Unable to remove items'))),
      )
      .subscribe(dispatch);

    const router$ = subject
      .pipe(
        map((s) => [s.payload.status, s.payload.dateRange] as const),
        distinctUntilChanged(
          ([s1, d1], [s2, d2]) =>
            s1 === s2 &&
            d1?.[0].getTime() === d2?.[0].getTime() &&
            d1?.[1].getTime() === d2?.[1].getTime(),
        ),
        map(
          ([status, dates]): {
            status: string | undefined;
            start: string | undefined;
            end: string | undefined;
          } => {
            return {
              status,
              start: mPipe(format('MM-dd-yyyy'))(dates?.[0]),
              end: mPipe(format('MM-dd-yyyy'))(dates?.[1]),
            };
          },
        ),
      )
      .subscribe(query.set);

    return () => {
      loading$.unsubscribe();
      remove$.unsubscribe();
      bulk$.unsubscribe();
      router$.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, dispatch];
}
