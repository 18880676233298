import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HelpIcon from '@material-ui/icons/Help';
import { optional, parse } from 'fp-utilities';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router';
import eventsAPI, {
  EventChannelId,
  EventDetails,
  EventId,
  EventTag,
} from '../../api/events';
import { Founder } from '../../api/founders';
import { Mentor } from '../../api/mentors';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import venturesAPI from '../../api/ventures';
import { VentureId } from '../../api/ventures/types/Venture';
import { SessionContext } from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { isValidateDate } from '../../utils/date';
import { compareDates } from '../../utils/date/CompareDates';
import { ZonedDate } from '../../utils/date/ZonedDate';
import { isMobile, validateEmailList } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  BeforeUnload,
  Button,
  FieldDatesDetails,
  FormGroup,
  SelectVentureSessionPopover,
  StickyContent,
  VentureFounderSelect,
  VentureMentorSelect,
} from '../common';
import { DatesOutOfRange } from '../common/Confirmation/DatesOutOfRange';
import {
  FormIssuesInput,
  FormSelect,
  TextFieldSummary,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from './wrappers';

interface SessionDetailsFormProps {
  sessionDetails?: EventDetails;
  loading?: boolean;
  onSubmit: (
    parsedFormValues: ParsedFormValues,
    selectedFounders: Founder[],
    selectedMentors: Mentor[],
  ) => any;
  handleOpenAdditionalModal?: (
    parsedFormValues: ParsedFormValues,
    selectedFounders: Founder[],
    selectedMentors: Mentor[],
  ) => any;
  founders?: Founder[];
  mentors?: Mentor[];
  issues?: EventTag['tagId'][];
  defaultLocation: string | undefined;
  timeZone: TenantTimeZone;
}

interface FormValues {
  ventureId: VentureId | undefined;
  ventureName: string;
  status: string;
  start: string;
  end: string;
  tenantEventChannelId: EventChannelId | 'physical' | undefined;
  physicalLocation: undefined | string;
  summary: string;
  description: string;
  emailList: string;
  issues: EventTag['tagId'][];
}

interface SessionDuplicateInfo {
  ventureId: VentureId;
  ventureName: string;
  summary?: string;
  emailList?: string;
  tenantEventChannelId?: EventChannelId;
  description?: string;
}
export interface ParsedFormValues {
  values: EventDetails;
  issues: EventTag['tagId'][];
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const STATUSES = [
  {
    value: 'CREATED',
    label: 'Created',
  },
  {
    value: 'PUBLISHED',
    label: 'Published',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
  },
  {
    value: 'ARCHIVED',
    label: 'Archived',
  },
];

const useStyles = makeStyles({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainFormBlock: {
    width: 560,
  },
  actionsBlock: {
    marginTop: 56,
  },
  description: {
    '& textarea': {
      minHeight: 100,
    },
  },
  selectVenture: {
    width: '100%',
    height: 54,
  },
});

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.ventureId) {
    errors.ventureId = 'Required';
  }
  if (!values.start) {
    errors.start = 'Required';
  }
  if (!values.end) {
    errors.end = 'Required';
  }
  if (values.start && !isValidateDate(values.start)) {
    errors.start = 'Invalid';
  }
  if (values.end && !isValidateDate(values.end)) {
    errors.end = 'Invalid';
  }
  if (
    values.start &&
    values.end &&
    isValidateDate(values.start) &&
    isValidateDate(values.end) &&
    compareDates(values.start, values.end)
  ) {
    errors.end = 'Less than Start';
  }
  if (!values.status) {
    errors.status = 'Required';
  }
  if (!values.summary) {
    errors.summary = 'Required';
  }
  if (values.emailList && !validateEmailList(values.emailList)) {
    errors.emailList = 'Invalid';
  }

  if (values.tenantEventChannelId === undefined) {
    errors['tenantEventChannelId'] = 'Required';
  }

  if (values.tenantEventChannelId === 'physical' && !values.physicalLocation) {
    errors['physicalLocation'] = 'Required';
  }

  return errors;
};

function getInitialValues(
  details?: EventDetails,
  duplicateInfo?: SessionDuplicateInfo,
  issues: EventTag['tagId'][] = [],
  defaultLocation?: string,
): FormValues {
  const channelId =
    details?.tenantEventChannelId || duplicateInfo?.tenantEventChannelId;

  return {
    ventureId: details?.ventureId ?? duplicateInfo?.ventureId,
    ventureName: details?.ventureName || duplicateInfo?.ventureName || '',
    status: details?.status || 'CREATED',
    tenantEventChannelId:
      !channelId && details?.physicalLocation ? 'physical' : channelId,
    physicalLocation: details?.physicalLocation ?? defaultLocation,
    summary: details?.summary || duplicateInfo?.summary || '',
    description: details?.description || duplicateInfo?.description || '',
    start: details?.start || '',
    end: details?.end || '',
    emailList: details?.emailList || duplicateInfo?.emailList || '',
    issues,
  };
}

const getParsedValues = parse<FormValues, ParsedFormValues>({
  issues: (v) => v.issues,
  values: parse<FormValues, ParsedFormValues['values']>({
    start: (v) => v.start,
    end: (v) => v.end,
    status: (v) => v.status,
    tenantEventChannelId: optional((v) =>
      v.tenantEventChannelId === 'physical' ? null : v.tenantEventChannelId,
    ),
    physicalLocation: optional((v) => v.physicalLocation ?? null),
    ventureId: (v) => v.ventureId,
    description: optional((v) => v.description || null),
    emailList: optional((v) => v.emailList || null),
    summary: (v) => v.summary,
  }),

  // values: {
  //   start: formValues.start,
  //   end: formValues.end,
  //   status: formValues.status,
  //   tenantEventChannelId: formValues.tenantEventChannelId,
  //   ventureId: formValues.ventureId,
  //   description: formValues.description || null,
  //   emailList: formValues.emailList || null,
  //   summary: formValues.summary,
  // },
  // issues: formValues.issues || [],
});

function SessionDetailsForm({
  sessionDetails,
  loading = false,
  onSubmit,
  founders,
  mentors,
  issues,
  handleOpenAdditionalModal,
  defaultLocation,
  timeZone,
}: SessionDetailsFormProps) {
  const classes = useStyles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const { isCancelEvent } = useContext(SessionContext);
  const [selectedFounders, setSelectedFounders] = useState(founders);
  const [selectedMentors, setSelectedMentors] = useState(mentors);
  const [duplicateInfo, setDuplicateInfo] = useState<SessionDuplicateInfo>();
  const location = useLocation<{
    ventureId?: VentureId;
    sessionId?: EventId;
  }>();
  const [ventureId] = useState(location.state?.ventureId);
  const [sessionId] = useState(location.state?.sessionId);

  const isInitedVentureId = useRef<boolean>(true);

  const { channels, hasAccessToAction } = useContext(UserContext);
  const channelList = useMemo(() => {
    if (!channels) {
      return [];
    }
    return [
      ...channels.map((channel) => ({
        label: channel.channelName,
        value: channel.id,
      })),
      {
        label: 'No Virtual Channel',
        value: 'physical',
      },
    ];
  }, [channels]);

  const isDirtyAssignements = useMemo(() => {
    const initialFounders = (founders || []).map((founder) => founder.id);
    const initialMentors = (mentors || []).map((mentor) => mentor.id);
    const currentFounders = (selectedFounders || []).map(
      (founder) => founder.id,
    );
    const currentMentors = (selectedMentors || []).map((mentor) => mentor.id);
    return (
      !isEqual(initialFounders, currentFounders) ||
      !isEqual(initialMentors, currentMentors)
    );
  }, [founders, mentors, selectedFounders, selectedMentors]);

  const handleSelectVenture = (
    onChange: any,
    value: { id: string; ventureName: string },
  ) => {
    onChange('ventureId', value?.id || '');
    onChange('ventureName', value?.ventureName || '');
  };

  const handleSubmit = (formValues: FormValues) => {
    const isChangeTime =
      formValues.end !== initialValues.end ||
      formValues.start !== initialValues.start;

    const parsedFormValues = getParsedValues(formValues);

    if (!parsedFormValues) return;

    if (isChangeTime && typeof handleOpenAdditionalModal === 'function') {
      return handleOpenAdditionalModal(
        parsedFormValues,
        selectedFounders || [],
        selectedMentors || [],
      );
    }

    return onSubmit(
      parsedFormValues,
      selectedFounders || [],
      selectedMentors || [],
    );
  };

  const initialValues = useMemo(() => {
    return getInitialValues(
      sessionDetails,
      duplicateInfo,
      issues,
      defaultLocation,
    );
  }, [sessionDetails, duplicateInfo, issues, defaultLocation]);

  const loadVentureInfo = async (ventureId: VentureId) => {
    const ventureInfoRequest = await venturesAPI.getVenture(ventureId);
    setDuplicateInfo({
      ventureId: ventureInfoRequest.id,
      ventureName: ventureInfoRequest.ventureName,
    });
  };

  const loadFullDetailsEvent = async (eventId: EventId) => {
    const fullDetailsRequest = await eventsAPI.getFullEvent(eventId);

    setDuplicateInfo({
      ventureId: fullDetailsRequest.event.ventureId,
      ventureName: fullDetailsRequest.event.ventureName,
      summary: fullDetailsRequest.event.summary,
      emailList: fullDetailsRequest.event.emailList,
      description: fullDetailsRequest.event.description,
    });
    setSelectedFounders(fullDetailsRequest.founderList);
    setSelectedMentors(fullDetailsRequest.mentorList);
  };

  useEffect(() => {
    if (mentors) setSelectedMentors(mentors);
  }, [mentors]);

  useEffect(() => {
    if (founders) setSelectedFounders(founders);
  }, [founders]);

  useEffect(() => {
    if (ventureId) {
      loadVentureInfo(ventureId);
    }
  }, [ventureId]);

  useEffect(() => {
    if (sessionId) {
      isInitedVentureId.current = false;
      loadFullDetailsEvent(sessionId);
    }
  }, [sessionId]);

  const getDates = (form: FormValues): ZonedDate<TenantTimeZone>[] => {
    return [
      new ZonedDate(new Date(form.start), timeZone),
      new ZonedDate(new Date(form.end), timeZone),
    ];
  };

  return (
    <div>
      <DatesOutOfRange
        dates={[initialValues.start, initialValues.end]
          .filter((v) => !!v)
          .map((v) => new ZonedDate<TenantTimeZone>(new Date(v), timeZone))}
        getDates={getDates}
        onSubmit={handleSubmit}>
        {({ onSubmit }) => {
          return (
            <Form
              validate={validateForm}
              onSubmit={onSubmit}
              initialValues={initialValues}
              keepDirtyOnReinitialize={false}
              render={(formProps) => {
                setSubmitValidationFailed(
                  formProps.submitFailed &&
                    !formProps.dirtySinceLastSubmit &&
                    !formProps.submitting,
                );
                return (
                  <>
                    <BeforeUnload
                      when={
                        !isCancelEvent &&
                        (formProps.dirty || isDirtyAssignements) &&
                        !loading
                      }
                      title='Leave the page'
                      body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                      disabled={loading}
                      confirmButtonRenderer={({ onConfirm }) => (
                        <Button
                          variant='outlined'
                          onClick={async () => {
                            try {
                              await formProps.handleSubmit();
                            } catch (e: any) {}
                            onConfirm();
                          }}
                          disabled={loading || !formProps.valid}>
                          {loading ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            'Save the changes'
                          )}
                        </Button>
                      )}
                    />
                    <div
                      className={classes.formBlocks}
                      data-testid='session-details-form'>
                      <div className={classes.mainFormBlock}>
                        <form noValidate>
                          <div className={classes.sectionBlock}>
                            <FormGroup mobile={isMobile()}>
                              <div>
                                <Field
                                  name='ventureId'
                                  component={() => <div />}
                                />
                                <SelectVentureSessionPopover
                                  value={
                                    formProps.values.ventureId
                                      ? {
                                          id: formProps.values.ventureId,
                                          ventureName:
                                            formProps.values.ventureName,
                                        }
                                      : undefined
                                  }
                                  onChange={(value: {
                                    id: string;
                                    ventureName: string;
                                  }) => {
                                    isInitedVentureId.current = true;
                                    handleSelectVenture(
                                      formProps.form.change,
                                      value,
                                    );
                                  }}
                                  small
                                  className={classes.selectVenture}
                                  required
                                  readOnly={
                                    !hasAccessToAction('session.details.update')
                                  }
                                />
                              </div>
                              <Field<string>
                                testid='session-details-status'
                                name='status'
                                component={FormSelect}
                                label='Status'
                                options={STATUSES}
                                readOnly={
                                  !hasAccessToAction('session.details.update')
                                }
                              />
                            </FormGroup>
                            <FieldDatesDetails
                              readOnly={
                                !hasAccessToAction('session.details.update')
                              }
                              minEndDate={
                                formProps.values?.start
                                  ? new Date(formProps.values.start)
                                  : undefined
                              }
                            />
                            <FormGroup>
                              <Field
                                name='issues'
                                data-testid='session-details-issues'
                                component={FormIssuesInput}
                                label='Issues'
                                readOnly={
                                  !hasAccessToAction('session.details.update')
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <Field<string>
                                testid='session-details-channel-id'
                                name='tenantEventChannelId'
                                component={FormSelect}
                                label='Channel*'
                                options={channelList}
                                readOnly={
                                  !hasAccessToAction('session.details.update')
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <Field<string>
                                name='physicalLocation'
                                component={TextFieldSummary}
                                testid='session-details-location'
                                label={`In-person location${
                                  formProps.values.tenantEventChannelId ===
                                  'physical'
                                    ? '*'
                                    : ''
                                }`}
                                ventureName={formProps.values.physicalLocation}
                                InputProps={{
                                  inputProps: {
                                    readOnly: !hasAccessToAction(
                                      'session.details.update',
                                    ),
                                  },
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Field<string>
                                name='summary'
                                component={TextFieldSummary}
                                testid='session-details-summary'
                                label='Summary*'
                                ventureName={formProps.values.ventureName}
                                InputProps={{
                                  inputProps: {
                                    readOnly: !hasAccessToAction(
                                      'session.details.update',
                                    ),
                                  },
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Field<string>
                                name='description'
                                component={TextFieldWysiwyg}
                                testid='session-details-description'
                                placeholder='Description...'
                                multiline
                                className={classes.description}
                                readOnly={
                                  !hasAccessToAction('session.details.update')
                                }
                              />
                            </FormGroup>

                            <FormGroup>
                              <Field<string>
                                name='emailList'
                                testid='session-details-email-list'
                                component={TextFieldWrapper}
                                label='Invitees'
                                InputProps={{
                                  endAdornment: (
                                    <Tooltip title='Please enter a comma-separated list of email addresses to continue'>
                                      <IconButton>
                                        <HelpIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ),
                                  inputProps: {
                                    readOnly: !hasAccessToAction(
                                      'session.details.update',
                                    ),
                                  },
                                }}
                              />
                            </FormGroup>

                            <FormGroup>
                              <VentureFounderSelect
                                ventureId={formProps.values.ventureId}
                                founders={selectedFounders}
                                onChange={(founders?: Founder[]) => {
                                  if (isInitedVentureId.current) {
                                    setSelectedFounders(founders);
                                  }
                                }}
                                readOnly={
                                  !hasAccessToAction('session.details.update')
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <VentureMentorSelect
                                ventureId={formProps.values.ventureId}
                                mentors={selectedMentors}
                                onChange={(mentors?: Mentor[]) => {
                                  if (isInitedVentureId.current) {
                                    setSelectedMentors(mentors);
                                  }
                                }}
                                readOnly={
                                  !hasAccessToAction('session.details.update')
                                }
                              />
                            </FormGroup>
                          </div>
                          {hasAccessToAction('session.details.update') && (
                            <div className={classes.actionsBlock}>
                              <StickyContent>
                                <Button
                                  className={CLASS_TRACKING.INTERNAL_ACTION}
                                  onClick={async () => {
                                    try {
                                      await formProps.handleSubmit();
                                    } catch (e: any) {}
                                  }}
                                  disabled={loading}
                                  data-testid='submit-form'
                                  startIcon={<CheckIcon />}>
                                  {loading ? (
                                    <CircularProgress
                                      size={24}
                                      color='inherit'
                                    />
                                  ) : (
                                    'Save'
                                  )}
                                </Button>
                              </StickyContent>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          );
        }}
      </DatesOutOfRange>
    </div>
  );
}

export default SessionDetailsForm;
