import { makeStyles, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as advisorsAPI from '../../../api/Advisors';
import { AdvisorWithSpecializations } from '../../../api/Advisors/types/Advisor';
import * as appointmentsAPI from '../../../api/Appointments';
import {
  Appointment,
  AppointmentId,
  AppointmentPaymentStatus,
} from '../../../api/Appointments/types/Appointment';
import { Tenant } from '../../../api/auth';
import { TabItem, Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { AddIntentModal } from '../../../components/appointments/add-intent-modal';
import { RefundPaymentModal } from '../../../components/appointments/refund-payment-modal';
import {
  ConfirmButton,
  PageLoader,
  Text,
  Button,
  SnackMessage,
} from '../../../components/common';
import AppointmentForm, {
  AppointmentFormValues,
} from '../../../components/forms/appointment-form';
import BaseLayout from '../../../components/layout/base-layout';
import { UserContext } from '../../../contexts/user-context';
import { getRoutePath, Pages } from '../../../router/constants';
import { COLORS } from '../../../theme/variables';
import { Email } from '../../../utils/String/Email';
import { getAppointmentTabs } from '../common/utils';

interface Props {
  user: Tenant;
}

interface SuccessState {
  type: 'success';
  appointment: Appointment;
  advisor: AdvisorWithSpecializations | null;
}

interface LoadingState {
  type: 'loading';
}

interface ErrorState {
  type: 'error';
  message: string;
}

type State = SuccessState | LoadingState | ErrorState;

enum ActionTypes {
  INVITE = 'invite',
  CANCEL = 'cancel',
  ADD_PAYMENT = 'add-payment',
  REFUND = 'refund',
}

enum ActionModals {
  ADD_INTENT = 'add-intent',
  CONFIRM_PAYMENT = 'confirm-payment',
  REFUND_PAYMENT = 'refund-payment',
}

const useStyles = makeStyles(() => ({
  loaderContainer: {
    display: 'flex',
    flexGrow: 10,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
  },
  actionButton: {
    marginLeft: 15,
  },
}));

export const EditAppointment: FC<Props> = ({ user }) => {
  const { id } = useParams<{ id: AppointmentId }>();
  const { hasAccessToAction } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = useState<State>({ type: 'loading' });
  const [processingAction, setProcessingAction] = useState<ActionTypes | null>(
    null,
  );
  const [showModal, setShowModal] = useState<ActionModals | null>(null);

  const tabs: TabItem[] = useMemo(() => getAppointmentTabs(id), [id]);

  const handleSendInvites = useCallback(
    async (cb: () => any) => {
      try {
        if (state.type !== 'success') return;

        setProcessingAction(ActionTypes.INVITE);
        const updatedSession = await appointmentsAPI.sendInvites(id);
        setState({
          type: 'success',
          appointment: updatedSession,
          advisor: state.advisor,
        });
        enqueueSnackbar('The invitations were sent successfully', {
          variant: 'success',
        });
        cb();
      } catch (e: any) {
        const messageError = e.response?.data?.message;
        enqueueSnackbar(
          'An error occurred while sending invitations. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        cb();
      } finally {
        setProcessingAction(null);
      }
    },
    [enqueueSnackbar, id, state],
  );

  const handleSubmit = useCallback(
    async (data: AppointmentFormValues) => {
      try {
        if (state.type !== 'success') return;

        const {
          topic,
          start,
          end,
          location,
          requestorFullName,
          requestorEmail,
          status,
          advisor: { value: advisorId, label: advisorFullName },
          agenda,
        } = data;
        const appointment = await appointmentsAPI.updateAppointment({
          ...state.appointment,
          topic,
          start,
          end,
          roomUrl: location,
          requestorFullName,
          requestorEmail: requestorEmail as Email,
          status,
          advisorId: advisorId as AdvisorId,
          advisorFullName,
          agenda,
        });
        const advisor = advisorId
          ? await advisorsAPI.getById(
              user.id,
              user.timeZone,
              advisorId as AdvisorId,
            )
          : null;

        setState({ type: 'success', appointment, advisor });
        enqueueSnackbar(`The appointment was successfully updated and saved.`, {
          variant: 'success',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          'Something went wrong when updating the appointment. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [state, user, enqueueSnackbar],
  );

  const handleCancelAppointment = useCallback(
    async (cb: () => any) => {
      try {
        setProcessingAction(ActionTypes.CANCEL);
        const appointment = await appointmentsAPI.cancel(id);
        setState((prev) => {
          if (prev.type !== 'success') return prev;

          return {
            ...prev,
            appointment,
          };
        });
        enqueueSnackbar('The meeting was successfully cancelled.', {
          variant: 'success',
        });
        cb();
        history.push(Pages.OH_APPOINTMENTS);
      } catch (error: any) {
        const messageError = error.response?.data?.message;

        enqueueSnackbar(
          'An error occurred at the cancel of the appointment. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        cb();
      } finally {
        setProcessingAction(null);
      }
    },
    [enqueueSnackbar, history, id],
  );

  const handleAddIntent = useCallback(async () => {
    // If the appointment has a paymentUrl, show the modal with the link without creating a new payment intent
    if (state.type === 'success' && state.appointment.paymentUrl) {
      setShowModal(ActionModals.ADD_INTENT);
      return;
    }

    try {
      setProcessingAction(ActionTypes.ADD_PAYMENT);
      const paymentLink = await appointmentsAPI.createPaymentIntent(id);
      setState((prev) => {
        if (prev.type !== 'success') return prev;

        return {
          ...prev,
          appointment: {
            ...prev.appointment,
            paymentUrl: paymentLink,
            paymentStatus: AppointmentPaymentStatus.INITIAL,
          },
        };
      });
      setShowModal(ActionModals.ADD_INTENT);
    } catch (error: any) {
      const messageError = error.response?.data?.message;

      enqueueSnackbar(
        'An error occurred while creating a payment intent. Please try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
    } finally {
      setProcessingAction(null);
    }
  }, [enqueueSnackbar, id, state]);

  const handleRefund = useCallback(
    async (onSuccess: () => void, onError: () => void) => {
      try {
        setProcessingAction(ActionTypes.REFUND);
        const res = await appointmentsAPI.refundPayment(id);

        if (res) {
          onSuccess();
          setState((prev) => {
            if (prev.type !== 'success') return prev;

            return {
              ...prev,
              appointment: {
                ...prev.appointment,
                paymentStatus: AppointmentPaymentStatus.REFUNDED,
              },
            };
          });
        } else {
          throw new Error('Error while refunding payment');
        }
      } catch (error) {
        console.error(error);
        onError();
      } finally {
        setProcessingAction(null);
      }
    },
    [id],
  );

  const disabledActions = !!processingAction || state.type !== 'success';

  const { showAddPayment, showRefundPayment } = useMemo(() => {
    const isSuccess = state.type === 'success';
    const showAddPayment =
      isSuccess &&
      !!state?.advisor?.rate &&
      hasAccessToAction('appointment.details.payment') &&
      [
        AppointmentPaymentStatus.NOT_STARTED,
        AppointmentPaymentStatus.INITIAL,
        AppointmentPaymentStatus.FAILED,
      ].some((status) => status === state.appointment.paymentStatus);
    const showRefundPayment =
      isSuccess &&
      !!state?.advisor?.rate &&
      hasAccessToAction('appointment.details.refund') &&
      [AppointmentPaymentStatus.SUCCESS].some(
        (status) => status === state.appointment.paymentStatus,
      );

    return { showAddPayment, showRefundPayment };
  }, [hasAccessToAction, state]);

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        setState({ type: 'loading' });
        const appointment = await appointmentsAPI.getById(id);
        const advisor = appointment.advisorId
          ? await advisorsAPI.getById(
              user.id,
              user.timeZone,
              appointment.advisorId,
            )
          : null;
        setState({ type: 'success', appointment, advisor });
      } catch (error) {
        console.error(error);
        const message =
          (error as AxiosError)?.response?.status === 404
            ? 'Appointment not found'
            : 'Oops, Something went wrong';
        setState({ type: 'error', message });
      }
    };

    fetchAppointment();
  }, [id, user]);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <AddIntentModal
        link={
          (state.type === 'success' && state?.appointment?.paymentUrl) || ''
        }
        isOpen={showModal === ActionModals.ADD_INTENT}
        onClose={() => setShowModal(null)}
      />
      <RefundPaymentModal
        isOpen={showModal === ActionModals.REFUND_PAYMENT}
        onClose={() => setShowModal(null)}
        onSubmit={handleRefund}
        isLoading={processingAction === ActionTypes.REFUND}
      />
      <WithTabs
        title={'Appointment'}
        backButtonLink={Pages.OH_APPOINTMENTS}
        backButtonTitle={'Back to Appointments'}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_APPOINTMENTS_EDIT, { id })}
            tabs={tabs}
          />
        }
        actions={
          state.type === 'success' ? (
            <>
              {hasAccessToAction('appointment.details.inviteAll') && (
                <ConfirmButton
                  title='Appointment'
                  loading={disabledActions}
                  body='You are about to send a significant number of invites... Are you ready to notify everyone about this appointment?'
                  successProps={{
                    btnLabel: 'Yes',
                    onSuccess: handleSendInvites,
                  }}
                  cancelProps={{
                    btnLabel: 'No',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <Button
                      onClick={onClick}
                      variant='contained'
                      data-testid='button-invite-all'
                      disabled={disabledActions}>
                      {processingAction === ActionTypes.INVITE ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Invite All'
                      )}
                    </Button>
                  )}
                />
              )}
              {showAddPayment && (
                <Button
                  className={classes.actionButton}
                  onClick={handleAddIntent}
                  variant='contained'
                  startIcon={
                    processingAction === ActionTypes.ADD_PAYMENT ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      <AddIcon />
                    )
                  }
                  data-testid='button-add-payment'
                  disabled={disabledActions}>
                  Payment
                </Button>
              )}
              {showRefundPayment && (
                <Button
                  className={classes.actionButton}
                  onClick={() => setShowModal(ActionModals.REFUND_PAYMENT)}
                  variant='contained'
                  data-testid='button-refund-payment'
                  disabled={disabledActions}>
                  Refund
                </Button>
              )}
              {hasAccessToAction('appointment.details.cancel') && (
                <ConfirmButton
                  title='Cancel appointment'
                  loading={disabledActions}
                  body='Do you really want to cancel appointment?'
                  successProps={{
                    btnLabel: 'Yes',
                    onSuccess: handleCancelAppointment,
                  }}
                  cancelProps={{
                    btnLabel: 'No',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <Button
                      className={classes.actionButton}
                      onClick={onClick}
                      data-testid='button-cancel-appointment'
                      variant='outlined'
                      disabled={disabledActions}>
                      {processingAction === ActionTypes.CANCEL ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Cancel'
                      )}
                    </Button>
                  )}></ConfirmButton>
              )}
            </>
          ) : null
        }>
        {state.type === 'error' ? (
          <div className={classes.loaderContainer}>
            <Text variant='normal' color={COLORS.COLOR_RED_BASE}>
              {state.message}
            </Text>
          </div>
        ) : state.type === 'loading' ? (
          <div className={classes.loaderContainer}>
            <PageLoader />
          </div>
        ) : (
          <AppointmentForm
            initialData={state.appointment}
            handleSubmit={handleSubmit}
            showLocation
          />
        )}
      </WithTabs>
    </BaseLayout>
  );
};
