import { Actions } from './types/Actions';
import { fromAppointment } from './types/Item';
import * as State from './types/State';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: a.payload })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromAppointment),
            prev: s.payload.page > 1,
            next: a.payload.next,
          })
        : s;
    case 'Remove':
      return s.type === 'Ready' &&
        s.payload.items.find((i) => i.id === a.payload)
        ? State.removeConfirmation({ ...s.payload, id: a.payload })
        : s;
    case 'RemoveConfirmation':
      return s.type === 'RemoveConfirmation'
        ? a.payload
          ? State.removing(s.payload)
          : State.ready(s.payload)
        : s;
    case 'RemoveError':
      return s.type === 'Removing' ? State.ready(s.payload) : s;
    case 'RemoveSuccess':
      return s.type === 'Removing'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromAppointment),
            next: a.payload.next,
          })
        : s;
    case 'BulkRemove': {
      return s.type === 'Ready' &&
        s.payload.items.filter((i) => i.selected).length > 0
        ? State.bulkRemoveConfirm({ ...s.payload })
        : s;
    }
    case 'BulkConfirmation':
      return s.type === 'BulkRemoveConfirm'
        ? a.payload
          ? State.bulkRemove(s.payload)
          : State.ready(s.payload)
        : s;
    case 'ToggleAll':
      return s.type === 'Ready'
        ? s.payload.items.some((i) => !i.selected)
          ? State.ready({
              ...s.payload,
              items: s.payload.items.map((i) => ({ ...i, selected: true })),
            })
          : State.ready({
              ...s.payload,
              items: s.payload.items.map((i) => ({ ...i, selected: false })),
            })
        : s;
    case 'Toggle':
      return s.type === 'Ready'
        ? State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload ? { ...i, selected: !i.selected } : i,
            ),
          })
        : s;
    case 'BulkError':
      return s.type === 'BulkRemove' ? State.ready(s.payload) : s;
    case 'BulkSuccess':
      return s.type === 'BulkRemove'
        ? State.ready({
            ...s.payload,
            items: a.payload.items.map(fromAppointment),
            next: a.payload.next,
          })
        : s;
    case 'NextPage':
      return s.type === 'Ready' && s.payload.next
        ? State.loading({ ...s.payload, page: s.payload.page + 1 })
        : s;
    case 'PrevPage':
      return s.type === 'Ready' && s.payload.page > 1
        ? State.loading({ ...s.payload, page: s.payload.page - 1 })
        : s;
    case 'SetDate':
      return s.type === 'Ready' && a.payload.startDate && a.payload.endDate
        ? State.loading({
            ...s.payload,
            dateRange: [a.payload.startDate, a.payload.endDate],
          })
        : s;
    case 'SetStatus':
      return s.type === 'Ready'
        ? State.loading({ ...s.payload, status: a.payload })
        : s;
    case 'ClearFilters':
      return s.type === 'Ready' &&
        (s.payload.status !== undefined || s.payload.dateRange !== undefined)
        ? State.loading({
            ...s.payload,
            status: 'nonaccepted',
            dateRange: undefined,
          })
        : s;
  }
}
