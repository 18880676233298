import { AxiosError } from 'axios';
import { mPipe, pass } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { BehaviorSubject, EMPTY, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { is } from '../../../api/Advisors/types/Status';
import { Tenant } from '../../../api/auth';
import { useQuery } from '../../../hooks/useQuery';
import { isRight } from '../../../utils/Either';
import { fromString } from '../../../utils/String/Sentence';
import { unreachableError } from '../../../utils/unreachableError';
import * as Api from './Api';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as Filter from './types/Filter';
import * as State from './types/State';

export function useAdvisorsList(
  user: Tenant,
): [State.State, Dispatch<Actions.PublicActions>] {
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const filter = useMemo((): Filter.Filter => {
    const search = mPipe(
      () => query.get('search'),
      fromString(10000),
      pass(isRight),
    )();

    if (search) {
      return Filter.search(search.value);
    }

    const status = mPipe(() => query.get('status'), pass(is))();

    if (status) {
      return Filter.status(status);
    }

    return Filter.none();
  }, [query]);
  const [state, dispatch] = useReducer(
    reducer,
    State.loading({
      tenantId: user.id,
      timeZone: user.timeZone,
      page: 1,
      filter,
    }),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subject = useMemo(() => new BehaviorSubject<State.State>(state), []);

  useEffect(() => {
    subject.next(state);
  }, [state, subject]);

  useEffect(() => {
    const s = subject
      .pipe(
        distinctUntilKeyChanged('type'),
        mergeMap((s) => {
          switch (s.type) {
            case 'Loading': {
              return of(s).pipe(
                map((v) => v.payload),
                switchMap(({ tenantId, timeZone, filter, page }) =>
                  Api.get(tenantId, timeZone, page, filter),
                ),
                map(Actions.loadSuccess),
                catchError((e: AxiosError) =>
                  of(
                    Actions.loadError(
                      e.response?.data.message ?? 'Unable to load advisors',
                    ),
                  ),
                ),
              );
            }
            case 'Updating': {
              return of(
                s.payload.items
                  .filter((i) => i.selected && i.status !== s.payload.status)
                  .map((i) => i.id),
              ).pipe(
                switchMap((ids) =>
                  from(
                    Api.changeStatus(
                      s.payload.tenantId,
                      s.payload.timeZone,
                      ids,
                      s.payload.status,
                      s.payload.page,
                      s.payload.filter,
                    ),
                  ),
                ),
                map(Actions.updateSuccess),
                catchError((e: AxiosError) =>
                  of(
                    Actions.updateError(
                      e.response?.data.message ?? 'Unable to update advisors',
                    ),
                  ),
                ),
              );
            }
            case 'Removing':
              return of(s.payload.id).pipe(
                mergeMap((id) =>
                  Api.remove(
                    s.payload.tenantId,
                    s.payload.timeZone,
                    id,
                    s.payload.page,
                    s.payload.filter,
                  ),
                ),
                map(Actions.removeSuccess),
                catchError((e: AxiosError) =>
                  of(
                    Actions.removeError(
                      e.response?.data.message ?? 'Unable to archive advisor',
                    ),
                  ),
                ),
              );
            case 'LoadError':
            case 'Ready':
            case 'RemoveConfirmation':
            case 'RemoveErr':
            case 'UpdateConfirmation':
            case 'UpdateErr':
              return EMPTY;
          }
        }),
      )
      .subscribe(dispatch);

    const router$ = subject
      .pipe(
        map((s) => s.payload.filter),
        map((f): { search: string | undefined; status: string | undefined } => {
          return {
            status: f.type === 'status' ? f.value : undefined,
            search: f.type === 'search' ? f.value : undefined,
          };
        }),
      )
      .subscribe(query.set);

    return () => {
      router$.unsubscribe();
      s.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (state.type) {
      case 'LoadError':
      case 'RemoveErr':
      case 'UpdateErr': {
        enqueueSnackbar(state.payload.message, { variant: 'error' });
        break;
      }
      case 'Ready':
      case 'Loading':
      case 'Removing':
      case 'RemoveConfirmation':
      case 'UpdateConfirmation':
      case 'Updating': {
        break;
      }
      default:
        unreachableError(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type, enqueueSnackbar]);

  return [state, dispatch];
}
