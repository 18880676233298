import { useContext } from 'react';
import { KnownModule } from '../api/user/Module';
import { Role } from '../api/user/Role';
import { UserContext } from '../contexts/user-context';
import { useAuth } from '../hooks';
import { getRoutePath, Pages } from './constants';

export function useRedirectRoute(isGuest: boolean, isProtected: boolean) {
  const auth = useAuth();
  const { hasRole, hasAccessToModal, hasAccessToAction } =
    useContext(UserContext);

  const hasOfficeHoursAccess =
    hasAccessToModal(KnownModule.OfficeHours) && hasRole(Role.Manager);

  return isGuest && auth.isAuthorized
    ? hasRole(Role.Mentor)
      ? Pages.MENTOR_DASHBOARD
      : hasRole(Role.Founder)
      ? Pages.FOUNDER_DASHBOARD
      : hasRole(Role.CommunityMember)
      ? getRoutePath(Pages.PUBLIC_ADVISORS, {
          tenantId: auth.tokenData.tenantId,
        })
      : hasAccessToModal(KnownModule.Core) &&
        hasAccessToAction('dashboard.view')
      ? Pages.DASHBOARD
      : hasOfficeHoursAccess
      ? Pages.OH_DASHBOARD
      : hasAccessToAction('venture.view')
      ? Pages.VENTURES
      : null
    : isProtected && !auth.isAuthorized
    ? Pages.LOGIN
    : null;
}
