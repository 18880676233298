import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import authAPI from '../../api/auth';
import mentorsAPI, { MentorId } from '../../api/mentors';
import { SnackMessage } from '../../components/common';
import MentorDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/mentor-details';
import { MentorContext } from '../../contexts/mentor-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { massAsyncRequest } from '../../utils/api';

function MentorDetailsPage() {
  const location = useLocation();
  const { mentorId } = useParams<{ mentorId: MentorId }>();
  const {
    mentor,
    mentorSpecializations,
    updateMentor,
    updateSpecializations,
    setTenantUser,
  } = useContext(MentorContext);
  const { identityid } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rb } = useResourceBundles();
  const [isUpdating, setUpdating] = useState(false);
  const isExternalPage = useMemo(
    () => location.pathname.includes('external') || identityid === mentorId,
    [mentorId, location, identityid],
  );

  const handleSubmitForm = useCallback(
    async (parsedValues: ParsedFormValues) => {
      try {
        const { logo, ...values } = parsedValues.values;
        setUpdating(true);
        let updatedLogo = logo;

        if (parsedValues.logoFile) {
          const updateLogoResult = await mentorsAPI.updateAvatar(
            mentorId,
            parsedValues.logoFile,
          );
          updatedLogo = updateLogoResult.logo;
        }
        const updatedMentor = await mentorsAPI.update(mentorId, {
          ...mentor,
          ...values,
          logo: updatedLogo,
          mentorAdditionalInfo: mentor?.mentorAdditionalInfo,
        });

        const loadedSpecializationsIds = mentorSpecializations || [];
        const addedSpecializationsIds = parsedValues.specializations.filter(
          (specializationId) =>
            !loadedSpecializationsIds.includes(specializationId),
        );
        const removedSpecializationsIds = loadedSpecializationsIds.filter(
          (specializationId) =>
            !parsedValues.specializations.includes(specializationId),
        );

        const addSpecializationsRequests = addedSpecializationsIds.map(
          (specializationId) => () =>
            mentorsAPI.setMentorSpecialization(mentorId, specializationId),
        );
        const removeSpecializationsRequests = removedSpecializationsIds.map(
          (specializationId) => () =>
            mentorsAPI.deleteMentorSpecialization(mentorId, specializationId),
        );
        await Promise.all([
          massAsyncRequest(addSpecializationsRequests),
          massAsyncRequest(removeSpecializationsRequests),
        ]);
        setUpdating(false);
        const loadedTenantUser = await authAPI.getUserByEmail(
          updatedMentor.email,
        );
        setTenantUser(loadedTenantUser);
        updateMentor(updatedMentor);
        updateSpecializations(parsedValues.specializations);
        enqueueSnackbar(
          `The ${rb('mentor')} was successfully updated and saved.`,
          {
            variant: 'success',
          },
        );
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          `An error occurred while updating the ${rb(
            'mentor',
          )}. Please, try again.`,
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setUpdating(false);
      }
    },
    [
      mentorId,
      mentor,
      mentorSpecializations,
      setTenantUser,
      updateMentor,
      updateSpecializations,
      enqueueSnackbar,
      rb,
    ],
  );

  return (
    <div>
      <MentorDetailsForm
        mentorDetails={mentor}
        mentorSpecializations={mentorSpecializations}
        loading={isUpdating}
        onSubmit={handleSubmitForm}
        isExternal={isExternalPage}
      />
    </div>
  );
}

export default MentorDetailsPage;
