import { useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { getById } from '../../../api/Advisors';
import { AdvisorId } from '../../../api/Advisors/types/Advisor';
import { Tenant } from '../../../api/auth';
import { getSettings } from '../../../api/tenants';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as State from './types/State';

export function useAdvisorView(user: Tenant): [State.State] {
  const params = useParams<{ id: AdvisorId }>();
  const [state, dispatch] = useReducer(reducer, State.init(user.id, params.id));
  // eslint-disable-next-line
  const state$ = useMemo(() => new BehaviorSubject<State.State>(state), []);

  useEffect(() => {
    state$.next(state);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const get$ = state$
      .pipe(
        filter((s): s is State.Loading => s.type === 'Loading'),
        switchMap(({ payload: { tenantId, id } }) => {
          return from(getSettings(tenantId)).pipe(
            switchMap(({ timeZone }) =>
              from(getById(tenantId, timeZone, id)).pipe(
                map(Actions.loadSuccess),
                catchError(() =>
                  of(Actions.loadError('Unable to load advisor')),
                ),
              ),
            ),
          );
        }),
      )
      .subscribe(dispatch);

    return () => {
      get$.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return [state];
}
