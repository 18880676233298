import { Actions } from './types/Actions';
import { fromAdvisor } from './types/Item';
import * as State from './types/State';
import { codeMessage } from './utils';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'LoadError':
      return s.type === 'Loading'
        ? State.loadError({ ...s.payload, message: codeMessage(a.payload) })
        : s;
    case 'LoadSuccess':
      return s.type === 'Loading'
        ? State.ready({
            ...s.payload,
            specializations: a.payload.specializations,
            strictOfficeHoursScheduling: a.payload.strictOfficeHoursScheduling,
            items: a.payload.advisors.map((i) =>
              fromAdvisor(i, a.payload.specializations),
            ),
            prev: s.payload.page > 1,
            next: a.payload.nextPage,
            timeZone: a.payload.timeZone,
          })
        : s;
    case 'Next':
      return (s.type === 'Ready' || s.type === 'FetchError') && s.payload.next
        ? State.fetching({
            ...s.payload,
            page: s.payload.page + 1,
          })
        : s;
    case 'Prev':
      return (s.type === 'Ready' || s.type === 'FetchError') &&
        s.payload.page > 1
        ? State.fetching({
            ...s.payload,
            page: s.payload.page - 1,
          })
        : s;
    case 'SetFilter':
      return s.type === 'Ready' || s.type === 'FetchError'
        ? State.fetching({ ...s.payload, filter: a.payload })
        : s;
    case 'FetchSuccess':
      return s.type === 'Fetching'
        ? State.ready({
            ...s.payload,
            items: a.payload.advisors.map((i) =>
              fromAdvisor(i, s.payload.specializations),
            ),
            prev: s.payload.page > 1,
            next: a.payload.nextPage,
          })
        : s;
    case 'FetchError':
      return s.type === 'Fetching'
        ? State.fetchError({ ...s.payload, message: a.payload })
        : s;
  }
}
