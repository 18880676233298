import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DateCalendar as Calendar } from '@mui/x-date-pickers/DateCalendar';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { ReactElement, useCallback } from 'react';
import { TestId } from '../../Testing/TestId';

export interface Props {
  open: boolean;
  onClose: () => void;
  shouldDisableDate: (date: Date) => boolean;
  onChange: (d: Date) => void;
  value: Date;
  min: Date;
  max: Date;
}

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 8,

    '& .MuiSvgIcon-root': {
      fontSize: '14px',
    },
  },
});

export function Day({
  open,
  onClose,
  shouldDisableDate,
  onChange,
  min,
  max,
  value,
}: Props): ReactElement {
  const classes = useStyles();
  const handleDisableDate = useCallback(
    (d: Date | null) => (d ? shouldDisableDate(d) : false),
    [shouldDisableDate],
  );
  const handleChange = useCallback(
    (d: Date | null, selectionState?: PickerSelectionState | undefined) =>
      d && selectionState === 'finish' && onChange(d),
    [onChange],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown>
      <IconButton
        className={classes.closeButton}
        aria-label='close'
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent data-testid='calendar-element'>
        <TestId testId='calendar-element'>
          <Calendar
            value={value}
            minDate={min}
            maxDate={max}
            onChange={handleChange}
            shouldDisableDate={handleDisableDate}
          />
        </TestId>
      </DialogContent>
    </Dialog>
  );
}
