import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { COLORS } from '../../theme/variables';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';

interface LoadingDrawerProps {
  body?: string | React.ReactNode;
  onClosed?: () => any;
  status?: string;
}

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    right: 30,
    bottom: 30,
    width: 400,
    padding: '26px 44px 26px 26px',
    background: 'white',
    boxShadow:
      '0px 4px 8px rgba(9, 29, 61, 0.08), 0px 20px 40px rgba(9, 29, 61, 0.16)',
    borderRadius: 10,
    zIndex: 999,
    display: 'flex'
  },
  opened: {
    transform: 'translateX(0)'
  },
  closeBtn: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  body: {
    flexGrow: 1,
    overflow: 'auto'
  },
  icon: {
    marginRight: 18
  },
  successIcon: {
    fontSize: 24,
    color: COLORS.COLOR_GREEN_BASE
  },
  errorIcon: {
    fontSize: 24,
    color: COLORS.COLOR_RED_BASE
  }
});

function LoadingDrawer({ body, onClosed, status }: LoadingDrawerProps) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    // for animate
    setTimeout(() => {
      setIsOpen(true);
    }, 0);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onClosed?.();
    }, 200);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  const getIcon = useMemo(() => {
    switch (status) {
      case 'success':
        return (
          <CheckCircleIcon className={cn(classes.icon, classes.successIcon)} />
        );
      case 'error':
        return <InfoIcon className={cn(classes.icon, classes.errorIcon)} />;
      case 'loaded':
        return (
          <CircularProgress
            size={24}
            color='primary'
            className={classes.icon}
          />
        );
      default:
        return (
          <CircularProgress
            size={24}
            color='primary'
            className={classes.icon}
          />
        );
    }
  }, [status, classes]);

  return (
    <div
      className={cn(classes.container, {
        [classes.opened]: isOpen
      })}>
      {getIcon}
      <div className={classes.body}>{body}</div>
      <IconButton className={classes.closeBtn} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}

export default LoadingDrawer;
