import React from 'react';
import { Rating as RatingComponent } from '@material-ui/lab';
import { Typography, makeStyles } from '@material-ui/core';
import cn from 'classnames';

interface Props {
  stars: number;
  value: number | undefined;
  onChange: (v: number | undefined) => void;
  label?: string;
  readOnly?: boolean;
  invalid?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-15px',

    '& > span': {
      width: 24,
      textAlign: 'center',
    },
  },
  invalid: {
    '& .MuiRating-icon': {
      color: '#ffb3b3',
    },
  },
});

export function RatingField({
  value = 0,
  onChange,
  label,
  readOnly,
  invalid = false,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {label && <Typography variant='h4'>{label}</Typography>}
      <RatingComponent
        className={cn({
          [classes.invalid]: invalid,
        })}
        value={value}
        onChange={(_, v) => onChange(v ?? undefined)}
        readOnly={readOnly}
      />
    </div>
  );
}
