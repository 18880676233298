import { format, getDate, getDay, setDay } from 'date-fns';
import { isT } from 'fp-utilities';
import {
  CustomWeekDays,
  Daily,
  Monthly,
  Period,
} from '../../../api/types/Period';
import {
  fromDateFnsWeekDay,
  toDateFnsWeekDay,
} from '../../../api/types/WeekDay';
import { getError } from '../../../utils/FormValue';
import { order } from '../../../utils/Num';
import { getDayOfWeekString } from '../../../utils/functions';
import { unreachableError } from '../../../utils/unreachableError';
import * as ItemType from './types/Item';
import { ItemError } from './types/Item';
import { Loaded, State } from './types/State';

export function isEditing(s: State): boolean {
  switch (s.type) {
    case 'Edited':
    case 'CustomPeriod':
      return true;
    case 'RemoveConfirmation':
    case 'SaveError':
    case 'LoadError':
    case 'Ready':
    case 'Saving':
    case 'Loading':
      return false;
  }
}

export function periodTitle(period: Period['periodType'], date: Date): string {
  switch (period) {
    case '':
      return 'Does not repeat';
    case 'DAILY':
      return 'Daily';
    case 'MONTHLY':
      return 'Monthly';
    case 'ALL_WORK_DAYS':
      return 'Every weekday (Mon-Fri)';
    case 'CUSTOM_WEEK_DAYS':
      return `Weekly on ${format(date, 'eeee')}`;
  }
}

export function customPeriodItem(
  date: Date,
  v: Period,
): { value: '-1'; label: string } | undefined {
  switch (v.periodType) {
    case '':
    case 'ALL_WORK_DAYS':
      return undefined;
    case 'DAILY': {
      if (v.skipDays === -1 && v.stopAfterIterations === undefined) {
        return undefined;
      }

      return {
        value: '-1',
        label: `${everyTitle(v)} ${endTitle(v)}`,
      };
    }
    case 'MONTHLY': {
      if (
        v.dayOfMonth === getDate(date) &&
        v.stopAfterIterations === undefined
      ) {
        return undefined;
      }

      return {
        value: '-1',
        label: `${everyTitle(v)} ${endTitle(v)}`,
      };
    }
    case 'CUSTOM_WEEK_DAYS': {
      if (
        v.ordinalNumber === undefined &&
        v.skipWeeks === -1 &&
        v.dayOfWeek === fromDateFnsWeekDay(getDay(date))
      ) {
        return undefined;
      }

      return {
        value: '-1',
        label:
          v.stopAfterIterations === undefined
            ? `Weekly on ${format(
                setDay(new Date(), toDateFnsWeekDay(v.dayOfWeek)),
                'eeee',
              )}`
            : `${everyTitle(v)} ${endTitle(v)}`,
      };
    }
    default:
      unreachableError(v);
  }
}

function everyTitle(v: Daily | Monthly | CustomWeekDays): string {
  switch (v.periodType) {
    case 'DAILY': {
      return v.skipDays === 1
        ? 'Every day.'
        : `Every ${v.skipDays}${order(v.skipDays)} day.`;
    }
    case 'MONTHLY': {
      return `Every ${v.dayOfMonth}${order(v.dayOfMonth)} of Month.`;
    }
    case 'CUSTOM_WEEK_DAYS': {
      if (v.ordinalNumber !== undefined) {
        return `Every ${v.ordinalNumber}${order(
          v.ordinalNumber,
        )} ${getDayOfWeekString(v.dayOfWeek)} of Month.`;
      } else {
        return `Every ${
          v.skipWeeks > 1 ? `${v.skipWeeks}${order(v.skipWeeks)}` : ''
        } ${getDayOfWeekString(v.dayOfWeek)}.`;
      }
    }
  }
}

function endTitle(v: Daily | Monthly | CustomWeekDays): string {
  switch (v.periodType) {
    case 'DAILY':
    case 'CUSTOM_WEEK_DAYS':
    case 'MONTHLY': {
      return v.stopAfterDate.value
        ? `Stop after ${format(v.stopAfterDate.value, 'M-d-Y')}`
        : `Stop after ${v.stopAfterIterations ?? 100} iterations`;
    }
  }
}

export const periods = (
  date: Date,
): {
  [k in Period['periodType'] | 'CustomPeriod']: { value: k; label: string };
} => ({
  '': {
    value: '',
    label: periodTitle('', date),
  },
  DAILY: {
    label: periodTitle('DAILY', date),
    value: 'DAILY',
  },

  CUSTOM_WEEK_DAYS: {
    value: 'CUSTOM_WEEK_DAYS',
    label: periodTitle('CUSTOM_WEEK_DAYS', date),
  },
  MONTHLY: {
    label: periodTitle('MONTHLY', date),
    value: 'MONTHLY',
  },
  ALL_WORK_DAYS: {
    value: 'ALL_WORK_DAYS',
    label: periodTitle('ALL_WORK_DAYS', date),
  },
  CustomPeriod: {
    label: 'Custom',
    value: 'CustomPeriod',
  },
});

export function canAddItem(s: Loaded): boolean {
  return s.payload.items.length < 20;
}

export function itemErrorText(v: ItemError): string | undefined {
  switch (v) {
    case ItemError.Required:
      return undefined;
    case ItemError.Invalid:
      return 'Provided date is invalid!';
    case ItemError.Low:
      return 'End date should be at lest 30 minutes later then start date!';
    case ItemError.Past:
      return 'Date should be at least 30 minutes later then current time!';
  }
}

export function itemErrors(item: ItemType.Item): ItemError[] {
  return [item.start, item.end, item.period].map(getError).filter(isT);
}
