import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactElement, ReactNode } from 'react';
import { TenantId } from '../../../api/tenants/Tenant';
import { useAdvisorSettings } from '../../../contexts/advisor-settings-context';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../../theme/variables';
import { TestId } from '../../Testing/TestId';
import { Link } from '../../common';
import { TenantLogo } from '../../common/TenantLogo';

export interface Props {
  to: string;
  id: TenantId;
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  space: {
    width: '100%',
    height: '61px',
  },
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'grid',
    gap: '10px',
    alignItems: 'center',
    padding: '4px 28px',
    boxShadow:
      '0px 0px 2px rgb(34 91 187 / 20%), 0px 0px 8px rgb(51 126 255 / 20%)',
    gridTemplateColumns: '1fr auto',
    justifyContent: 'start',
    background: '#fff',
    zIndex: 10,

    [theme.breakpoints.down('xs')]: {
      padding: '4px',
    },
  },
  content: {
    maxWidth: '1360px',
  },
  image: {
    height: '51px',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: MONTSERRAT_FONT_FAMILY,
    color: COLORS.COLOR_TEXT_BASE,
    textDecoration: 'none',
  },
}));

export function LogoOnly({ to, id, children }: Props): ReactElement {
  const classes = useStyles();
  const { settings } = useAdvisorSettings();

  return (
    <>
      <div className={classes.space}></div>
      <div className={classes.root}>
        <TestId testId={'header-logo'}>
          <Link to={to} className={classes.logo}>
            <TenantLogo id={id} className={classes.image} />
            {settings?.programName}
          </Link>
        </TestId>
        {children}
      </div>
    </>
  );
}
