import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';

interface Props {
  parentPath: string | undefined;
}

export const RouterContext = createContext<Props>({
  parentPath: undefined,
});

export const RouterProvider = ({ children }: PropsWithChildren<{}>) => {
  const { pathname, search } = useLocation();
  const [parentPath, setParentPath] = useState<string | undefined>();
  const parentPathRef = useRef(parentPath);
  parentPathRef.current = parentPath;

  useEffect(() => {
    return () => {
      if (
        (parentPathRef.current &&
          pathname.startsWith(parentPathRef.current.split('?')[0]) &&
          pathname.length > parentPathRef.current.length) ||
        pathname.includes('/new')
      ) {
        return;
      }

      setParentPath(`${pathname}${search}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  return (
    <RouterContext.Provider value={{ parentPath }}>
      {children}
    </RouterContext.Provider>
  );
};
