import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { env } from '../env';

interface AxiosRequestParams extends AxiosRequestConfig {
  credentials?: boolean;
}

const instance = axios.create({
  baseURL: env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  function (res) {
    if (res.status !== 200) {
      throw res;
    }
    return res;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      !error?.request?.responseURL?.includes('tenants/users/passwords')
    ) {
      window.dispatchEvent(new Event('traction5.token_expired'));
    }
    return Promise.reject(error);
  },
);

const getCredentialsHeader = (): { Authorization: string } | {} => {
  const token = localStorage.getItem('token');

  return token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
};

export const axiosRequest = {
  get<T>({
    url,
    data,
    credentials,
    ...axiosConfig
  }: AxiosRequestParams): Promise<AxiosResponse<T>> {
    return instance.request<T>({
      url,
      method: 'GET',
      params: data,
      ...axiosConfig,
      headers: {
        ...axiosConfig.headers,
        ...(credentials ? getCredentialsHeader() : {}),
      },
    });
  },
  post<T>({
    url,
    data,
    credentials,
    ...axiosConfig
  }: AxiosRequestParams): Promise<AxiosResponse<T>> {
    return instance.request<T>({
      url,
      method: 'POST',
      data,
      ...axiosConfig,
      headers: {
        ...axiosConfig.headers,
        ...(credentials ? getCredentialsHeader() : {}),
      },
    });
  },
  put<T>({
    url,
    data,
    credentials,
    ...axiosConfig
  }: AxiosRequestParams): Promise<AxiosResponse<T>> {
    return instance.request<T>({
      url,
      method: 'PUT',
      data,
      ...axiosConfig,
      headers: {
        ...axiosConfig.headers,
        ...(credentials ? getCredentialsHeader() : {}),
      },
    });
  },
  delete<T>({
    url,
    data,
    credentials,
    ...axiosConfig
  }: AxiosRequestParams): Promise<AxiosResponse<T>> {
    return instance.request<T>({
      url,
      method: 'DELETE',
      data: data,
      ...axiosConfig,
      headers: {
        ...axiosConfig.headers,
        ...(credentials ? getCredentialsHeader() : {}),
      },
    });
  },
};

export default instance;
