import { IconButton, Paper, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useParentPath } from '../../../hooks/router';
import { TestId } from '../../Testing/TestId';
import { Header } from '../../layout/main-layout';

export interface Props {
  title: string;
  tabs: ReactElement;
  backButtonTitle: string;
  backButtonLink: string;
  actions?: ReactNode;
}

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  requestLink: {
    minWidth: 187,
    whiteSpace: 'nowrap',
  },
});

export function WithTabs({
  title,
  backButtonTitle,
  backButtonLink,
  children,
  tabs,
  actions,
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();
  const parent = useParentPath();

  return (
    <>
      <Header
        title={title}
        backLink={
          <TestId testId={'back-link'}>
            <Tooltip title={backButtonTitle}>
              <IconButton component={Link} to={parent ?? backButtonLink}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </TestId>
        }
        actionRenderer={() => actions}
      />
      <Paper className={classes.container} elevation={1}>
        {tabs}
        <div className={classes.content}>{children}</div>
      </Paper>
    </>
  );
}
