import { Paper, PaperProps } from '@material-ui/core';
import { classnames } from '@material-ui/data-grid';
import { Autocomplete as Component } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { TestId } from '../Testing/TestId';
import TextField, { TextFieldProps } from './text-field';

const useStyles = makeStyles({
  disabledSearch: {
    '& .MuiAutocomplete-inputRoot, & input': {
      cursor: 'pointer',
    },
  },
  activeSearch: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2575FF !important',
      borderWidth: '1px !important',
    },
  },
  paper: {
    padding: 0,
    borderRadius: 4,
    boxShadow:
      '0px 0px 2px rgba(9, 29, 61, 0.25), 0px 8px 16px rgba(34, 91, 187, 0.08), 0px 8px 24px rgba(51, 126, 255, 0.08)',
  },
});

export interface Option<T> {
  value: T;
  label: string;
}

export interface AutocompleteProps<T, M extends undefined | boolean> {
  value?: M extends undefined | false ? T : T[];
  options: Option<T>[];
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  onChange: (value: M extends undefined | false ? T : T[]) => void;
  readOnly?: boolean;
  disableSearch?: boolean;
  multiple: M;
  className?: string;
  testId?: string;
  size?: 'small' | 'medium';
  isActive?: boolean;
}

export function Autocomplete<T, M extends boolean>({
  className,
  multiple,
  value,
  options,
  onChange,
  readOnly,
  disabled,
  testId,
  textFieldProps,
  size,
  disableSearch,
  isActive = false,
}: AutocompleteProps<T, M>) {
  const classes = useStyles();

  return (
    <Component<T, M, true>
      className={classnames(className, {
        teaT: true,
        [classes.disabledSearch]: disableSearch,
        [classes.activeSearch]: isActive,
      })}
      size={size}
      multiple={multiple}
      // @ts-expect-error
      value={value}
      disableCloseOnSelect={multiple}
      options={options.map(({ value }) => value)}
      disableClearable
      getOptionLabel={(value) =>
        options.find((v) => v.value === value)?.label ?? ''
      }
      onChange={(e, v) => onChange(v)}
      filterSelectedOptions
      data-testid={testId}
      selectOnFocus={!disableSearch}
      forcePopupIcon={readOnly ? false : undefined}
      renderInput={(params) => {
        return (
          <TextField
            {...textFieldProps}
            {...params}
            inputProps={{
              ...(params?.inputProps || {}),
              readOnly: readOnly || disableSearch,
              // This is a hack in order to clear autocomplete value
              value:
                value === undefined
                  ? ''
                  : options.find((i) => i.value === value)?.label,
            }}
          />
        );
      }}
      PaperComponent={(paperProps: PaperProps) => {
        return !readOnly ? (
          <TestId testId={`${testId}-dropdown`}>
            <Paper {...paperProps} className={classes.paper} />
          </TestId>
        ) : (
          <></>
        );
      }}
      disabled={disabled}
    />
  );
}
