import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Dispatch,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Tabs } from '../../../components/Pages/Forms/Tabs';
import { WithTabs } from '../../../components/Pages/Forms/WithTabs';
import { TestId } from '../../../components/Testing/TestId';
import { PageLoader } from '../../../components/common';
import { ActionsDialog } from '../../../components/common/Confirmation/ActionsDialog';
import { BeforeUnloadWithSave } from '../../../components/common/Confirmation/BeforeUnloadWithSave';
import { FormItem2 } from '../../../components/common/Forms/types/FormItem';
import { Inline } from '../../../components/common/Inline';
import Button from '../../../components/common/button';
import Text from '../../../components/common/text';
import BaseLayout from '../../../components/layout/base-layout';
import { UserContext } from '../../../contexts/user-context';
import { getRoutePath, Pages } from '../../../router/constants';
import { ProtectedRouteProps } from '../../../router/type';
import { Form } from '../common/Form';
import {
  createField,
  formValueToInput,
  formValueToInput2,
  getTabs,
  statusOptions,
} from '../common/utils';
import * as Actions from './types/Actions';
import { isLoaded, Loaded } from './types/State';
import { useAdvisorEdit } from './useAdvisorEdit';

function LoadedState({ s, dispatch }: { s: Loaded; dispatch: Dispatch<any> }) {
  const {
    item,
    advisor: { dateOfEnrollment },
  } = s.payload;
  const {
    firstName,
    lastName,
    rate,
    email,
    state,
    linkedIn,
    long,
    status,
    logo,
    country,
    address,
    birthDate,
    phone,
    zip,
    city,
    short,
    specializations,
  } = item;

  const _firstName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(firstName),
      onBlur: () => dispatch(Actions.toggle('firstName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'firstName', value: v })),
    }),
    [firstName, dispatch],
  );
  const _lastName = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(lastName),
      onBlur: () => dispatch(Actions.toggle('lastName')),
      onChange: (v) =>
        dispatch(Actions.setValue({ key: 'lastName', value: v })),
    }),
    [lastName, dispatch],
  );
  const _email = useMemo(
    (): FormItem2<string | undefined> => ({
      ...formValueToInput2(email),
      onBlur: () => dispatch(Actions.toggle('email')),
      onChange: (v) => dispatch(Actions.setValue({ key: 'email', value: v })),
    }),
    [email, dispatch],
  );
  const _state = useMemo(
    () => createField(state, 'state', dispatch),
    [state, dispatch],
  );
  const _linkedIn = useMemo(
    () => createField(linkedIn, 'linkedIn', dispatch),
    [linkedIn, dispatch],
  );
  const _long = useMemo(
    () => createField(long, 'long', dispatch),
    [long, dispatch],
  );
  const _short = useMemo(
    () => createField(short, 'short', dispatch),
    [short, dispatch],
  );
  const _status = useMemo(
    () => createField(status, 'status', dispatch),
    [status, dispatch],
  );
  const _country = useMemo(
    () => createField(country, 'country', dispatch),
    [country, dispatch],
  );
  const _birthDate = useMemo(
    () => createField(birthDate, 'birthDate', dispatch),
    [birthDate, dispatch],
  );
  const _phone = useMemo(
    () => createField(phone, 'phone', dispatch),
    [phone, dispatch],
  );
  const _zip = useMemo(
    () => createField(zip, 'zip', dispatch),
    [zip, dispatch],
  );
  const _city = useMemo(
    () => createField(city, 'city', dispatch),
    [city, dispatch],
  );
  const _address = useMemo(
    () => createField(address, 'address', dispatch),
    [address, dispatch],
  );
  const _specializations = useMemo(
    () => createField(specializations, 'specializations', dispatch),
    [specializations, dispatch],
  );
  const _logo = useMemo(() => {
    return {
      ...formValueToInput(logo),
      onBlur: () => dispatch(Actions.toggle('logo')),
      onChange: (value: File | undefined) =>
        dispatch(
          value
            ? Actions.upload(value)
            : Actions.setValue({ key: 'logo', value: undefined }),
        ),
    };
  }, [logo, dispatch]);
  const _rate = useMemo(
    () => createField(rate, 'rate', dispatch),
    [rate, dispatch],
  );
  const Dialogs = (): ReactElement | null => {
    switch (s.type) {
      case 'UnloadError':
      case 'UnloadSaving':
      case 'UnloadConfirm':
      case 'UnloadSubmitted': {
        const buttonStatus = (): 'active' | 'disabled' | 'loading' => {
          switch (s.type) {
            case 'UnloadConfirm':
            case 'UnloadError':
              return 'active';
            case 'UnloadSaving':
              return 'loading';
            case 'UnloadSubmitted':
              return 'disabled';
          }
        };

        return (
          <BeforeUnloadWithSave
            open={true}
            onClose={() => dispatch(Actions.decline())}
            onSave={() => dispatch(Actions.save())}
            onLeave={() => dispatch(Actions.confirm())}
            saveButton={buttonStatus()}>
            {s.type === 'UnloadSubmitted' ? (
              <Alert severity={'error'}>
                Can’t save Advisor details. Please fix highlighted fields values
              </Alert>
            ) : null}
          </BeforeUnloadWithSave>
        );
      }
      case 'InvalidAvailabilities':
      case 'RemoveAndUpdate':
      case 'RemoveAndUpdateError': {
        const ActionButtons = (): ReactElement => {
          return (
            <>
              <TestId testId={'remove-and-save'}>
                <Button
                  variant={'contained'}
                  onClick={() => dispatch(Actions.removeAndUpdate())}>
                  {s.type === 'RemoveAndUpdate' ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Remove and Save'
                  )}
                </Button>
              </TestId>
              <Button
                variant={'outlined'}
                onClick={() => dispatch(Actions.decline())}>
                Cancel
              </Button>
            </>
          );
        };

        return (
          <ActionsDialog
            open={true}
            onClose={() => dispatch(Actions.decline())}
            title={'Invalid availabilities'}
            actions={<ActionButtons />}>
            <Text variant={'normal'}>
              We found that some of Advisor availabilities are outdated and thus
              can’t save your modifications. <br />
              Do you want to automatically remove all outdated time slots and
              proceed with saving your changes?
            </Text>
          </ActionsDialog>
        );
      }
      case 'UnloadInvalidAvailabilities':
      case 'UnloadRemoveAnUpdate':
      case 'UnloadRemoveAnUpdateError': {
        const ActionButtons = (): ReactElement => {
          return (
            <>
              <Inline gap={10} align={'center'}>
                <TestId testId={'remove-and-save'}>
                  <Button
                    variant={'outlined'}
                    onClick={() => dispatch(Actions.removeAndUpdate())}>
                    {s.type === 'UnloadRemoveAnUpdate' ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Remove and Save'
                    )}
                  </Button>
                </TestId>
                <Button
                  variant={'contained'}
                  onClick={() => dispatch(Actions.confirm())}
                  disabled={s.type === 'UnloadRemoveAnUpdate'}>
                  Yes, proceed
                </Button>
              </Inline>
              <Button
                variant={'outlined'}
                onClick={() => dispatch(Actions.decline())}>
                Cancel
              </Button>
            </>
          );
        };

        return (
          <ActionsDialog
            open={true}
            onClose={() => dispatch(Actions.decline())}
            title={'Invalid availabilities'}
            actions={<ActionButtons />}>
            <Text variant={'normal'}>
              We found that some of Advisor availabilities are outdated and thus
              can’t save your modifications. Do you want to automatically remove
              all outdated time slots and proceed with saving your changes?
            </Text>
          </ActionsDialog>
        );
      }
      case 'Unloading':
      case 'Uploading':
      case 'Edited':
      case 'Ready':
      case 'SaveError':
      case 'SaveSuccess':
      case 'Saving':
      case 'Submitted':
      case 'VerificationError':
        return null;
    }
  };
  const handleSubmit = useCallback(() => dispatch(Actions.save()), [dispatch]);

  return (
    <>
      <Dialogs />
      <Form
        address={_address}
        birthDate={_birthDate}
        city={_city}
        country={_country}
        email={_email}
        enrollmentDate={dateOfEnrollment}
        firstName={_firstName}
        lastName={_lastName}
        rate={_rate}
        linkedIn={_linkedIn}
        logo={_logo}
        long={_long}
        phone={_phone}
        short={_short}
        state={_state}
        status={_status}
        statuses={statusOptions}
        specializations={_specializations}
        zip={_zip}
        onSubmit={handleSubmit}
        disableSubmit={false}
      />
    </>
  );
}

export function EditAdvisor({ user }: ProtectedRouteProps) {
  const [s, dispatch] = useAdvisorEdit(user);
  const { hasAccessToAction } = useContext(UserContext);

  return (
    <BaseLayout user={user} fullHeight sidebar='officehours'>
      <WithTabs
        title={'Edit advisor'}
        backButtonLink={Pages.OH_ADVISORS}
        backButtonTitle={'Back to Advisors'}
        tabs={
          <Tabs
            active={getRoutePath(Pages.OH_ADVISORS_EDIT, { id: s.payload.id })}
            tabs={getTabs(s.payload.id, hasAccessToAction)}
          />
        }>
        {isLoaded(s) ? (
          <LoadedState s={s} dispatch={dispatch} />
        ) : (
          <PageLoader />
        )}
      </WithTabs>
    </BaseLayout>
  );
}
