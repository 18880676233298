import { Card, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import cn from 'classnames';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { AdvisorId } from '../../api/Advisors/types/Advisor';
import { COLORS, INTER_FONT_FAMILY } from '../../theme/variables';
import { TestId } from '../Testing/TestId';
import { Avatar } from '../common';
import { Pagination } from '../common/Pagination';
import { Tags } from '../common/Tags';
import { RateBox } from '../common/rate-box';
import Text from '../common/text';
import { BookButton } from './BookButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24,
    transition: 'opacity .5s ease',
  },
  loading: {
    opacity: 0.2,
  },
  item: {
    display: 'grid',
    gridTemplateAreas:
      '"image name view" \n "image description view" \n "image tags view"',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: 'auto auto 1fr',
    rowGap: 5,
    columnGap: 24,

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.down('xs')]: {
      gridTemplateAreas:
        '"image name view" \n "image description view" \n "tags tags view"',
      gridTemplateColumns: '50px 1fr',
      gridTemplateRows: 'auto auto 1fr',
      rowGap: 10,
    },

    [theme.breakpoints.down(600)]: {
      gridTemplateAreas:
        '"image name" \n "image description" \n "tags tags" \n "view view"',
    },
  },
  imageWrapper: {
    gridArea: 'image',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 18,
    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      fontSize: 14,
    },
  },
  name: {
    gridArea: 'name',
    cursor: 'pointer',
  },
  description: {
    gridArea: 'description',
    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  tags: {
    gridArea: 'tags',
    alignSelf: 'end',
    marginTop: 16,
  },
  tag: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    fontFamily: INTER_FONT_FAMILY,
  },
  view: {
    gridArea: 'view',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,

    [theme.breakpoints.down(600)]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

export interface Props {
  items: Omit<ItemProps, 'onBook'>[];
  page: number;
  onBook: (id: AdvisorId) => void;
  onPrev: () => void;
  onNext: () => void;
  disablePrev?: boolean;
  disableNext?: boolean;
  loading?: boolean;
}

export function AdvisorsList({
  items,
  page,
  onBook,
  onNext,
  onPrev,
  disablePrev,
  disableNext,
  loading,
}: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text variant={'normal'} color={COLORS.COLOR_GRAY_DARKENED}>
        Showing {items.length} advisors
      </Text>
      <TestId testId={'advisors-list'}>
        <div
          className={classNames(classes.items, { [classes.loading]: loading })}>
          {items.map((i) => (
            <Item
              id={i.id}
              key={i.id}
              avatar={i.avatar}
              firstName={i.firstName}
              lastName={i.lastName}
              description={i.description}
              tags={i.tags}
              view={i.view}
              onBook={() => onBook(i.id)}
              bookStatus={i.bookStatus}
              rate={i.rate}
            />
          ))}
        </div>
      </TestId>
      {!(disableNext && disablePrev) ? (
        <div className={classes.pagination}>
          <Pagination
            page={page}
            onNext={onNext}
            onPrev={onPrev}
            disablePrev={disablePrev}
            disableNext={disableNext}
          />
        </div>
      ) : null}
    </div>
  );
}

export interface ItemProps {
  id: AdvisorId;
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  description: string;
  tags: string[];
  view: string;
  onBook: () => void;
  bookStatus: 'active' | 'disabled';
  rate: number | null;
}

export function Item({
  id,
  firstName,
  lastName,
  description,
  tags,
  view,
  avatar,
  onBook,
  bookStatus,
  rate,
}: ItemProps) {
  const classes = useStyles();
  const { push } = useHistory();
  const handleView = useCallback(() => push(view), [view, push]);

  return (
    <TestId testId={'advisor-item'} data-id={id}>
      <Card className={classes.item}>
        <div className={classes.imageWrapper}>
          <Avatar
            name={`${firstName[0]}${lastName[0]}`}
            className={classes.image}
            variant={'rounded'}
            size={'120'}
            src={avatar}
            onClick={handleView}
          />
        </div>
        <TestId testId={'advisor-name'}>
          <Typography
            variant={'h5'}
            className={classes.name}
            onClick={handleView}>{`${firstName} ${lastName}`}</Typography>
        </TestId>
        <TestId testId={'advisor-short-desc'}>
          <div
            className={cn(classes.description, 'inner-html')}
            onClick={handleView}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </TestId>
        {!!tags.length && <Tags tags={tags} className={classes.tags} />}
        <div className={classes.view}>
          <RateBox rate={rate} />
          <BookButton
            onClick={onBook}
            loading={bookStatus === 'disabled'}
            disabled={bookStatus === 'disabled'}
          />
        </div>
      </Card>
    </TestId>
  );
}
