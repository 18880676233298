import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Range } from 'react-date-range';
import { Field, Form } from 'react-final-form';
import {
  Button,
  DateRangePickerInput,
  FormButtons,
  Modal,
} from '../../components/common';
import { FormCheckbox } from '../../components/forms/wrappers';

interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
  loading: boolean;
  onlyDateInput?: boolean;
}

const useTitleStyles = makeStyles({
  container: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  filterItem: {
    marginBottom: 10,

    '& + &': {
      marginLeft: 15,
    },
  },
  dateRangePicker: {
    position: 'fixed',
  },
  formButtons: {
    width: '100%',
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  buttonCustomize: {
    marginLeft: 15,
  },
});

export interface FormValues {
  dataRange: Range;
  includeLogo?: string;
  includeSummary?: string;
  includeAssessment?: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};

  return errors;
};

function ModalCustomizeReport({
  initialValues,
  onSubmit,
  loading,
  onlyDateInput,
}: Props) {
  const classes = useTitleStyles();

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  const handleChangeField = (
    onChange: any,
    name: string,
    value: string | Range | null,
  ) => {
    onChange(name, value);
  };

  return (
    <Modal
      title='Customize report'
      caption=''
      contentRenderer={({ handleClose }) => (
        <div className={classes.container}>
          <Form
            validate={validateForm}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            keepDirtyOnReinitialize={false}
            render={(formProps) => (
              <>
                <div className={classes.filterItem}>
                  <DateRangePickerInput
                    onChange={(value) =>
                      handleChangeField(
                        formProps.form.change,
                        'dataRange',
                        value,
                      )
                    }
                    value={formProps.values.dataRange}
                  />
                </div>
                {!onlyDateInput && (
                  <>
                    <Field<string>
                      testid='customize-report-include-logo'
                      name='includeLogo'
                      component={FormCheckbox}
                      label='Include logo'
                    />
                    <Field<string>
                      testid='customize-report-include-summary'
                      name='includeSummary'
                      component={FormCheckbox}
                      label='Include summary'
                    />
                    <Field<string>
                      testid='customize-report-include-assessment'
                      name='includeAssessment'
                      component={FormCheckbox}
                      label='Include assessment'
                    />
                  </>
                )}
                <FormButtons className={classes.formButtons}>
                  <Button
                    disabled={loading}
                    type='submit'
                    onClick={async () => {
                      try {
                        await formProps.handleSubmit();
                        handleClose();
                      } catch (e: any) {}
                    }}
                    data-testid='form-apply'>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Generate'
                    )}
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant='outlined'
                    data-testid='form-cancel'>
                    Cancel
                  </Button>
                </FormButtons>
              </>
            )}
          />
        </div>
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          onClick={onClick}
          className={classes.buttonCustomize}
          data-testid='customize-report-button'>
          Customize Report
        </Button>
      )}
      width={!onlyDateInput ? 600 : 310}
    />
  );
}

export default ModalCustomizeReport;
