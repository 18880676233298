import { Button as MaterialButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { stringify as stringifyQuery } from 'query-string';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FounderId } from '../api/founders';
import {
  getActiveOwnerGoals,
  getOwnerGoalsByStatus,
  getVentureGoals,
  getVentureGoalsByStatus,
} from '../api/goals';
import { Goal, GoalStatus } from '../api/types/Goal';
import venturesAPI from '../api/ventures';
import { Venture, VentureId } from '../api/ventures/types/Venture';
import { Listing } from '../components/Pages/Listing';
import { SelectGoalStatus } from '../components/common/goal-status-select';
import BaseLayout from '../components/layout/base-layout';
import GoalsTable from '../components/tables/goals-table';
import { UserContext } from '../contexts/user-context';
import { useQuery } from '../hooks/useQuery';
import { Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { decodeQuery, encodeQuery } from '../utils/functions';
import { CLASS_TRACKING } from '../utils/tracking_class';

interface GoalsFilters {
  venture: string;
  status: SelectGoalStatus;
}

const defaultFilters: GoalsFilters = {
  status: 'ALL',
  venture: 'ALL',
};

const getInitialFilters = (filter: string | null): GoalsFilters => {
  if (filter) {
    const decodedFilter = decodeQuery(filter);
    return { ...defaultFilters, ...decodedFilter };
  } else {
    return defaultFilters;
  }
};

function GoalsPage({ user }: ProtectedRouteProps) {
  const { hasAccessToAction, identityid } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  const [goals, setGoals] = useState<Goal[]>([]);
  const [filters, setFilters] = useState<GoalsFilters>(
    getInitialFilters(query.get('filter')),
  );
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState();
  const [founderVentures, setFounderVentures] = useState<Venture[]>([]);
  const [isFounderVentureLoading, setFounderVentureLoading] = useState(true);

  const ventureOptions = useMemo(
    () => [
      { value: 'ALL', label: 'All Ventures' },
      ...founderVentures.map((fv) => ({ value: fv.id, label: fv.ventureName })),
    ],
    [founderVentures],
  );

  const errorHandler = (error: any) => {
    if (error?.response?.status === 404 || error?.message === '404') {
      setIsLoading(false);
      return setIsEmptyState(true);
    }
    const errorMessage =
      error?.responserror?.data?.message || 'Internal server error';
    setIsLoading(false);
    setLoadError(errorMessage);
  };

  const handleGoalSliderChange = useCallback(
    ({ id, progress, status }) =>
      setGoals((prev) =>
        prev.map((prevGoal) =>
          prevGoal.id === id ? { ...prevGoal, progress, status } : prevGoal,
        ),
      ),
    [],
  );

  const handleFilterUpdate = useCallback(
    (field: string, value: string | GoalStatus) => {
      let filters = defaultFilters;
      setFilters((prev) => {
        filters = {
          ...prev,
          [field]: value,
        };
        return filters;
      });
      history.replace(
        `${location.pathname}?${stringifyQuery({
          filter: encodeQuery(filters),
        })}`,
      );
    },
    [history, location.pathname],
  );

  useEffect(() => {
    if (identityid) {
      const loadFounderVentures = async (identityid: string) => {
        try {
          setFounderVentureLoading(true);
          const loadedVentures =
            await venturesAPI.getVenturesDetailsByFounderId(identityid);
          setFounderVentures(loadedVentures);
          setFounderVentureLoading(false);
        } catch (error) {
          setFounderVentureLoading(false);
          errorHandler(error);
        }
      };

      loadFounderVentures(identityid);
    }
  }, [identityid]);

  useEffect(() => {
    if (identityid) {
      const loadGoals = async () => {
        try {
          setGoals([]);
          setIsLoading(true);
          setIsEmptyState(false);
          let loadedGoals;

          if (filters.status !== 'ALL' && filters.venture !== 'ALL') {
            loadedGoals = await getVentureGoalsByStatus(
              filters.venture as VentureId,
              filters.status,
            );
          } else if (filters.status !== 'ALL') {
            loadedGoals = await getOwnerGoalsByStatus(
              identityid as FounderId,
              filters.status,
            );
          } else if (filters.venture !== 'ALL') {
            loadedGoals = await getVentureGoals(filters.venture as VentureId);
          } else {
            loadedGoals = await getActiveOwnerGoals(identityid as FounderId);
          }

          if (loadedGoals.length === 0) throw new Error('404');
          setGoals(loadedGoals);
          setIsLoading(false);
        } catch (error) {
          errorHandler(error);
        }
      };

      loadGoals();
    }
  }, [identityid, filters.status, filters.venture]);

  return (
    <BaseLayout user={user} fullWidth>
      <Listing
        title='Goals'
        actions={[
          hasAccessToAction('goal.create') ? (
            <MaterialButton
              className={CLASS_TRACKING.ENTITY_ACTION}
              data-testid='button-new-goal'
              component={Link}
              to={{
                pathname: Pages.NEW_GOAL,
                state: {
                  prevPath: currentPath,
                },
                search:
                  filters.venture !== 'ALL'
                    ? `?venture=${encodeQuery(filters.venture)}`
                    : '',
              }}
              startIcon={<AddIcon />}
              variant='contained'
              key='button-new-goal'
              disabled={!founderVentures.length}
              color='primary'>
              Goal
            </MaterialButton>
          ) : null,
        ]}>
        <GoalsTable
          goals={goals}
          isLoading={isLoading}
          loadError={loadError}
          isEmptyState={isEmptyState}
          handleGoalSliderChange={handleGoalSliderChange}
          handleFilterUpdate={handleFilterUpdate}
          filters={filters}
          ventureOptions={ventureOptions}
          isFounderVentureLoading={isFounderVentureLoading}
          founderVentures={founderVentures}
        />
      </Listing>
    </BaseLayout>
  );
}

export default GoalsPage;
