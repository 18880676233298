import {
  CircularProgress,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import { cancelAppointment } from '../api/Appointments';
import { Appointment } from '../api/Appointments/types/Appointment';
import { getSettings } from '../api/tenants';
import { Settings } from '../api/tenants/types/Settings';
import { Text } from '../components/common';
import { COLORS } from '../theme/variables';
import { getCommentDate } from '../utils/date';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 400,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: 20,
  },
}));

function parseFilterFromURL(search: string): any {
  return _pick(parseQuery(search), ['office_hours_id']);
}

function CancelSessionPage() {
  const classes = useStyles();
  const location = useLocation();

  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const { office_hours_id } = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );

  useEffect(() => {
    const handleCancelSession = async () => {
      try {
        setErrorMessage('');
        setIsLoading(true);
        if (!office_hours_id) throw new Error();
        const appointment = await cancelAppointment(office_hours_id);
        const tenant = await getSettings(appointment.tenantId);
        setSettings(tenant);
        setAppointment(appointment);
      } catch (error) {
        console.error(error);
        setErrorMessage(
          (error as AxiosError).response?.data.message || 'An error occurred',
        );
      } finally {
        setIsLoading(false);
      }
    };

    handleCancelSession();
  }, [office_hours_id]);

  if (isLoading) {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div
        data-testid='mentor-wizard-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>{errorMessage}</Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid='mentor-wizard-page-success'
      className={cn(classes.container, classes.loadingContainer)}>
      <div className={cn(classes.block, classes.successBlock)}>
        <CheckCircleIcon className={classes.successIcon} />
        <div className={classes.successContent}>
          <Typography variant='h5' align='center'>
            The Session was Successfully Cancelled
          </Typography>
          <div className={classes.grid}>
            {appointment?.start ? (
              <>
                <Text variant='normal'>Date:</Text>
                <Text variant='normal'>
                  {getCommentDate(appointment.start)}
                </Text>
              </>
            ) : null}
            <Text variant='normal'>Topic:</Text>
            <Text variant='normal'>{appointment?.topic || '-'}</Text>
            <Text variant='normal'>Advisor Name:</Text>
            <Text variant='normal'>{appointment?.advisorFullName || '-'}</Text>
            <Text variant='normal'>Requestor:</Text>
            <Text variant='normal'>
              {appointment?.requestorFullName || '-'}
            </Text>
            {appointment?.refunded ? (
              <>
                <Text variant='normal'>Refund Issued on:</Text>
                <Text variant='normal'>{getCommentDate(new Date())}</Text>
              </>
            ) : null}
          </div>
          <Text variant='normal'>
            If you have additional questions about the session or you feel that
            a mistake was made, please feel free to reach out to the program
            admin at{' '}
            <Link href={`mailto:${settings?.email}`}>{settings?.email}</Link>.
          </Text>
        </div>
      </div>
    </div>
  );
}

export default CancelSessionPage;
