import { AxiosError } from 'axios';
import { mPipe, pass } from 'fp-utilities';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { SpecializationId } from '../../../../api/specializations';
import { TenantId } from '../../../../api/tenants/Tenant';
import { useQuery } from '../../../../hooks/useQuery';
import { isRight } from '../../../../utils/Either';
import { fromString } from '../../../../utils/String/Sentence';
import { getSettings } from '../common/Api';
import * as Api from './Api';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import * as Filter from './types/Filter';
import { Fetching, loading, Loading, State } from './types/State';

export function usePublicAdvisors(): [
  State,
  Dispatch<Actions.Next | Actions.Prev | Actions.SetFilter>,
] {
  const { tenantId } = useParams<{ tenantId: TenantId }>();
  const query = useQuery();
  const filterType = useMemo((): Filter.Filter => {
    const search = mPipe(
      () => query.get('search'),
      fromString(10000),
      pass(isRight),
    )();

    if (search) {
      return Filter.search(search.value);
    }

    const specialization = mPipe(() => query.get('specialization'))();

    if (specialization) {
      return Filter.specialization(specialization as SpecializationId);
    }

    return Filter.none();
  }, [query]);
  const [state, dispatch] = useReducer(
    reducer,
    loading({ tenantId, page: 1, filter: filterType }),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const state$ = useMemo(() => new BehaviorSubject(state), []);

  useEffect(() => {
    state$.next(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const loading$ = state$
      .pipe(
        distinctUntilKeyChanged('type'),
        filter((s): s is Loading => s.type === 'Loading'),
        switchMap((s) =>
          from(getSettings(s.payload.tenantId)).pipe(
            switchMap(({ timeZone, strictOfficeHoursScheduling }) =>
              forkJoin({
                advisors: from(
                  Api.get(
                    s.payload.tenantId,
                    timeZone,
                    s.payload.filter,
                    s.payload.page,
                  ),
                ),
                specializations: Api.getSpecializations(tenantId).catch(
                  () => [],
                ),
              }).pipe(
                map((r) =>
                  Actions.loadSuccess({
                    strictOfficeHoursScheduling,
                    specializations: r.specializations,
                    advisors: r.advisors.advisors,
                    nextPage: r.advisors.nextPage,
                    timeZone: timeZone,
                  }),
                ),
                catchError((e: AxiosError) =>
                  of(Actions.loadError(e.response?.status ?? 0)),
                ),
              ),
            ),
          ),
        ),
      )
      .subscribe(dispatch);

    const fetching = state$
      .pipe(
        distinctUntilKeyChanged('type'),
        filter((s): s is Fetching => s.type === 'Fetching'),
        switchMap((s) =>
          from(
            Api.get(
              s.payload.tenantId,
              s.payload.timeZone,
              s.payload.filter,
              s.payload.page,
            ),
          ).pipe(
            map(Actions.fetchSuccess),
            catchError((e: AxiosError) =>
              of(Actions.fetchError(e.response?.data.message)),
            ),
          ),
        ),
      )
      .subscribe(dispatch);

    const router$ = state$
      .pipe(
        map((s) => s.payload.filter),
        map(
          (
            f,
          ): {
            search: string | undefined;
            specialization: string | undefined;
          } => {
            return {
              specialization: f.type === 'Specialization' ? f.value : undefined,
              search: f.type === 'Search' ? f.value : undefined,
            };
          },
        ),
      )
      .subscribe(query.set);

    return () => {
      loading$.unsubscribe();
      fetching.unsubscribe();
      router$.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, dispatch];
}
