import { makeStyles } from '@material-ui/core/styles';
import { CardAdvisors, CardCalendar, CardPeople } from '../../assets/images';
import DashboardCard from '../../components/dashboard/card';
import BaseLayout from '../../components/layout/base-layout';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { useOfficeHoursDashboard } from './useOfficeHoursDashboard';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    width: 'calc(100% - 40px)',
    marginLeft: '-28px',
    flexDirection: 'column',
    padding: '0 20px',
    rowGap: 20,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },

    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% + 28px)',
      flexDirection: 'row',
      rowGap: 0,
    },

    '& > *': {
      marginLeft: '28px',
      maxWidth: 'auto',
      width: '100%',
      minHeight: '352px',
      boxSizing: 'border-box',

      [theme.breakpoints.up('sm')]: {
        maxWidth: '33.3333%',
      },
    },
  },
}));

export function OfficeHours({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const [s] = useOfficeHoursDashboard();

  switch (s.type) {
    case 'Loading':
    case 'LoadError':
      return <BaseLayout user={user} sidebar={'officehours'} isLoading />;
    case 'Ready':
      return (
        <BaseLayout user={user} sidebar='officehours'>
          <div className={classes.cards}>
            <DashboardCard
              logo={<CardAdvisors />}
              title={'Advisors'}
              statHelp={'Were added / removed in the last month'}
              cardType={'advisors'}
              statistic={{ total: s.payload.advisors.total }}
              addLink={Pages.OH_ADVISORS_NEW}
              link={`${Pages.OH_ADVISORS}?status=Active`}
              menuItems={[
                {
                  count: s.payload.advisors.thisWeek,
                  label: 'Have bookings this week',
                },
                {
                  count: s.payload.advisors.nextWeek,
                  label: 'Have bookings next week',
                },
                {
                  count: s.payload.advisors.afterNextWeek,
                  label: 'Have bookings further out',
                },
              ]}
            />
            <DashboardCard
              logo={<CardPeople />}
              title={'Community Members'}
              cardType={'community-members'}
              statistic={{ total: s.payload.communityMembers.total }}
              addLink={Pages.OH_COMMUNITY_MEMBERS_CREATE}
              link={`${Pages.OH_COMMUNITY_MEMBERS}?status=Active`}
              menuItems={[
                {
                  count: s.payload.communityMembers.applicants,
                  label: 'Applicants',
                },
                {
                  count: s.payload.communityMembers.joinedLastMonth,
                  label: 'Joined last month',
                },
                {
                  count: s.payload.communityMembers.withAppointments,
                  label: 'Had appointments last month',
                },
              ]}
            />
            <DashboardCard
              logo={<CardCalendar />}
              title={'Appointments'}
              cardType={'appointments'}
              statistic={{ total: s.payload.appointments.total }}
              addLink={Pages.OH_APPOINTMENTS_NEW}
              link={Pages.OH_APPOINTMENTS}
              menuItems={[
                {
                  count: s.payload.appointments.accepted,
                  label: 'Accepted',
                  link: `${Pages.OH_APPOINTMENTS}?status=accepted`,
                  'data-testid': 'appointments-show-accepted',
                },

                {
                  count: s.payload.appointments.declinedUnconfirmed,
                  label: 'Rejected/Requested',
                  link: `${Pages.OH_APPOINTMENTS}?status=nonaccepted`,
                  'data-testid': 'appointments-show-rejected-requested',
                },
              ]}
            />
          </div>
        </BaseLayout>
      );
  }
}
