import { useContext, useEffect, useReducer, useRef } from 'react';
import { ImagesContext } from '../contexts/images-context';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface Loading {
  type: 'Loading';
  src: string;
}

interface LoadErr {
  type: 'LoadErr';
  message: string;
  src: string;
}

interface Success {
  type: 'Success';
  url: string;
  src: string;
}

type State = Loading | LoadErr | Success;

// region AError
export interface AError {
  type: 'AError';
}

export const aError = (): AError => ({
  type: 'AError',
});
// endregion

// region ASuccess
export interface ASuccess {
  type: 'ASuccess';
  payload: string;
}

export const aSuccess = (payload: ASuccess['payload']): ASuccess => ({
  type: 'ASuccess',
  payload,
});
// endregion

// region Reset
export interface Reset {
  type: 'Reset';
  payload: string;
}

export const reset = (payload: Reset['payload']): Reset => ({
  type: 'Reset',
  payload,
});
// endregion

type Actions = AError | ASuccess | Reset;

function reducer(s: State, a: Actions): State {
  switch (a.type) {
    case 'Reset':
      return { type: 'Loading', src: a.payload };
    case 'AError':
      return s.type === 'Loading'
        ? { type: 'LoadErr', message: 'Unable to load image', src: s.src }
        : s;
    case 'ASuccess':
      return s.type === 'Loading'
        ? { type: 'Success', url: a.payload, src: s.src }
        : s;
  }
}

export function useAsyncLogo(src: string): [State] {
  const { getImage } = useContext(ImagesContext);
  const [state, dispatch] = useReducer(reducer, { type: 'Loading', src });
  const getImageRef = useRef({ get: getImage });
  const isLoading = state.type === 'Loading';
  getImageRef.current.get = getImage;

  useEffect(() => {
    if (state.type === 'Loading') {
      const o$ = from(getImageRef.current.get(state.src))
        .pipe(
          map(aSuccess),
          catchError(() => of(aError())),
        )
        .subscribe(dispatch);

      return () => o$.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    dispatch(reset(src));
  }, [src]);

  return [state];
}
