import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import { match } from 'fp-utilities';
import { stringify as stringifyQuery } from 'query-string';
import { pipe } from 'ramda';
import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EventVentures } from '../../../api/events';
import { VentureId } from '../../../api/ventures/types/Venture';
import { FiltersWrapper } from '../../../components/Pages/Forms/FiltersWrapper';
import { TestId } from '../../../components/Testing/TestId';
import {
  AlertState,
  Button,
  DateRangePickerInput,
  Text,
} from '../../../components/common';
import { ActionsDialog } from '../../../components/common/Confirmation/ActionsDialog';
import { Inline } from '../../../components/common/Inline';
import { EmptyPlaceHolder } from '../../../components/common/Table/EmptyPlaceHolder';
import { TableWrapper } from '../../../components/common/Table/Wrapper';
import CustomSelect from '../../../components/common/custom-select';
import SchedulingWizardInitialForm from '../../../components/forms/scheduling-wizard-initial-form';
import BaseLayout from '../../../components/layout/base-layout';
import { FounderSessionsTable } from '../../../components/tables/founder-sessions-table';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { ProtectedRouteProps } from '../../../router/type';
import { COLORS } from '../../../theme/variables';
import { encodeQuery } from '../../../utils/functions';
import {
  resetFilters,
  showScheduleCheck,
  cancelSchedule,
  setScheduleVentureId,
  confirmScheduleCheck,
  confirmScheduleDelete,
  confirmScheduleCreate,
  setFilterDateRange,
} from './types/Actions';
import {
  isLoadError,
  isLoading,
  isScheduleCheck,
  isScheduleChecking,
  stateIsLoaded,
  isScheduleDeleting,
  isScheduleDelete,
  isScheduleCreating,
  isScheduleCreate,
  LoadingPayload,
} from './types/State';
import { useFounderSessionsPage } from './useFounderSessionsPage';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    padding: '0 20px !important',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: 15,
    },
  },
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
  modalTitle: {
    '& > div > h4': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  ventureSelect: {
    '& > .MuiFormControl-root': {
      display: 'flex',
      '& legend > span': {
        lineHeight: '100%',
      },
    },
  },
  empty: {
    maxWidth: 500,
  },
}));

interface VentureOption {
  label: string;
  value: EventVentures;
}

export function FounderSessionsPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { rb } = useResourceBundles();
  const formRef = useRef<HTMLFormElement>();

  const [state, dispatch] = useFounderSessionsPage();
  const ventureOptions = useMemo(() => {
    const loading = isLoading(state) || isLoadError(state);
    const newVentureOptions: VentureOption[] = loading
      ? [{ label: 'All Ventures', value: 'ALL' }]
      : [
          { label: 'All Ventures', value: 'ALL' },
          ...state.payload.ventures.map((v) => ({
            label: v.name,
            value: v.id,
          })),
        ];

    return newVentureOptions;
  }, [state]);

  useEffect(() => {
    history.replace(
      `${location.pathname}?${stringifyQuery({
        filter: encodeQuery(state.payload.filter),
      })}`,
    );
  }, [history, location.pathname, state.payload.filter]);

  return (
    <BaseLayout user={user} fullWidth>
      <div className={classes.header}>
        <Typography variant='h2'>Sessions</Typography>
        <div className={classes.headerActions}>
          <Button
            onClick={pipe(showScheduleCheck, dispatch)}
            data-testid='button-scheduling-wizard'
            disabled={ventureOptions.length <= 1}
            startIcon={<AddIcon />}>
            Request a Session
          </Button>
        </div>
      </div>
      <TableWrapper
        header={
          <FiltersWrapper
            showClear={false}
            onClear={() => dispatch(resetFilters())}>
            <div style={{ width: '160px' }}>
              <TestId testId={'date-range-filter'}>
                <DateRangePickerInput
                  onChange={(v) =>
                    v &&
                    dispatch(
                      setFilterDateRange(
                        v as LoadingPayload['filter']['dateRange'],
                      ),
                    )
                  }
                  value={state.payload.filter.dateRange}
                />
              </TestId>
            </div>
          </FiltersWrapper>
        }>
        {match(
          [
            isLoading,
            () => (
              <EmptyPlaceHolder>
                <CircularProgress />
              </EmptyPlaceHolder>
            ),
          ],
          [
            isLoadError,
            (s) => (
              <EmptyPlaceHolder>
                {s.payload.message === 'No ventures found for the founder' ? (
                  <Text
                    className={classes.empty}
                    variant='normal'
                    testid='session-table-no-ventures'>
                    Sessions are unavailable as there are no ventures assigned
                    to you. If this is unexpected, please reach out to your
                    Program Administrator to clarify.
                  </Text>
                ) : (
                  <AlertState type='error'>{s.payload.message}</AlertState>
                )}
              </EmptyPlaceHolder>
            ),
          ],
          [
            stateIsLoaded,
            (s) =>
              s.payload.sessions.length ? (
                <FounderSessionsTable events={s.payload.sessions} />
              ) : (
                <EmptyPlaceHolder>
                  <Text
                    variant='normal'
                    className={classes.empty}
                    testid='session-table-no-items'>
                    There are no sessions scheduled yet with your participation.{' '}
                    <br /> Please reach out to the Program Manager to get a
                    session with a venture on your calendar!
                  </Text>
                </EmptyPlaceHolder>
              ),
          ],
        )(state)}
        <ActionsDialog
          open={isScheduleCheck(state) || isScheduleChecking(state)}
          title={
            <Inline gap={10}>
              <span>Scheduling Wizard</span>
              <Tooltip
                title={`This wizard schedules new sessions automatically. It will send a request to founders to provide meeting date options, and then will ask ${rb(
                  'mentors',
                )} to vote on the date that works for most. The new session(s) will be scheduled in 4 days.`}>
                <IconButton className={classes.buttonInfoIcon}>
                  <InfoIcon className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            </Inline>
          }
          actions={
            <Inline gap={10}>
              <Button
                variant={'outlined'}
                onClick={() => dispatch(cancelSchedule())}>
                Cancel
              </Button>
              <Button
                color={'primary'}
                onClick={() => dispatch(confirmScheduleCheck())}
                disabled={
                  isScheduleChecking(state) || !state.payload.scheduleVentureId
                }>
                Apply
              </Button>
            </Inline>
          }
          onClose={() => dispatch(cancelSchedule())}>
          {isScheduleCheck(state) || isScheduleChecking(state) ? (
            <div className={classes.ventureSelect}>
              <CustomSelect
                options={state.payload.ventures.map((v) => ({
                  label: v.name,
                  value: v.id,
                }))}
                testid={'select-venture'}
                onChange={(e) =>
                  dispatch(setScheduleVentureId(e.target.value as VentureId))
                }
                label='Schedule New Session(s) for'
                value={state.payload.scheduleVentureId}
              />
            </div>
          ) : null}
        </ActionsDialog>
        <ActionsDialog
          open={isScheduleDelete(state) || isScheduleDeleting(state)}
          title={
            <Inline gap={10}>
              <span>Scheduling Wizard</span>
              <Tooltip
                title={`This wizard schedules new sessions automatically. It will send a request to founders to provide meeting date options, and then will ask ${rb(
                  'mentors',
                )} to vote on the date that works for most. The new session(s) will be scheduled in 4 days.`}>
                <IconButton className={classes.buttonInfoIcon}>
                  <InfoIcon className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            </Inline>
          }
          actions={
            <Inline gap={10}>
              <Button
                variant={'outlined'}
                onClick={() => dispatch(cancelSchedule())}>
                Cancel
              </Button>
              <Button
                color={'primary'}
                onClick={() => dispatch(confirmScheduleDelete())}
                disabled={isScheduleDeleting(state)}>
                Remove
              </Button>
            </Inline>
          }
          onClose={() => dispatch(cancelSchedule())}>
          There is another wizard in place. Would you like to cancel it and
          start a new one?
        </ActionsDialog>
        <ActionsDialog
          open={isScheduleCreate(state) || isScheduleCreating(state)}
          title={
            <Inline gap={10}>
              <span>Scheduling Wizard</span>
              <Tooltip
                title={`This wizard schedules new sessions automatically. It will send a request to founders to provide meeting date options, and then will ask ${rb(
                  'mentors',
                )} to vote on the date that works for most. The new session(s) will be scheduled in 4 days.`}>
                <IconButton className={classes.buttonInfoIcon}>
                  <InfoIcon className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            </Inline>
          }
          actions={
            <Inline gap={10}>
              <Button
                variant={'outlined'}
                onClick={() => dispatch(cancelSchedule())}>
                Cancel
              </Button>
              <Button
                color={'primary'}
                onClick={() => formRef.current?.submit()}
                disabled={
                  isScheduleCreating(state) || !state.payload.scheduleVentureId
                }>
                Apply
              </Button>
            </Inline>
          }
          onClose={() => dispatch(cancelSchedule())}>
          {stateIsLoaded(state) && (
            <SchedulingWizardInitialForm
              initialValues={{
                email: state.payload.email,
                venture:
                  state.payload.ventures.find(
                    (v) => v.id === state.payload.scheduleVentureId,
                  )?.name || '',
                meeting: 'virtually',
                physicalLocation: undefined,
              }}
              onSubmit={(values) => dispatch(confirmScheduleCreate(values))}
              loading={isScheduleCreating(state)}
              timeZone={user.timeZone ?? 'US/Eastern'}
              noEmail
              noSubmit
              dateFullWidth
              ref={formRef}
            />
          )}
        </ActionsDialog>
      </TableWrapper>
    </BaseLayout>
  );
}
