import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useMemo } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  AdvisorIcon,
  CommunityMembersIcon,
  EventClockIcon,
} from '../../assets/icons';
import { Pages } from '../../router/constants';
import { Item, SidebarMenu } from '../common/Menu/Sidebar';

export function OfficeHours() {
  const location = useLocation();

  const menuItems = useMemo<Item[]>(() => {
    const items = [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.OH_DASHBOARD,
      },
      {
        id: 'advisors',
        label: 'Advisors',
        icon: AdvisorIcon,
        link: Pages.OH_ADVISORS,
      },
      {
        id: 'appointments',
        label: 'Appointments',
        icon: EventClockIcon,
        link: Pages.OH_APPOINTMENTS,
      },
      {
        id: 'community',
        label: 'Community',
        icon: CommunityMembersIcon,
        link: Pages.OH_COMMUNITY_MEMBERS,
      },
      {
        id: 'reports',
        label: 'Reports',
        icon: EqualizerIcon,
        link: Pages.OH_REPORTS,
      },
    ];

    return items.map((i) => ({
      ...i,
      active: !!matchPath(location.pathname, i.link),
    }));
  }, [location.pathname]);

  return <SidebarMenu items={menuItems} />;
}
