import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

export interface Props {
  title: string;
  actions?: ReactNode[];
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    padding: '0 20px !important',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
    '@media print': {
      display: 'none',
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: 15,
    },
  },
}));

export function Listing({
  title,
  children,
  actions,
}: PropsWithChildren<Props>): ReactElement {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <Typography variant='h2'>{title}</Typography>
        <div className={classes.headerActions}>{actions}</div>
      </div>
      {children}
    </>
  );
}
