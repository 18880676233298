import { IconButton, Paper, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import authAPI from '../../api/auth';
import foundersAPI, { Founder, FounderStatus } from '../../api/founders';
import {
  Button,
  ConfirmButton,
  PageLoader,
  RouteACL,
  SnackMessage,
} from '../../components/common';
import PageTabs from '../../components/founders/page-tabs';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import {
  FounderContext,
  FounderProvider,
} from '../../contexts/founder-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { isMobile } from '../../utils/functions';
import FounderAdditionalInfoPage from './founder-additional-info-page';
import FounderDetailsPage from './founder-details-page';
import FounderLogPage from './founder-log-page';
import FounderNotesPage from './founder-notes-page';
import FounderVenturesPage from './founder-ventures-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  requestLink: {
    minWidth: 187,
    whiteSpace: 'nowrap',
  },
  actionsContainer: {
    display: 'flex',
    columnGap: '16px',
  },
});

function FounderPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { founderId } = useParams<{ founderId: Founder['id'] }>();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const {
    founder,
    tenantUser,
    isFounderLoading,
    founderSpecializations,
    loadFounder,
    loadFounderSpecializations,
    updateFounder,
    setTenantUser,
  } = useContext(FounderContext);
  const [sending, setSending] = useState(false);
  const [isLoadingUserInvite, setLoadingUserInvite] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { hasAccessToAction } = useContext(UserContext);

  const handleStatusChange = useCallback(
    async (status: FounderStatus) => {
      if (founder) {
        const prevStatus = founder.status;
        try {
          updateFounder({ ...founder, status });
          await foundersAPI.update(founder.id, { ...founder, status });
          enqueueSnackbar(
            `The founder status was successfully changed to '${status}'`,
            {
              variant: 'success',
            },
          );
        } catch (e: any) {
          const messageError = e.response?.data?.message;

          updateFounder({ ...founder, status: prevStatus });
          enqueueSnackbar(
            "An error occurred while changing the founder's status. Please, try again.",
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          console.error('error status change', e);
        }
      }
    },
    [founder, updateFounder, enqueueSnackbar],
  );

  const handleSendFounderUpdate = async (onClose: () => any) => {
    try {
      setSending(true);
      await foundersAPI.sendFounderUpdateInvites([founderId]);
      enqueueSnackbar('The profile update request was successfully sent', {
        variant: 'success',
      });
      setSending(false);
      onClose();
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setSending(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const handleSendInvite = useCallback(
    async (onClose: () => any) => {
      if (founder) {
        try {
          setLoadingUserInvite(true);
          tenantUser?.inviteCode
            ? await authAPI.userInvites(tenantUser.id)
            : await authAPI.createFounders([founderId]);
          const loadedTenantUser = await authAPI.getUserByEmail(founder.email);
          setTenantUser(loadedTenantUser);
          onClose();
          enqueueSnackbar('The invitation was successfully sent!', {
            variant: 'success',
          });
          setLoadingUserInvite(false);
        } catch (error: any) {
          const messageError = error.response?.data?.message;
          enqueueSnackbar(
            'An error occurred when sending the invitation. Please try again.',
            {
              content: (key, message) =>
                SnackMessage({
                  key,
                  message,
                  variant: 'error',
                  additionalMessage: messageError,
                }),
              variant: 'error',
            },
          );
          setLoadingUserInvite(false);
        }
      }
    },
    [
      enqueueSnackbar,
      founder,
      founderId,
      setTenantUser,
      tenantUser?.id,
      tenantUser?.inviteCode,
    ],
  );

  // load founder's info
  useEffect(() => {
    if (!founder) {
      loadFounder(founderId);
    }
  }, [founderId, founder, loadFounder]);

  // load founder's specializations
  useEffect(() => {
    if (!founderSpecializations) {
      loadFounderSpecializations(founderId);
    }
  }, [founderSpecializations, founderId, loadFounderSpecializations]);

  return (
    <BaseLayout user={user} fullHeight>
      {isFounderLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title={`${founder?.firstName} ${founder?.lastName}`}
            onStatusChange={handleStatusChange}
            backLink={
              <Tooltip title='Back to Founders'>
                <IconButton
                  component={Link}
                  to={prevPath || Pages.ACTIVE_FOUNDERS}
                  data-testid='button-back-founders'>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            actionRenderer={() => (
              <div className={classes.actionsContainer}>
                {!isMobile() &&
                  hasAccessToAction('userManagement.founder') &&
                  !(tenantUser?.inviteCode === null) &&
                  founder?.email && (
                    <ConfirmButton
                      title='Give Founder Access to Traction5'
                      loading={isLoadingUserInvite}
                      body='An invite to join Traction5 will be sent to this Founder. They will be able to create an account, login, and review materials on their ventures and session. If they already have an account, the invite will be re-sent.'
                      successProps={{
                        btnLabel: 'Send Invite',
                        onSuccess: handleSendInvite,
                      }}
                      cancelProps={{
                        btnLabel: 'Cancel',
                      }}
                      buttonRenderer={({ onClick }) => (
                        <Button
                          onClick={onClick}
                          variant='contained'
                          data-testid='button-invite-user'
                          startIcon={
                            tenantUser?.inviteCode ? null : <AddIcon />
                          }>
                          {tenantUser?.inviteCode ? 'Resend Invite' : 'User'}
                        </Button>
                      )}
                    />
                  )}
                {hasAccessToAction('founder.updateProfile') && (
                  <ConfirmButton
                    title='Send Single Profile Update Request'
                    loading={sending}
                    body='Your are about to send a temporary link to allow participants to update their profile. The link can work only 1 time, and is available for 48 hours only.'
                    successProps={{
                      btnLabel: 'Ok',
                      onSuccess: handleSendFounderUpdate,
                    }}
                    cancelProps={{
                      btnLabel: 'Cancel',
                    }}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        className={classes.requestLink}
                        onClick={onClick}
                        variant='contained'
                        data-testid='button-invite-founder-update'>
                        Request Profile Update
                      </Button>
                    )}
                  />
                )}
              </div>
            )}
          />
          <Paper className={classes.container} elevation={1}>
            <PageTabs founderId={founderId} />
            <div className={classes.content}>
              <Switch>
                <RouteACL
                  exact
                  path={Pages.FOUNDER_DETAILS}
                  component={FounderDetailsPage}
                  rule='founder.details.view'
                  redirectUrl={Pages.ACTIVE_FOUNDERS}
                />
                <RouteACL
                  exact
                  path={Pages.FOUNDER_VENTURES}
                  component={FounderVenturesPage}
                  rule='founder.ventures.view'
                  redirectUrl={Pages.ACTIVE_FOUNDERS}
                />
                <RouteACL
                  exact
                  path={Pages.FOUNDER_ADDITIONAL_INFO}
                  component={FounderAdditionalInfoPage}
                  rule='founder.additionalInfo.view'
                  redirectUrl={Pages.ACTIVE_MENTORS}
                />
                <RouteACL
                  exact
                  path={Pages.FOUNDER_NOTES}
                  component={FounderNotesPage}
                  rule='founder.notes.view'
                  redirectUrl={Pages.ACTIVE_FOUNDERS}
                />
                <RouteACL
                  exact
                  path={Pages.FOUNDER_LOG}
                  component={FounderLogPage}
                  rule='founder.logs.view'
                  redirectUrl={Pages.ACTIVE_FOUNDERS}
                />
                <Redirect to={Pages.FOUNDER_DETAILS} />
              </Switch>
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

function withFounderProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <FounderProvider>
      <Component {...props} />
    </FounderProvider>
  );
}

export default withFounderProvider(FounderPage);
