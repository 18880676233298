import { createContext, useContext, useEffect, useState } from 'react';
import { TenantId } from '../api/tenants/Tenant';
import { getTenantId, parseKey } from '../pages/report/utils';
import { UserContext } from './user-context';

export interface ResourceBundlesProps {
  rb: (key: string) => string;
}

interface ResourceBundlesProviderProps {
  children: React.ReactNode;
}

export const ResourceBundlesContext = createContext<ResourceBundlesProps>({
  rb: function (key: string): string {
    console.error('ResourceBundlesContext not initialized');
    return key;
  },
});

export const ResourceBundlesProvider = ({
  children,
}: ResourceBundlesProviderProps) => {
  const { user } = useContext(UserContext);

  const [targetTenantId, setTargetTenantId] = useState<TenantId | undefined>();

  const [defaultResourceBundles, setDefaultResourceBundles] = useState<
    Record<string, string>
  >({});
  const [tenantResourceBundles, setTenantResourceBundles] =
    useState<null | Record<string, string>>(null);

  useEffect(() => {
    const updateTargetTenantId = () => {
      // tenant id from key in url query param or user id from user context
      const key = parseKey(window.location.search);
      const tenantIdFromKey = key ? getTenantId(key) : undefined;
      setTargetTenantId(tenantIdFromKey || user?.id);
    };

    updateTargetTenantId();

    window.addEventListener('popstate', updateTargetTenantId);

    return () => {
      window.removeEventListener('popstate', updateTargetTenantId);
    };
  }, [user?.id]);

  useEffect(() => {
    const fetchTenantResourceBundles = async () => {
      if (targetTenantId) {
        const fileName = `${targetTenantId}.json`;

        try {
          const response = await fetch('/resource-bundles/' + fileName);

          if (response.ok) {
            const jsonData = await response.json();
            setTenantResourceBundles(jsonData);
          } else {
            setTenantResourceBundles(null);
          }
        } catch (error) {
          setTenantResourceBundles(null);
        }
      }
    };

    fetchTenantResourceBundles();
  }, [targetTenantId]);

  useEffect(() => {
    const fetchDefaultResourceBundles = async () => {
      try {
        const response = await fetch('/resource-bundles/default.json');

        if (response.ok) {
          const jsonData = await response.json();
          setDefaultResourceBundles(jsonData);
        }
      } catch (error) {
        console.error('Error fetching default resource bundle');
      }
    };

    fetchDefaultResourceBundles();
  }, []);

  const rb = (key: string) => {
    if (tenantResourceBundles && tenantResourceBundles[key]) {
      return tenantResourceBundles[key];
    } else if (defaultResourceBundles[key]) {
      return defaultResourceBundles[key];
    } else {
      return key;
    }
  };

  const contextValue: ResourceBundlesProps = {
    rb,
  };

  return (
    <ResourceBundlesContext.Provider value={contextValue}>
      {children}
    </ResourceBundlesContext.Provider>
  );
};

export const useResourceBundles = () => {
  return useContext(ResourceBundlesContext);
};
