import { isSameDay } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { match } from 'fp-utilities';
import { apply } from '../../../../../utils/Either';
import {
  initial,
  invalid,
  isValid,
  valid,
} from '../../../../../utils/FormValue';
import * as Email from '../../../../../utils/String/Email';
import * as Sentence from '../../../../../utils/String/Sentence';
import { unreachableError } from '../../../../../utils/unreachableError';
import { Actions } from './types/Actions';
import * as State from './types/State';
import * as Strategy from './types/Strategy';
import { validateEmail, validateConfirmEmail, validateTerms } from './utils';

const invalidName = 'Invalid Name';
const invalidEmail = 'Invalid Email';
const invalidConfirmEmail = 'Invalid Confirm Email';
const invalidTopic = 'Invalid Topic';
const invalidAgenda = 'Invalid Agenda';
const invalidTerms = 'Invalid Terms';

export function reducer(s: State.State, a: Actions): State.State {
  switch (a.type) {
    case 'Book': {
      const str = Strategy.strategy(
        s.payload.user,
        a.payload.tenantId,
        a.payload.strictOfficeHoursScheduling,
      );

      if (!str) {
        return State.logIn({
          tenantId: a.payload.tenantId,
          timeZone: a.payload.timeZone,
          strictOfficeHoursScheduling: a.payload.strictOfficeHoursScheduling,
          advisor: a.payload.advisor,
          username: initial(undefined),
          password: initial(undefined),
          user: { type: 'Guest' },
          showPassword: false,
        });
      }

      return match(
        [
          Strategy.isPublic,
          (strategy): State.State =>
            State.bookingLoading({
              tenantId: a.payload.tenantId,
              timeZone: a.payload.timeZone,
              strictOfficeHoursScheduling:
                a.payload.strictOfficeHoursScheduling,
              advisor: a.payload.advisor,
              user: s.payload.user,
              strategy,
            }),
        ],
        [
          Strategy.isPrivate,
          (strategy): State.State =>
            State.bookingLoading({
              tenantId: a.payload.tenantId,
              timeZone: a.payload.timeZone,
              strictOfficeHoursScheduling:
                a.payload.strictOfficeHoursScheduling,
              advisor: a.payload.advisor,
              user: s.payload.user,
              strategy,
            }),
        ],
      )(str);
    }
    case 'BookFetchError':
      return s.type === 'BookingLoading'
        ? State.bookingLoadingError({ ...s.payload, message: a.payload })
        : s;
    case 'BookFetchSuccess':
      return s.type === 'BookingLoading'
        ? a.payload.length === 0
          ? State.bookingLoadingError({
              ...s.payload,
              message: 'Unfortunately the advisor has no available time slots',
            })
          : State.bookingDay({
              ...s.payload,
              timeslots: a.payload.map(({ start, end }) => ({
                start: toDate(start, { timeZone: s.payload.timeZone }),
                end: toDate(end, { timeZone: s.payload.timeZone }),
              })),
              day: undefined,
              start: undefined,
              end: undefined,
              name: undefined,
              email: undefined,
              confirmEmail: undefined,
              agenda: undefined,
              topic: undefined,
              terms: undefined,
            })
        : s;
    case 'BookDay':
      return s.type === 'BookingDay'
        ? State.bookingTime({
            ...s.payload,
            day: a.payload,
            hours: s.payload.timeslots.filter((v) =>
              isSameDay(v.start, a.payload),
            ),
            start: undefined,
            end: undefined,
          })
        : s;
    case 'CancelPayment':
      return s.type === 'SaveSuccessWithPayment'
        ? State.cancelingPayment(s.payload)
        : s;
    case 'BookTime':
      return s.type === 'BookingTime' && s.payload.hours[a.payload]
        ? Strategy.isPublic(s.payload.strategy) &&
          Strategy.isGuest(s.payload.strategy)
          ? State.bookingGuestDetails({
              ...s.payload,
              strategy: s.payload.strategy,
              start: s.payload.hours[a.payload].start,
              end: s.payload.hours[a.payload].end,
              name: s.payload.name ?? initial(''),
              email: s.payload.email ?? initial(''),
              confirmEmail: s.payload.confirmEmail ?? initial(''),
              agenda: s.payload.agenda ?? initial(''),
              topic: s.payload.topic ?? initial(''),
              terms: s.payload.terms ?? initial(''),
              inputs: {
                topic: s.payload.topic?.value || '',
                agenda: s.payload.agenda?.value || '',
              },
            })
          : State.bookingLoggedInDetails({
              ...s.payload,
              strategy: s.payload.strategy,
              start: s.payload.hours[a.payload].start,
              end: s.payload.hours[a.payload].end,
              name: s.payload.name ?? initial(''),
              email: s.payload.email ?? initial(''),
              confirmEmail: s.payload.confirmEmail ?? initial(''),
              agenda: s.payload.agenda ?? initial(''),
              topic: s.payload.topic ?? initial(''),
              terms: s.payload.terms ?? initial(''),
              inputs: {
                topic: s.payload.topic?.value || '',
                agenda: s.payload.agenda?.value || '',
              },
            })
        : s;
    case 'SetValue': {
      if (State.isBookingDetails(s)) {
        switch (a.payload.key) {
          case 'name':
            return s.type === 'BookingGuestDetails'
              ? State.bookingGuestDetails({
                  ...s.payload,
                  name: apply(
                    () => invalid(invalidName, a.payload.value),
                    valid,
                    Sentence.fromString(100)(a.payload.value),
                  ),
                })
              : s;
          case 'email':
            return s.type === 'BookingGuestDetails'
              ? State.bookingGuestDetails({
                  ...s.payload,
                  email: apply(
                    () => invalid(invalidEmail, a.payload.value),
                    valid,
                    validateEmail(a.payload.value),
                  ),
                  confirmEmail:
                    s.payload.confirmEmail.__typeName !== 'initial'
                      ? apply(
                          () =>
                            invalid(
                              invalidConfirmEmail,
                              s.payload.confirmEmail.value,
                            ),
                          valid,
                          validateConfirmEmail(
                            s.payload.confirmEmail.value,
                            a.payload.value,
                          ),
                        )
                      : s.payload.confirmEmail,
                })
              : s;
          case 'confirmEmail':
            return s.type === 'BookingGuestDetails'
              ? State.bookingGuestDetails({
                  ...s.payload,
                  confirmEmail: apply(
                    () => invalid(invalidConfirmEmail, a.payload.value),
                    valid,
                    validateConfirmEmail(
                      a.payload.value,
                      s.payload.email.value,
                    ),
                  ),
                })
              : s;
          case 'topic':
            return {
              type: s.type,
              payload: {
                ...s.payload,
                topic: apply(
                  () => invalid(invalidTopic, a.payload.value),
                  valid,
                  Sentence.fromString(250)(a.payload.value),
                ),
                inputs: {
                  ...s.payload.inputs,
                  topic: a.payload.value,
                },
              },
            } as State.BookingDetails;
          case 'agenda':
            return {
              type: s.type,
              payload: {
                ...s.payload,
                agenda: apply(
                  () => invalid(invalidAgenda, a.payload.value),
                  valid,
                  Sentence.fromString(500)(a.payload.value),
                ),
                inputs: {
                  ...s.payload.inputs,
                  agenda: a.payload.value,
                },
              },
            } as State.BookingDetails;
          case 'terms':
            return {
              type: s.type,
              payload: {
                ...s.payload,
                terms: apply(
                  () => invalid(invalidTerms, a.payload.value),
                  valid,
                  validateTerms(a.payload.value),
                ),
              },
            } as State.BookingDetails;

          default:
            return unreachableError(a.payload.key);
        }
      }

      return s;
    }
    case 'Toggle': {
      if (State.isBookingDetails(s)) {
        switch (a.payload) {
          case 'name':
            return s.type === 'BookingGuestDetails'
              ? State.bookingGuestDetails({
                  ...s.payload,
                  name:
                    s.payload.name.__typeName === 'initial'
                      ? invalid(invalidName, s.payload.name.value)
                      : s.payload.name,
                })
              : s;
          case 'email':
            return s.type === 'BookingGuestDetails'
              ? State.bookingGuestDetails({
                  ...s.payload,
                  email:
                    s.payload.email.__typeName === 'initial'
                      ? invalid(invalidEmail, s.payload.email.value)
                      : s.payload.email,
                })
              : s;
          case 'confirmEmail':
            return s.type === 'BookingGuestDetails'
              ? State.bookingGuestDetails({
                  ...s.payload,
                  confirmEmail:
                    s.payload.confirmEmail.__typeName === 'initial'
                      ? invalid(
                          invalidConfirmEmail,
                          s.payload.confirmEmail.value,
                        )
                      : s.payload.confirmEmail,
                })
              : s;
          case 'topic':
            return {
              type: s.type,
              payload: {
                ...s.payload,
                topic:
                  s.payload.topic.__typeName === 'initial'
                    ? invalid(invalidTopic, s.payload.topic.value)
                    : s.payload.topic,
              },
            } as State.BookingDetails;
          case 'agenda':
            return {
              type: s.type,
              payload: {
                ...s.payload,
                agenda:
                  s.payload.agenda.__typeName === 'initial'
                    ? invalid(invalidAgenda, s.payload.agenda.value)
                    : s.payload.agenda,
              },
            } as State.BookingDetails;
          case 'terms':
            return {
              type: s.type,
              payload: {
                ...s.payload,
                terms:
                  s.payload.terms?.__typeName === 'initial'
                    ? invalid(invalidTerms, s.payload.terms.value)
                    : s.payload.terms,
              },
            } as State.BookingDetails;
          default:
            return unreachableError(a);
        }
      }

      return s;
    }
    case 'Cancel': {
      switch (s.type) {
        case 'Idle':
        case 'SavingLoggedIn':
        case 'SavingGuest':
        case 'BookingLoading':
        case 'BookingLoadingError':
          return s;
        case 'LogIn':
        case 'LoggingIn':
        case 'LoginError':
        case 'ResetError':
        case 'ResetPassword':
        case 'Resetting':
        case 'BookingDay':
        case 'BookingTime':
        case 'BookingLoggedInDetails':
        case 'BookingGuestDetails':
          return State.idle(s.payload);
        case 'SaveErrorGuest':
        case 'SaveErrorLoggedIn':
        case 'SaveSuccess':
        case 'SaveSuccessWithPayment':
        case 'CancelingPayment':
          return State.idle(s.payload);
        default:
          return unreachableError(s);
      }
    }
    case 'Confirm': {
      switch (s.type) {
        case 'SaveSuccess':
        case 'SaveSuccessWithPayment':
          return State.idle(s.payload);
        case 'Idle':
        case 'BookingDay':
        case 'BookingTime':
        case 'BookingGuestDetails':
        case 'BookingLoggedInDetails':
        case 'SavingGuest':
        case 'SaveErrorGuest':
        case 'SaveErrorLoggedIn':
        case 'SavingLoggedIn':
        case 'BookingLoadingError':
        case 'BookingLoading':
        case 'LogIn':
        case 'LoggingIn':
        case 'LoginError':
        case 'ResetError':
        case 'ResetPassword':
        case 'Resetting':
        case 'CancelingPayment':
          return s;
        default:
          return unreachableError(s);
      }
    }
    case 'Decline': {
      switch (s.type) {
        case 'Idle':
        case 'BookingDay':
        case 'BookingTime':
        case 'BookingLoggedInDetails':
        case 'BookingGuestDetails':
        case 'SavingGuest':
        case 'SavingLoggedIn':
        case 'SaveSuccess':
        case 'SaveSuccessWithPayment':
        case 'SaveErrorLoggedIn':
        case 'SaveErrorGuest':
        case 'BookingLoading':
        case 'BookingLoadingError':
        case 'LogIn':
        case 'LoggingIn':
        case 'LoginError':
        case 'Resetting':
        case 'CancelingPayment':
        case 'ResetPassword':
        case 'ResetError':
          return s;
        default:
          return unreachableError(s);
      }
    }
    case 'Back': {
      switch (s.type) {
        case 'BookingDay':
        case 'SaveSuccess':
        case 'SaveSuccessWithPayment':
        case 'SavingLoggedIn':
        case 'SavingGuest':
        case 'SaveErrorGuest':
        case 'SaveErrorLoggedIn':
        case 'Idle':
        case 'BookingLoadingError':
        case 'BookingLoading':
        case 'LogIn':
        case 'LoggingIn':
        case 'LoginError':
        case 'ResetError':
        case 'ResetPassword':
        case 'Resetting':
        case 'CancelingPayment':
          return s;
        case 'BookingTime':
          return State.bookingDay(s.payload);
        case 'BookingGuestDetails':
        case 'BookingLoggedInDetails':
          return State.bookingTime(s.payload);
        default:
          return unreachableError(s);
      }
    }
    case 'Submit': {
      if (State.isBookingDetails(s) || State.isSaveError(s)) {
        switch (s.type) {
          case 'BookingGuestDetails': {
            const { name, email, confirmEmail, topic, agenda, terms } =
              s.payload;

            return isValid(name) &&
              isValid(email) &&
              isValid(confirmEmail) &&
              isValid(topic) &&
              isValid(agenda) &&
              isValid(terms)
              ? State.savingGuest({
                  ...s.payload,
                  name,
                  email,
                  confirmEmail,
                  topic,
                  agenda,
                  terms,
                })
              : State.bookingGuestDetails({
                  ...s.payload,
                  name:
                    s.payload.name.__typeName === 'initial'
                      ? invalid(invalidName, s.payload.name.value)
                      : s.payload.name,
                  email:
                    s.payload.email.__typeName === 'initial'
                      ? invalid(invalidEmail, s.payload.email.value)
                      : s.payload.email,
                  confirmEmail:
                    s.payload.confirmEmail.__typeName === 'initial'
                      ? invalid(
                          invalidConfirmEmail,
                          s.payload.confirmEmail.value,
                        )
                      : s.payload.confirmEmail,
                  topic:
                    s.payload.topic.__typeName === 'initial'
                      ? invalid(invalidTopic, s.payload.topic.value)
                      : s.payload.topic,
                  agenda:
                    s.payload.agenda.__typeName === 'initial'
                      ? invalid(invalidAgenda, s.payload.agenda.value)
                      : s.payload.agenda,
                  terms:
                    s.payload.terms?.__typeName === 'initial'
                      ? invalid(invalidTerms, s.payload.terms.value)
                      : s.payload.terms,
                });
          }
          case 'BookingLoggedInDetails': {
            const { topic, agenda, terms } = s.payload;

            return isValid(topic) && isValid(agenda) && isValid(terms)
              ? State.savingLoggedIn({ ...s.payload, topic, agenda, terms })
              : State.bookingLoggedInDetails({
                  ...s.payload,
                  topic:
                    s.payload.topic.__typeName === 'initial'
                      ? invalid(invalidTopic, s.payload.topic.value)
                      : s.payload.topic,
                  agenda:
                    s.payload.agenda.__typeName === 'initial'
                      ? invalid(invalidAgenda, s.payload.agenda.value)
                      : s.payload.agenda,
                  terms:
                    s.payload.terms?.__typeName === 'initial'
                      ? invalid(invalidTerms, s.payload.terms.value)
                      : s.payload.terms,
                });
          }
        }
      }

      return s;
    }
    case 'SaveError':
      if (State.isSaving(s)) {
        switch (s.type) {
          case 'SavingLoggedIn':
            return State.saveErrorLoggedIn({
              ...s.payload,
              message: a.payload,
            });
          case 'SavingGuest':
            return State.saveErrorGuest({ ...s.payload, message: a.payload });
        }
      }
      return s;
    case 'SaveSuccess':
      return State.isSaving(s) ? State.saveSuccess(s.payload) : s;
    case 'SaveSuccessWithPayment':
      return State.isSaving(s)
        ? State.saveSuccessWithPayment({ ...s.payload, appointment: a.payload })
        : s;
    case 'SetName': {
      const u = Email.fromString(a.payload);
      return s.type === 'LogIn' || s.type === 'LoginError'
        ? State.logIn({
            ...s.payload,
            username: u ? valid(u) : invalid('required', a.payload),
          })
        : s.type === 'ResetPassword' || s.type === 'ResetError'
        ? State.resetPassword({ ...s.payload, username: initial(undefined) })
        : s;
    }
    case 'SetPass': {
      return s.type === 'LogIn' || s.type === 'LoginError'
        ? State.logIn({
            ...s.payload,
            password: apply(
              () => invalid('required', a.payload),
              valid,
              Sentence.fromString(100)(a.payload),
            ),
          })
        : s;
    }
    case 'Login':
      return s.type === 'LogIn' &&
        isValid(s.payload.username) &&
        isValid(s.payload.password)
        ? State.loggingIn({
            ...s.payload,
            username: s.payload.username,
            password: s.payload.password,
          })
        : s;
    case 'TogglePassword':
      return s.type === 'LogIn'
        ? State.logIn({ ...s.payload, showPassword: !s.payload.showPassword })
        : s;
    case 'LoginError':
      return s.type === 'LoggingIn'
        ? State.loginError({ ...s.payload, message: a.payload })
        : s;
    case 'UserLoggedIn': {
      switch (s.type) {
        case 'LoggingIn':
        case 'LoginError': {
          const str = Strategy.strategy(
            a.payload,
            s.payload.tenantId,
            s.payload.strictOfficeHoursScheduling,
          );

          if (!str) {
            return State.loginError({
              ...s.payload,
              username: s.payload.username,
              password: s.payload.password,
              user: { type: 'Guest' },
              showPassword: false,
              message: 'It seems your user have no rights to book',
            });
          }

          return match(
            [
              Strategy.isPublic,
              (strategy): State.State =>
                State.bookingLoading({
                  ...s.payload,
                  user: a.payload,
                  strategy,
                }),
            ],
            [
              Strategy.isPrivate,
              (strategy): State.State =>
                State.bookingLoading({
                  ...s.payload,
                  user: a.payload,
                  strategy,
                }),
            ],
          )(str);
        }
        case 'Idle':
        case 'ResetError':
        case 'ResetPassword':
        case 'Resetting':
        case 'CancelingPayment':
        case 'BookingDay':
        case 'BookingGuestDetails':
        case 'BookingLoggedInDetails':
        case 'BookingLoading':
        case 'BookingLoadingError':
        case 'BookingTime':
        case 'SaveErrorGuest':
        case 'SaveErrorLoggedIn':
        case 'SaveSuccess':
        case 'SaveSuccessWithPayment':
        case 'LogIn':
        case 'SavingGuest':
        case 'SavingLoggedIn':
          return State.idle({
            user: { type: 'LoggedIn', tenantId: a.payload.tenantId },
          });

        default:
          return unreachableError(s);
      }
    }
    case 'UserLoggedOut':
      return State.idle({ user: { type: 'Guest' } });
    case 'GoToReset':
      return s.type === 'LogIn' || s.type === 'LoginError'
        ? State.resetPassword({ ...s.payload, username: initial(undefined) })
        : s;
    case 'ResetPassword':
      return (s.type === 'ResetPassword' || s.type === 'ResetError') &&
        isValid(s.payload.username)
        ? State.resetting({ ...s.payload, username: s.payload.username })
        : s;
    case 'ResetError':
      return s.type === 'Resetting'
        ? State.resetError({ ...s.payload, message: a.payload })
        : s;
    case 'ResetSuccess':
      return s.type === 'Resetting'
        ? State.logIn({
            ...s.payload,
            username: initial(undefined),
            password: initial(undefined),
            showPassword: false,
          })
        : s;
  }
}
