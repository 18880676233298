import { parse as parseQuery } from 'query-string';
import _pick from 'lodash/pick';
import { TenantId } from '../../api/tenants/Tenant';

export function getTenantId(key: string): TenantId | undefined {
  try {
    const id = atob(key).split('|')[0] as TenantId;

    return id ? id : undefined;
  } catch {
    return undefined;
  }
}

export function parseKey(search: string): string | undefined {
  const { key } = _pick(parseQuery(search), ['key']);

  return typeof key === 'string' ? key : undefined;
}
