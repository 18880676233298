import { IconButton, Theme, Tooltip, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useParentPath } from '../../../hooks/router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    marginBottom: '24px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
}));

export interface Props {
  title: string;
  back?: string;
}

export function WithTitle({ title, back, children }: PropsWithChildren<Props>) {
  const classes = useStyles();
  const parent = useParentPath();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {back ? (
          <Tooltip title='Back to Advisors'>
            <IconButton
              component={Link}
              to={parent ?? back}
              data-testid='button-back-advisors'>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <Typography variant={'h2'}>{title}</Typography>
      </div>
      {children}
    </div>
  );
}
