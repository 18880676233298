import AddIcon from '@material-ui/icons/Add';
import { stringify as stringifyQuery } from 'query-string';
import { useState } from 'react';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import filesAPI from '../api/files';
import { FounderId } from '../api/founders';
import mentorsAPI, { Mentor, MentorId } from '../api/mentors';
import venturesAPI from '../api/ventures';
import { VentureId } from '../api/ventures/types/Venture';
import { Listing } from '../components/Pages/Listing';
import { MentorManagementRequestButton } from '../components/common';
import { OptionProps } from '../components/common/select';
import BaseLayout from '../components/layout/base-layout';
import MyMentorsTable from '../components/tables/my-mentors-table';
import { useResourceBundles } from '../contexts/resource-bundles-context';
import { UserContext } from '../contexts/user-context';
import { useQuery } from '../hooks/useQuery';
import { ProtectedRouteProps } from '../router/type';
import { decodeQuery, encodeQuery } from '../utils/functions';

interface MyMentorsFilters {
  venture?: VentureId;
}

function MyMentorsPage({ user }: ProtectedRouteProps) {
  const { hasAccessToAction, identityid } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const { get } = useQuery();
  const { rb } = useResourceBundles();
  const filterFromQuery: MyMentorsFilters = decodeQuery(get('filter') || '');
  const [selectedVenture, setSelectedVenture] = useState<VentureId | null>(
    filterFromQuery?.venture || null,
  );
  const [ventureOptions, setVentureOptions] = useState<
    OptionProps<VentureId>[]
  >([]);
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [mentorOptions, setMentorOptions] = useState<OptionProps<MentorId>[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isVenturesLoading, setIsVenturesLoading] = useState(true);
  const [loadError, setLoadError] = useState();
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [mentorsLogos, setMentorsLogos] = useState<{
    [x: string]: string | null;
  }>({});

  const errorHandler = (error: any) => {
    setIsLoading(false);
    setIsVenturesLoading(false);
    if (error?.response?.status === 404 || error?.message === '404') {
      return setIsEmptyState(true);
    }
    const errorMessage =
      error?.response?.data?.message || 'Internal server error';
    setLoadError(errorMessage);
  };

  useEffect(() => {
    if (identityid && selectedVenture) {
      const loadMentors = async (ventureId: VentureId) => {
        try {
          setMentors([]);
          setMentorOptions([]);
          setIsLoading(true);
          setIsEmptyState(false);
          const loadedMentors = await mentorsAPI.getMentorsDetailsByVentureId(
            ventureId,
          );
          if (loadedMentors.length === 0) throw new Error('404');
          setMentors(loadedMentors);
          const loadedMentorOptions: OptionProps<MentorId>[] =
            loadedMentors.map((v) => ({
              value: v.id,
              label: `${v.firstName} ${v.lastName}`,
            }));
          setMentorOptions(loadedMentorOptions);
          setIsLoading(false);
        } catch (error) {
          errorHandler(error);
        }
      };

      loadMentors(selectedVenture);
    }
  }, [identityid, selectedVenture]);

  useEffect(() => {
    const loadMentorsLogos = async (mentorsList: Mentor[]) => {
      try {
        const mentorImages = mentorsList.map((mentor: Mentor) => ({
          id: mentor.id,
          logo: mentor.logo,
        }));
        setMentorsLogos(() => {
          const sources = mentorImages.reduce((res, mentor) => {
            res[mentor.id] = mentor.logo ? '' : null;
            return res;
          }, {} as { [x: string]: string | null });
          return sources;
        });
        const shouldBeLoaded = mentorImages.filter(
          (mentorImage) => !!mentorImage.logo,
        );
        const logos = shouldBeLoaded.map((mentorImage) => mentorImage.logo);
        if (!logos.length) {
          return false;
        }
        const loadedLogos = await filesAPI.getFileLogos(logos as string[]);

        const nextState = shouldBeLoaded.reduce(
          (res, mentorImage, mentorImageIndex) => {
            res[mentorImage.id] = loadedLogos[mentorImageIndex];
            return res;
          },
          {} as { [x: string]: string },
        );
        return setMentorsLogos((prevMentors) => ({
          ...prevMentors,
          ...nextState,
        }));
      } catch (e: any) {
        console.error('loadMentorsLogos error');
        return false;
      }
    };

    if (mentors.length) {
      loadMentorsLogos(mentors);
    }
  }, [mentors]);

  useEffect(() => {
    history.replace(
      `${location.pathname}?${stringifyQuery({
        filter: encodeQuery({
          venture: selectedVenture,
        }),
      })}`,
    );
  }, [history, location.pathname, selectedVenture]);

  useEffect(() => {
    if (identityid) {
      const loadVentures = async (founderId: FounderId) => {
        try {
          setVentureOptions([]);
          setIsVenturesLoading(true);
          const loadedVentures =
            await venturesAPI.getVenturesDetailsByFounderId(founderId);
          const loadedVentureOptions: OptionProps<VentureId>[] =
            loadedVentures.map((v) => ({
              value: v.id,
              label: v.ventureName,
            }));
          setVentureOptions(loadedVentureOptions);
          setSelectedVenture((prev) =>
            prev || loadedVentures.length ? loadedVentures[0].id : null,
          );
          setIsVenturesLoading(false);
        } catch (error) {
          errorHandler(error);
        }
      };

      loadVentures(identityid as FounderId);
    }
  }, [identityid, setSelectedVenture]);

  return (
    <BaseLayout user={user} fullWidth>
      <Listing
        title={`My ${rb('mentors-u')}`}
        actions={[
          hasAccessToAction('mentor.details.manageMentor') ? (
            <MentorManagementRequestButton
              title={`Manage ${rb('mentors-u')}`}
              mentorId={null}
              buttonText={`Manage ${rb('mentors-u')}`}
              defaultType='REMOVE'
              selectableType
              ventureId={selectedVenture !== 'ALL' ? selectedVenture : null}
              mentorOptions={mentorOptions}
              disabledButton={!mentorOptions?.length}
            />
          ) : null,
          hasAccessToAction('mentor.mentorRequest') ? (
            <MentorManagementRequestButton
              title={`Add ${rb('mentor-u')}`}
              caption={`Send a request to your programm admin to add a ${rb(
                'mentor',
              )}`}
              ventureId={selectedVenture !== 'ALL' ? selectedVenture : null}
              buttonText={rb('mentor-u')}
              defaultType='ADD'
              startIcon={<AddIcon />}
              mentorId={null}
              ventureOptions={ventureOptions}
              disabledButton={!ventureOptions?.length}
              testid='add-mentor-management-button'
            />
          ) : null,
        ]}>
        <MyMentorsTable
          selectedVenture={selectedVenture}
          setSelectedVenture={setSelectedVenture}
          ventureOptions={ventureOptions}
          isLoading={isLoading}
          isVenturesLoading={isVenturesLoading}
          mentors={mentors}
          mentorsLogos={mentorsLogos}
          loadError={loadError}
          isEmptyState={isEmptyState}
        />
      </Listing>
    </BaseLayout>
  );
}

export default MyMentorsPage;
