import { TenantId } from '../../../../../../api/tenants/Tenant';
import { TenantTimeZone } from '../../../../../../api/tenants/types/Settings';
import { Valid, Value } from '../../../../../../utils/FormValue';
import { Email } from '../../../../../../utils/String/Email';
import { Sentence } from '../../../../../../utils/String/Sentence';
import { Item } from './Item';
import { Private, PublicGuest, PublicLoggedIn, Strategy } from './Strategy';
import { TimeRange } from './TimeRange';
import { Guest, User } from './User';

// region Idle
export interface IdlePayload {
  user: User;
}

export interface Idle {
  type: 'Idle';
  payload: IdlePayload;
}

export const idle = (payload: Idle['payload']): Idle => ({
  type: 'Idle',
  payload,
});
// endregion

// region LogIn
export interface LogInPayload {
  strictOfficeHoursScheduling: boolean;
  tenantId: TenantId;
  timeZone: TenantTimeZone;
  advisor: Item;
  user: Guest;
  username: Value<'required', Email, string | undefined>;
  password: Value<'required', Sentence<100>, string | undefined>;
  showPassword: boolean;
}

export interface LogIn {
  type: 'LogIn';
  payload: LogInPayload;
}

export const logIn = (payload: LogIn['payload']): LogIn => ({
  type: 'LogIn',
  payload,
});
// endregion

// region LoggingIn
export interface LoggingInPayload extends LogInPayload {
  username: Valid<Email>;
  password: Valid<Sentence<100>>;
}

export interface LoggingIn {
  type: 'LoggingIn';
  payload: LoggingInPayload;
}

export const loggingIn = (payload: LoggingIn['payload']): LoggingIn => ({
  type: 'LoggingIn',
  payload,
});
// endregion

// region LoginError
export interface LoginErrorPayload extends LoggingInPayload {
  message: string;
}

export interface LoginError {
  type: 'LoginError';
  payload: LoginErrorPayload;
}

export const loginError = (payload: LoginError['payload']): LoginError => ({
  type: 'LoginError',
  payload,
});
// endregion

// region ResetPassword
export interface ResetPasswordPayload {
  strictOfficeHoursScheduling: boolean;
  tenantId: TenantId;
  timeZone: TenantTimeZone;
  advisor: Item;
  username: Value<'required', Email, string | undefined>;
  user: Guest;
}

export interface ResetPassword {
  type: 'ResetPassword';
  payload: ResetPasswordPayload;
}

export const resetPassword = (
  payload: ResetPassword['payload'],
): ResetPassword => ({
  type: 'ResetPassword',
  payload,
});
// endregion

// region Resetting
export interface ResettingPayload extends ResetPasswordPayload {
  username: Valid<Email>;
}

export interface Resetting {
  type: 'Resetting';
  payload: ResettingPayload;
}

export const resetting = (payload: Resetting['payload']): Resetting => ({
  type: 'Resetting',
  payload,
});
// endregion

// region ResetError
export interface ResetErrorPayload extends ResettingPayload {
  message: string;
}

export interface ResetError {
  type: 'ResetError';
  payload: ResetErrorPayload;
}

export const resetError = (payload: ResetError['payload']): ResetError => ({
  type: 'ResetError',
  payload,
});
// endregion

// region BookingLoading
export interface BookingLoadingPayload {
  strictOfficeHoursScheduling: boolean;
  tenantId: TenantId;
  timeZone: TenantTimeZone;
  advisor: Item;
  strategy: Strategy;
  user: User;
}

export interface BookingLoading {
  type: 'BookingLoading';
  payload: BookingLoadingPayload;
}

export const bookingLoading = (
  payload: BookingLoading['payload'],
): BookingLoading => ({
  type: 'BookingLoading',
  payload,
});
// endregion

// region BookingLoadingError
export interface BookingLoadingErrorPayload extends BookingLoadingPayload {
  message: string;
}

export interface BookingLoadingError {
  type: 'BookingLoadingError';
  payload: BookingLoadingErrorPayload;
}

export const bookingLoadingError = (
  payload: BookingLoadingError['payload'],
): BookingLoadingError => ({
  type: 'BookingLoadingError',
  payload,
});
// endregion

// region BookingDay
export interface BookingDayPayload extends BookingLoadingPayload {
  timeslots: TimeRange[];
  day: Date | undefined;
  start: Date | undefined;
  end: Date | undefined;
  name: Value<string, Sentence<100>, string> | undefined;
  email: Value<string, Email, string> | undefined;
  confirmEmail: Value<string, Email, string> | undefined;
  topic: Value<string, Sentence<250>, string> | undefined;
  agenda: Value<string, Sentence<500>, string> | undefined;
  terms: Value<string, string, string> | undefined;
}

export interface BookingDay {
  type: 'BookingDay';
  payload: BookingDayPayload;
}

export const bookingDay = (payload: BookingDay['payload']): BookingDay => ({
  type: 'BookingDay',
  payload,
});
// endregion

// region BookingTime
export interface BookingTimePayload extends BookingDayPayload {
  day: Date;
  hours: TimeRange[];
}

export interface BookingTime {
  type: 'BookingTime';
  payload: BookingTimePayload;
}

export const bookingTime = (payload: BookingTime['payload']): BookingTime => ({
  type: 'BookingTime',
  payload,
});
// endregion

// region BookingGuestDetails
export interface BookingGuestDetailsPayload extends BookingTimePayload {
  strategy: PublicGuest;
  start: Date;
  end: Date;
  name: Value<string, Sentence<100>, string>;
  email: Value<string, Email, string>;
  confirmEmail: Value<string, Email, string>;
  topic: Value<string, Sentence<250>, string>;
  agenda: Value<string, Sentence<500>, string>;
  terms: Value<string, string, string>;
  inputs: {
    topic: string;
    agenda: string;
  };
}

export interface BookingGuestDetails {
  type: 'BookingGuestDetails';
  payload: BookingGuestDetailsPayload;
}

export const bookingGuestDetails = (
  payload: BookingGuestDetails['payload'],
): BookingGuestDetails => ({
  type: 'BookingGuestDetails',
  payload,
});
// endregion

// region BookingLoggedInDetails
export interface BookingLoggedInDetailsPayload extends BookingTimePayload {
  strategy: PublicLoggedIn | Private;
  start: Date;
  end: Date;
  topic: Value<string, Sentence<250>, string>;
  agenda: Value<string, Sentence<500>, string>;
  terms: Value<string, string, string>;
  inputs: {
    topic: string;
    agenda: string;
  };
}

export interface BookingLoggedInDetails {
  type: 'BookingLoggedInDetails';
  payload: BookingLoggedInDetailsPayload;
}

export const bookingLoggedInDetails = (
  payload: BookingLoggedInDetails['payload'],
): BookingLoggedInDetails => ({
  type: 'BookingLoggedInDetails',
  payload,
});
// endregion

// region SavingGuest
export interface SavingGuestPayload extends BookingLoadingPayload {
  strategy: PublicGuest;
  start: Date;
  end: Date;
  name: Valid<Sentence<100>>;
  email: Valid<Email>;
  confirmEmail: Valid<Email>;
  topic: Valid<Sentence<250>>;
  agenda: Valid<Sentence<500>>;
  terms: Valid<string>;
  inputs: {
    topic: string;
    agenda: string;
  };
}

export interface SavingGuest {
  type: 'SavingGuest';
  payload: SavingGuestPayload;
}

export const savingGuest = (payload: SavingGuest['payload']): SavingGuest => ({
  type: 'SavingGuest',
  payload,
});
// endregion

// region SavingLoggedIn
export interface SavingLoggedInPayload extends BookingLoadingPayload {
  strategy: PublicLoggedIn | Private;
  start: Date;
  end: Date;
  topic: Valid<Sentence<250>>;
  agenda: Valid<Sentence<500>>;
  terms: Valid<string>;
  inputs: {
    topic: string;
    agenda: string;
  };
}

export interface SavingLoggedIn {
  type: 'SavingLoggedIn';
  payload: SavingLoggedInPayload;
}

export const savingLoggedIn = (
  payload: SavingLoggedIn['payload'],
): SavingLoggedIn => ({
  type: 'SavingLoggedIn',
  payload,
});
// endregion

// region SaveErrorLoggedIn
export interface SaveErrorLoggedInPayload extends SavingLoggedInPayload {
  message: string;
}

export interface SaveErrorLoggedIn {
  type: 'SaveErrorLoggedIn';
  payload: SaveErrorLoggedInPayload;
}

export const saveErrorLoggedIn = (
  payload: SaveErrorLoggedIn['payload'],
): SaveErrorLoggedIn => ({
  type: 'SaveErrorLoggedIn',
  payload,
});
// endregion

// region SaveErrorGuest
export interface SaveErrorGuestPayload extends SavingGuestPayload {
  message: string;
}

export interface SaveErrorGuest {
  type: 'SaveErrorGuest';
  payload: SaveErrorGuestPayload;
}

export const saveErrorGuest = (
  payload: SaveErrorGuest['payload'],
): SaveErrorGuest => ({
  type: 'SaveErrorGuest',
  payload,
});
// endregion

// region SaveSuccess
export interface SaveSuccessPayload extends BookingLoadingPayload {}

export interface SaveSuccess {
  type: 'SaveSuccess';
  payload: SaveSuccessPayload;
}

export const saveSuccess = (payload: SaveSuccess['payload']): SaveSuccess => ({
  type: 'SaveSuccess',
  payload,
});
// endregion

// region SaveSuccessWithPayment
export interface SaveSuccessWithPaymentPayload extends SaveSuccessPayload {
  appointment: {
    paymentUrl: string;
    responseId: string;
  };
}

export interface SaveSuccessWithPayment {
  type: 'SaveSuccessWithPayment';
  payload: SaveSuccessWithPaymentPayload;
}

export const saveSuccessWithPayment = (
  payload: SaveSuccessWithPayment['payload'],
): SaveSuccessWithPayment => ({
  type: 'SaveSuccessWithPayment',
  payload,
});

// endregion

// region CancelingPayment

export interface CancelingPaymentPayload
  extends SaveSuccessWithPaymentPayload {}

export interface CancelingPayment {
  type: 'CancelingPayment';
  payload: CancelingPaymentPayload;
}

export const cancelingPayment = (
  payload: CancelingPayment['payload'],
): CancelingPayment => ({
  type: 'CancelingPayment',
  payload,
});

// endregion

export type State =
  | Idle
  | LogIn
  | LoggingIn
  | LoginError
  | ResetPassword
  | Resetting
  | ResetError
  | BookingLoading
  | BookingLoadingError
  | BookingDay
  | BookingTime
  | BookingLoggedInDetails
  | BookingGuestDetails
  | SavingLoggedIn
  | SavingGuest
  | SaveErrorGuest
  | SaveErrorLoggedIn
  | SaveSuccess
  | SaveSuccessWithPayment
  | CancelingPayment;

export type Bookable =
  | Idle
  | SaveErrorLoggedIn
  | SaveErrorGuest
  | BookingLoadingError;

export function isBookable(s: State): s is Bookable {
  switch (s.type) {
    case 'Idle':
    case 'SaveErrorLoggedIn':
    case 'SaveErrorGuest':
    case 'SaveSuccess':
    case 'SaveSuccessWithPayment':
    case 'BookingLoadingError':
      return true;
    case 'BookingLoading':
    case 'SavingGuest':
    case 'SavingLoggedIn':
    case 'BookingLoggedInDetails':
    case 'BookingGuestDetails':
    case 'BookingTime':
    case 'BookingDay':
    case 'LogIn':
    case 'LoggingIn':
    case 'LoginError':
    case 'Resetting':
    case 'CancelingPayment':
    case 'ResetError':
    case 'ResetPassword':
      return false;
  }
}

export function isError(
  s: State,
): s is SaveErrorLoggedIn | SaveErrorGuest | BookingLoadingError {
  switch (s.type) {
    case 'SaveErrorGuest':
    case 'SaveErrorLoggedIn':
    case 'BookingLoadingError':
      return true;
    case 'Idle':
    case 'SaveSuccess':
    case 'SaveSuccessWithPayment':
    case 'LoginError':
    case 'ResetError':
    case 'Resetting':
    case 'CancelingPayment':
    case 'ResetPassword':
    case 'BookingGuestDetails':
    case 'BookingLoggedInDetails':
    case 'BookingDay':
    case 'BookingLoading':
    case 'BookingTime':
    case 'LoggingIn':
    case 'SavingLoggedIn':
    case 'SavingGuest':
    case 'LogIn':
      return false;
  }
}

// region BookingDetails
export type BookingDetails = BookingGuestDetails | BookingLoggedInDetails;

export function isBookingDetails(s: State): s is BookingDetails {
  switch (s.type) {
    case 'BookingLoggedInDetails':
    case 'BookingGuestDetails':
      return true;
    case 'SaveErrorLoggedIn':
    case 'SavingLoggedIn':
    case 'SavingGuest':
    case 'SaveErrorGuest':
    case 'BookingTime':
    case 'BookingLoading':
    case 'BookingDay':
    case 'LogIn':
    case 'LoggingIn':
    case 'ResetPassword':
    case 'Resetting':
    case 'CancelingPayment':
    case 'ResetError':
    case 'LoginError':
    case 'SaveSuccess':
    case 'SaveSuccessWithPayment':
    case 'BookingLoadingError':
    case 'Idle':
      return false;
  }
}
// endregion

// region Saving
export type Saving = SavingGuest | SavingLoggedIn;

export function isSaving(s: State): s is Saving {
  switch (s.type) {
    case 'SavingLoggedIn':
    case 'SavingGuest':
      return true;
    case 'BookingLoggedInDetails':
    case 'BookingGuestDetails':
    case 'SaveErrorLoggedIn':
    case 'SaveErrorGuest':
    case 'BookingLoadingError':
    case 'SaveSuccess':
    case 'SaveSuccessWithPayment':
    case 'LoginError':
    case 'ResetError':
    case 'Resetting':
    case 'CancelingPayment':
    case 'ResetPassword':
    case 'LoggingIn':
    case 'BookingDay':
    case 'BookingLoading':
    case 'BookingTime':
    case 'Idle':
    case 'LogIn':
      return false;
  }
}
// endregion

// region SaveError
export type SaveError = SaveErrorGuest | SaveErrorLoggedIn;

export function isSaveError(s: State): s is SaveError {
  switch (s.type) {
    case 'SaveErrorLoggedIn':
    case 'SaveErrorGuest':
      return true;
    case 'BookingLoggedInDetails':
    case 'BookingGuestDetails':
    case 'SavingLoggedIn':
    case 'SavingGuest':
    case 'BookingLoadingError':
    case 'SaveSuccess':
    case 'SaveSuccessWithPayment':
    case 'LoginError':
    case 'ResetError':
    case 'Resetting':
    case 'CancelingPayment':
    case 'ResetPassword':
    case 'LoggingIn':
    case 'BookingDay':
    case 'BookingLoading':
    case 'BookingTime':
    case 'Idle':
    case 'LogIn':
      return false;
  }
}
// endregion
