import {
  Button as MaterialButton,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Modal } from '../../components/common';
import AssignSchedulingWizardForm from '../../components/forms/assign-scheduling-wizard';
import BaseLayout from '../../components/layout/base-layout';
import SessionsTable from '../../components/tables/sessions-table';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { COLORS } from '../../theme/variables';
import { CLASS_TRACKING } from '../../utils/tracking_class';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    padding: '0 20px !important',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: 15,
    },
  },
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
  modalTitle: {
    '& > div > h4': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export function SessionsPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();

  const { hasAccessToAction } = useContext(UserContext);
  const location = useLocation();

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  return (
    <BaseLayout user={user} fullWidth>
      <div className={classes.header}>
        <Typography variant='h2'>Sessions</Typography>
        <div className={classes.headerActions}>
          {hasAccessToAction('session.create') && (
            <MaterialButton
              data-testid='button-new-session'
              component={Link}
              className={CLASS_TRACKING.ENTITY_ACTION}
              to={{
                pathname: getRoutePath(Pages.NEW_SESSIONS),
                state: {
                  prevPath: currentPath,
                },
              }}
              startIcon={<AddIcon />}
              variant='contained'
              color='primary'>
              Session
            </MaterialButton>
          )}
          {hasAccessToAction('session.wizard') && (
            <Modal
              title='Scheduling Wizard'
              classNameTitle={classes.modalTitle}
              titleIcon={
                <Tooltip
                  title={`This wizard schedules new sessions automatically. It will send a request to founders to provide meeting date options, and then will ask ${rb(
                    'mentors',
                  )} to vote on the date that works for most. The new session(s) will be scheduled in 4 days.`}>
                  <IconButton className={classes.buttonInfoIcon}>
                    <InfoIcon className={classes.infoIcon} />
                  </IconButton>
                </Tooltip>
              }
              contentRenderer={({ handleClose }) => (
                <AssignSchedulingWizardForm onCancel={handleClose} />
              )}
              buttonRenderer={({ onClick }) => (
                <Button
                  onClick={onClick}
                  data-testid='button-scheduling-wizard'
                  startIcon={<AddIcon />}>
                  Wizard
                </Button>
              )}
              width={720}
            />
          )}
        </div>
      </div>
      <div>
        <SessionsTable />
      </div>
    </BaseLayout>
  );
}
