import { Button as MaterialButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BaseLayout from '../components/layout/base-layout';
import GatheringsTable from '../components/tables/gatherings-table';
import { UserContext } from '../contexts/user-context';
import { getRoutePath, Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { COLORS } from '../theme/variables';
import { CLASS_TRACKING } from '../utils/tracking_class';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    padding: '0 20px !important',
    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: 15,
    },
  },
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
  modalTitle: {
    '& > div > h4': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

function GatheringsPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);
  const location = useLocation();

  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );

  return (
    <BaseLayout user={user} fullWidth>
      <div className={classes.header}>
        <Typography variant='h2'>Gatherings</Typography>
        <div className={classes.headerActions}>
          {hasAccessToAction('session.create') && (
            <MaterialButton
              data-testid='button-new-session'
              component={Link}
              className={CLASS_TRACKING.ENTITY_ACTION}
              to={{
                pathname: getRoutePath(Pages.NEW_GATHERINGS),
                state: {
                  prevPath: currentPath,
                },
              }}
              startIcon={<AddIcon />}
              variant='contained'
              color='primary'>
              Gathering
            </MaterialButton>
          )}
        </div>
      </div>
      <div>
        <GatheringsTable />
      </div>
    </BaseLayout>
  );
}

export default GatheringsPage;
