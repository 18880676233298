import { Dialog, DialogContent, Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { COLORS } from '../../../theme/variables';
import { DialogTitle } from '../../common/dialog';
import { Header } from './Header';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 12,
    },
  },
  title: {
    textAlign: 'center',

    '& h4': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '15px',

      '& .MuiSvgIcon-root': {
        cursor: 'pointer',
      },
    },
    '& button[aria-label=close] .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  content: {
    width: '460px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '25px',
    padding: '16px 32px 32px 32px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  hours: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 10,
    columnGap: 10,
    justifyContent: 'center',
  },
  hour: {
    border: `2px solid ${COLORS.COLOR_BLUE_LIGHTENED_45}`,
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    borderRadius: 4,
    padding: '6px 20px',
    cursor: 'pointer',
    fontSize: 13,

    '&:hover, &.active': {
      backgroundColor: COLORS.COLOR_BLUE_BASE,
      borderColor: COLORS.COLOR_BLUE_BASE,
      color: '#fff',
    },
  },
  charge: {
    textAlign: 'center',
    color: COLORS.COLOR_TEXT_LIGHTENED_10,
    fontSize: 13,
  },
}));

export interface Props<T extends string> {
  open: boolean;
  onClose: () => void;
  onChange: (d: number) => void;
  onBack: () => void;
  value: T | undefined;
  hours: T[];
  avatar: string | undefined;
  firstName: string;
  lastName: string;
  date: Date;
  rate: number | null;
}

export function Time<T extends string>({
  open,
  onClose,
  onChange,
  onBack,
  hours,
  value,
  firstName,
  lastName,
  avatar,
  date,
  rate,
}: Props<T>): ReactElement {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle onClose={onClose} className={classes.title}>
        <ArrowBackIcon onClick={onBack} />
        <span>Select Available Time</span>
      </DialogTitle>
      <DialogContent className={classes.content} data-testid='hours-list'>
        <Header
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          date={date}
          time={undefined}
        />
        {rate ? (
          <Typography className={classes.charge}>
            You will be charged ${rate} USD for this consultation
          </Typography>
        ) : null}
        <div className={classes.hours}>
          {hours.map((h, i) => (
            <Hour
              onChange={() => onChange(i)}
              key={i}
              time={h}
              active={value === h}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function Hour({
  time,
  onChange,
  active,
}: {
  time: string;
  onChange: () => void;
  active: boolean;
}): ReactElement {
  const classes = useStyles();

  return (
    <span
      data-testid='hour'
      className={classNames(classes.hour, { active: active })}
      onClick={onChange}>
      {time}
    </span>
  );
}
